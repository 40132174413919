import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { IpClaimService } from "../_services/IpClaimService";
import { Button } from "react-bootstrap";
import "../SCSS/CommonComponentsCSS/CustomServerTable.scss";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { BiCheck, BiCircle } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { formatDateAndTime } from "../_helpers/date-format";
import Moment from "react-moment";
import { messageService } from "../_services/messageService";
import TextError from "./TextError";

export const IpServerTable = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null)


  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);
    const res = await IpClaimService.getIpClaimList();
    if (res?.errorCode) {
      setErrorMessage(res.message);
    } else {
      setData(res);
      setTotalRows(res.length);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  const columns = useMemo(() => [
    {
      name: "Case Number",
      sortable: true,
      selector: "claimRefNum",
    },
    {
      name: "Patient's Name",
      selector: "patientName",
      sortable: true,
    },
    {
      name: "Claim Amount",
      selector: "appliedClaimAmount",
      sortable: true,
      cell: (row) => {
        return (new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR'
        }).format(row.appliedClaimAmount));
      },
    },
    {
      name: "Contact Number",
      selector: "contactNum",
      sortable: true,
    },
    {
      name: "Claim Date",
      sortable: true,
      selector: "createdTimestamp",
      cell: (row) => {
        //return formatDateAndTime(row.createdTimestamp);
        return <Moment format={"DD-MM-YYYY hh:mm A"}>
          {row.createdTimestamp}
        </Moment>
      },
    },
    {
      name: "Sakala Number",
      sortable: true,
      selector: "sakalaNumber",
    },
    {
      name: "Relationship",
      sortable: true,
      selector: "relationshipType",
    },
    {
      name: "Status",
      sortable: true,
      selector: "claimStatus",
      cell: (row) => {
        return (
          <>
            {row.claimStatus === "Approved" && (
              <button type="button" className="approved-btn fix-width">
                <BiCheck
                  size={20}
                  style={{
                    marginTop: "-0.1rem",
                  }}
                />
                <i>{row.claimStatus}</i>
              </button>
            )}
            {row.claimStatus === "Submitted" && (
              <button type="button" className="submitted-btn fix-width">
                {"Submitted"}
              </button>
            )}
            {row.claimStatus === "Rejected" && (
              <button type="button" className="rejected-btn fix-width">
                <ImCross
                  size={10}
                  style={{
                    marginRight: "0.5rem",
                  }}
                />
                {row.claimStatus}
              </button>
            )}
            {row.claimStatus === "Objection" && (
              <button type="button" className="received-btn fix-width">
                {row.claimStatus}
              </button>
            )}
            {(row.claimStatus !== "Submitted" && row.claimStatus !== "Approved" && row.claimStatus !== "Objection" && row.claimStatus !== "Rejected") && (
              <button type="button" className="submitted-btn fix-width">
                {row.claimStatus}
              </button>
            )}
          </>
        );
      },
    },
  ]);

  const tableData = {
    columns,
    data,
  };

  const handleRowClicked = (row) => {
    history.push(`${props.data.path}/${row.claimUuid}`);
  };

  const redirectToAdd = () => {
    console.log("claims sending using rxjs ", data)
    messageService.sendMessage(data)
    history.push("/user/addNewClaim");
  };

  return (

    <>
      {errorMessage ? <TextError>{errorMessage}</TextError> : null}
      <div>
        <Button
          className="addnewclaim-buttons"
          id="addnewclaim-button"
          onClick={redirectToAdd}
        >
          ADD NEW CLAIM
        </Button>
      </div>
      <div>
        {data.length ? <DataTableExtensions export={false} print={false} {...tableData}>
          <DataTable
            pagination
            columns={columns}
            pointerOnHover={true}
            data={data}
            progressPending={loading}
            highlightOnHover
            onRowClicked={handleRowClicked}
            defaultSortFieldId={5}
            defaultSortAsc={false}
          />
        </DataTableExtensions> :
          <DataTable
            pagination
            columns={columns}
            data={data}
            progressPending={loading}
            highlightOnHover
            onRowClicked={handleRowClicked}
            defaultSortFieldId={5}
            defaultSortAsc={false}
          />}
      </div>
    </>
  );
};
