import React, { useState, useEffect } from 'react';
import './../../../SCSS/CommonComponentsCSS/DigitalSignModal.scss';
import { Modal, Tab, Tabs, Row, Col, Button } from 'react-bootstrap';
import SignCanvas from '../../../signCanvas/SignCanvas'
import { ImCross } from "react-icons/im";
import { ConfirmSignSubmit } from './ConfirmSignSubmit';
import { OfficeNotesAndMemo } from '../../../_services';
import TextError from '../../../CommonComponents/TextError';

export const DigitalSignModal = (props) => {
    const [tabKey, setTabKey] = useState(false);
    const [fullName, setFullName] = useState("");
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);
    const [imageFile, setImageFile] = useState();
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setFullName(JSON.parse(localStorage.currentUser).username);
        setImageFile(props?.data);
    }, [])

    const handleSelect = (key) => {
        setTabKey(key)
    };

    const handleCancle = () => {
        props.onHide(imageFile)
    }
    const closeModal = (data) => {
        setShowWarningModal(false);
        if (data === "success") {
            getSign();
        }
    }

    const getSign = async () => {
        const result = await OfficeNotesAndMemo.getSignatureService();
        if (result.errorCode) {
            setErrorMessage(result.message);
        } else {
            setImageFile(result.sign);
        }
    }
    const handleCallback = (imgUrl) => {
        setImgUrl(imgUrl);
    }

    const onSelectFile = (e) => {
        const file = e.currentTarget.files[0];
        if (file.type === 'image/jpeg') {
            getBase64(file).then(
                data => {
                    setImgUrl(data);
                });
        } else {
            setErrorMessage("Please Upload Only File Type .jpeg or .jpg");
        }
    }

    const handleSelectStyle = (style) => {
        var tCtx = document.getElementById('textCanvas').getContext('2d'),
            imageElem = document.getElementById('image');
        tCtx.canvas.width = tCtx.measureText(fullName).width + 20;
        tCtx.font = `30px ${style}`;
        tCtx.fillText(fullName, 0, 30, tCtx.canvas.width);
        imageElem.src = tCtx.canvas.toDataURL('image/jpg');
        setImgUrl(imageElem.src);
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='digitalSign'
            >
                <Modal.Header>

                    <Modal.Title id="contained-modal-title-vcenter">
                        Digi Sign
                    </Modal.Title>
                    <ImCross className="modal-im-cross" onClick={props.onHide} />
                </Modal.Header>

                <Modal.Body className="confirm-modal-body">
                    {errorMessage && <TextError>{errorMessage}</TextError>}
                    {imageFile ? <img src={`data:image/png;base64,${imageFile}`} alt="pic1" width="250" height="250" /> :
                        <Tabs
                            defaultActiveKey="upload"
                            id="esi-tabs"
                            className="tabsCls"
                            onSelect={(e) => handleSelect(e)}
                        >
                            {/* <Tab
                                eventKey="selectStyle" title="SELECT STYLE">
                                <Row>
                                    <Col xs={10} className="mb-3">
                                        <div className="form-floating">
                                            <input type="text" className="form-control textInput" id="enterName" placeholder="name initial" value={fullName}
                                                onChange={(e) => setFullName(e.currentTarget.value)} max={20} maxLength={20} />
                                            <label for="enterName">Edit Signature Text</label>
                                            <span style={{ fontSize: "10px", float: 'left', marginLeft: '15px' }}>
                                                Maximum 20 Characters
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={10}>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control sig1" id="fullName" placeholder="name initial" value={fullName} readonly />
                                            <label for="fullName">Document Signed By</label>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className="form-floating mb-3 selectRadio">
                                            <input type="radio" name="selectStyle" className="form-check-input" onChange={() => handleSelectStyle("Shadows Into Light")} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={10}>
                                        <div className="form-floating mb-3">
                                            <input type="text" id="fullname1" value={fullName} className="form-control sig2" readonly />
                                            <label for="fullname1">Document Signed By</label>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className="form-floating mb-3 selectRadio">
                                            <input type="radio" name="selectStyle" className="form-check-input" onChange={() => handleSelectStyle("Rock Salt")} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={10}>
                                        <div className="form-floating mb-3">
                                            <input type="text" id="fullname2" value={fullName} className="form-control sig3" readonly />
                                            <label for="fullname">Document Signed By</label>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className="form-floating mb-3 selectRadio">
                                            <input type="radio" name="selectStyle" className="form-check-input" onChange={() => handleSelectStyle("Cedarville Cursive")} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <canvas id='textCanvas' height="50" width="250" style={{ visibility: 'hidden' }}></canvas>
                                        <img id='image' />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="draw"
                                title="DRAW">
                                {tabKey === 'draw' && <SignCanvas parentCallback={handleCallback}></SignCanvas>}

                            </Tab> */}
                            <Tab eventKey="upload"
                                title="UPLOAD">
                                <div>
                                    <form>
                                        <div class="input-group custom-file-button">
                                            <label class="input-group-text" for="inputGroupFile">Upload Sign Image</label>
                                            <input type="file" class="form-control" id="inputGroupFile" name="file" multiple onChange={onSelectFile} accept=".jpg,.jpeg" />
                                        </div>
                                    </form>
                                    {imgUrl && <img src={imgUrl} alt="pic1" width="250" height="250" />}
                                </div>
                            </Tab>
                        </Tabs>}
                </Modal.Body>
                {!imageFile && <Modal.Footer>
                    <Row>
                        <Col xs={6}>
                            <div className=" mg-auto">
                                <Button
                                    className="esi-white-cancel-button-lg"
                                    onClick={() => { handleCancle() }}
                                >
                                    CANCEL

                                </Button>

                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="">
                                <Button
                                    className="esi-navy-blue-button-lg"
                                    type="submit"
                                    onClick={() => { setShowWarningModal(true) }}
                                    disabled={!imgUrl}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Footer>}
            </Modal>
            <ConfirmSignSubmit show={showWarningModal} onHide={closeModal} data={imgUrl}></ConfirmSignSubmit>
        </>
    )
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
