import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";

export const SuccessModal = (props) => {
  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      > 
        <Modal.Body>
            <div>
                <div className="success-text">
                    <FaCheck size={80}/><br/><br/>
                        <span style={{fontSize: "20px"}}>SOB Successfully Saved</span>
                </div>
            </div>
          </Modal.Body>
      </Modal>
  );
};
