import React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory, Link } from 'react-router-dom';
import { CommonAccordian } from '../../CommonComponents/CommonAccordian';
import '../../SCSS/Views/AddNewClaim.scss';
import '../../SCSS/Views/Topbar.scss';
import { HorizontalDivider } from '../../CommonComponents/HorizontalDivider';
import { ipInfo as ipData } from '../../Test/Data/IpInfo';
import { EmployeeProfileInfoStyle} from '../../CommonComponents/EmployeeProfileInfoStyle';

function AdminProfileView() {
  const [ipInfo, setIpinfo] = useState({});
  const [title, setTitle] = useState('');
  const history = useHistory();

  useEffect(() => {
    getIpInfoData();
  }, []);

  const getIpInfoData = async () => {
    setIpinfo(ipData);
    console.log(ipData);
  };

  return (
    <>
      <Row className='row-breadcrumb'>
        <Col>
          <div>
            <MdKeyboardBackspace
              className='BackIcon'
                 onClick={() => history.goBack()}
            ></MdKeyboardBackspace>
          </div>
          <div className='breadcrumb-div'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/admin'> <div className="homeIcon-svg"></div> </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='col-sub-header' id='col-edit'>
          {title}
        </Col>
        <HorizontalDivider color='#5277ac' />
      </Row>
      {/* <Form onSubmit={handleSubmit}> */}
      <Stack direction='vertical' gap={5}>
        <div>
          <CommonAccordian
            data={{
              title: 'User Profile',
              subtitle: 'User Information',
              eventKey: '0',
            }}
          >
            <EmployeeProfileInfoStyle onChange={(heading)=>setTitle(heading)}  isKannada={false} ></EmployeeProfileInfoStyle>
          </CommonAccordian>
        </div>
      </Stack>

      {/* </Form> */}
    </>
  );
}

export default AdminProfileView;
