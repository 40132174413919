import React, { useEffect, useState } from "react";
import { Button, Col, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CommonAccordian } from "../../CommonComponents/CommonAccordian";
import { CustomCard } from "../../CommonComponents/CustomCard";
import { DependentModal } from "../../CommonComponents/DependantModal";
import { DependantTable } from "../../CommonComponents/DependantTable";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import { IpServerTable } from "../../CommonComponents/IpServerTable";
import "../../SCSS/Views/IpDashboard.scss";
import { IpClaimService } from "../../_services/IpClaimService";

const IpClaimHistory = () => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [showClaimResults, setShowClaimResults] = useState(true);
  const [hideTable, setHideTable] = useState(false);

  const [dashboardResponse, setDashboardResponse] = useState({});

  useEffect(() => {
    getDashBoardCounts();
  }, []);

  const getDashBoardCounts = async () => {
    const results = await IpClaimService.getDashBoardCounts().then((res) => {
      setDashboardResponse(res);
    });
  };

  const modalClosed = () => {
    console.log("closeModal Triggered");
    setModalShow(false);
    setHideTable(true);
    setTimeout(() => {
      setHideTable(false);
      console.log("triggerd after timeout");
    }, 100);
  };
  const Close = () => {
    console.log("closeModal Triggered");
    setModalShow(false);
    setHideTable(true);
    setTimeout(() => {
      setHideTable(false);
      console.log("triggerd after timeout");
    }, 100);
  };


  const redirectToAdd = () => {
    history.push("/user/addNewClaim");
  };
  const handleSelect = (key) => {
    console.log(key);
    if (key === "dependants") {
      setShowClaimResults(false);
      // backgroundColor:$esiblue;
    } else if (key === "overview") {
      setShowClaimResults(true);
    }
  };
  const data = {
    cardTitle: "Total",
    cardValue: `${
      !dashboardResponse.totalCount ? 0 : dashboardResponse.totalCount
    }`,
    backgroundColor: "#dff0f8",
  };
  const data2 = {
    cardTitle: "Submitted",
    cardValue: `${
      !dashboardResponse.submittedCount ? 0 : dashboardResponse.submittedCount
    }`,
    backgroundColor: "#fef7da",
  };
  const data3 = {
    cardTitle: "In Progress",
    cardValue: `${
      !dashboardResponse.inProgressCount ? 0 : dashboardResponse.inProgressCount
    }`,
    backgroundColor: "#77bbbbbf",
  };
  const data4 = {
    cardTitle: "Approved",
    cardValue: `${
      !dashboardResponse.approvedCount ? 0 : dashboardResponse.approvedCount
    }`,
    backgroundColor: "#e2ffdd",
  };
  const data5 = {
    cardTitle: "Rejected",
    cardValue: `${
      !dashboardResponse.rejectedCount ? 0 : dashboardResponse.rejectedCount
    }`,
    backgroundColor: "#ffdcdc",
  };

  const data6 = {
    cardTitle: "Objected",
    cardValue: `${
      !dashboardResponse.objectionCount ? 0 : dashboardResponse.objectionCount
    }`,
    backgroundColor: "#fbf89be0",
  }
  return (
    <>
      <Stack direction="vertical" gap={2}>
        <div>
          <Tabs
            defaultActiveKey="overview"
            className="mb-3"
            onSelect={(e) => handleSelect(e)}
          >
            <Tab eventKey="overview" title="Overview">
              <Row className="dashboard-card-row">
                <Col xs={12} lg={2}>
                  <CustomCard data={data} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data2} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data3} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data6} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data5} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data4} />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="dependants"
              title="Dependents"
              tabClassName="dependents-tab"
            >
              <Stack gap={2} className="dependents-border">
                <div className="ms-auto">
                  <Button
                    className="esi-navy-blue-button"
                    onClick={() => setModalShow(true)}
                  >
                    <span style={{ fontSize: "15px" }}>&nbsp;+</span>ADD NEW
                  </Button>

                  {modalShow && <DependentModal
                    show={modalShow}
                    onHide={modalClosed}
                    onCancel={modalClosed}
                    data={{ title: "Add Dependent" }}
                  />}
                </div>
                <div>{hideTable ? null : <DependantTable />}</div>
              </Stack>
            </Tab>
          </Tabs>
        </div>
        <div className="acc-container">
          {showClaimResults ? (
            <CommonAccordian data={{ title: "Claim Application History", eventKey: "0" }}>
              <Stack direction="vertical" gap={2}>
                <div>
                  <IpServerTable data={{ path: "/user/ipClaimView" }} />
                </div>
              </Stack>
            </CommonAccordian>
          ) : null}
        </div>
      </Stack>
    </>
  );
};

export default IpClaimHistory;
