// {
//   // eslint-disable-next-line react/button-has-type
//   cell: (row) => <button onClick={handleDelete(row)}>Delete</button>;
// }

import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ImBin } from "react-icons/im";
import { RiEditBoxLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import "../SCSS/CommonComponentsCSS/DependantTable.scss";
import { DependentModal } from "./DependantModal";
import { DependantService } from "../_services/DependantService";
import { ConfirmModal } from "../Views/Admin/Modals/ConfirmModal";
import Moment from "react-moment";

export const DependantTable = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dependantRow, setDependantRow] = useState(null);
  const [dependentRecord, setDependentRecord] = useState({});
  // const [deleted, setDeleted] = useState([]);
  useEffect(() => {
    fetchDependants();
  }, []);

  const handleDelete = async (row) => {
    setDependantRow(row);
    setDeleteModalShow(true);
    setDependentRecord(row);
  };

  const deleteModalClosed = async () => {
    setDeleteModalShow(false);
    fetchDependants();
  };

  const handleEdit = (row) => {
    console.log(row);
    setDependantRow(row);
    setModalShow(true);
  };

  const modalClosed = () => {
    setModalShow(false);
    fetchDependants();
  };

  const fetchDependants = async () => {
    setLoading(true);
    setData(await DependantService.getIpDependants());
    setLoading(false);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "dependentName",
      sortable: true,
    },
    {
      name: "Relationship",
      selector: "relationshipType",
      sortable: true,
    },
    {
      name: "DOB",
      selector: "dob",
      sortable: true,
      cell: (row) => {
        //return formatDateAndTime(row.createdTimestamp);
        return row.dob? <Moment format={"DD-MM-YYYY"}>
        {row.dob }
      </Moment>:'-'
      },
    },
    {
      name: "Age",
      selector: "age",
      sortable: true,
    },
    {
      name: "Dependent's UHID",
      selector: "patientUhid",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => [
        <Stack direction="horizontal" gap={2}>
          <div>
            <RiEditBoxLine
              className="esi-react-edit"
              onClick={() => handleEdit(row)}
            />
          </div>
          <div>
            {!(row.relationshipType.toUpperCase() === "Self".toUpperCase()) && (
              <ImBin
                className="esi-react-bin"
                onClick={() => handleDelete(row)}
              />
            )}
          </div>
        </Stack>,
      ],
    },
  ]);

  return (
    <>
      <DataTable columns={columns} data={data} highlightOnHover />
      {modalShow && <DependentModal
        show={modalShow}
        onHide={modalClosed}
        onCancel={modalClosed}
        data={{ title: "Update Dependant", rowData: dependantRow }}
      /> }
      <ConfirmModal
        show={deleteModalShow}
        onHide={deleteModalClosed}
        data={{
          dependentRecord: dependentRecord,
          rowData: dependantRow
        }}
      />
    </>
  );
};
