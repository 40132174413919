import React, { useRef } from "react";
import DateView from "react-datepicker";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";

function EsiDatePicker(props) {
  const { label, name, ...rest } = props;
  const datepickerRef = useRef(null);
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }

  return (
    <div>
      <label htmlFor={name}>{label}</label>

      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <>
              <DateView
                className="esi-date-picker"
                id={name}
                {...field}
                {...rest}
                selected={value}
                onChange={(val) => setFieldValue(name, val)}
                dateFormat="dd-MM-yyyy"
                ref={datepickerRef}
              />
              <div className="calendar-icon">
                <BiCalendar
                  size={23}
                  onClick={() => handleClickDatepickerIcon()}
                />
              </div>
            </>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default EsiDatePicker;
