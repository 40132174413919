import "../SCSS/CommonComponentsCSS/ActivityStateComponent.scss";
import { ActivityCard } from "./ActivityCard";
import { useEffect } from "react";



export const ActivityStateComponent = (props) => {

useEffect(() => {
  console.log(props.activityData)
}, [props.activityData]);

  return (
    <>
      <div>
        <ActivityCard data={props.activityData} />
      </div>
    </>
  );
};
