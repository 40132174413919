import React, { useState } from "react";
import { Redirect } from "react-router-dom";

const AdminHome = () => {
  const [redirect, setRedirect] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to="/admin" />;
  }

  return <></>;
};

export default AdminHome;
