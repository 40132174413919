import React from "react";
import Topbar from "./../Topbar/Topbar";
import Footbar from "./../Footbar/Footbar";
import Topbar_2 from "./../Topbar/Topbar_2";
import { Container } from "react-bootstrap";

const UserPage = ({ children }) => {
  return (
    <>
      <div className="page-children">{children}</div>
      {/* <Footbar></Footbar> */}
    </>
  );
};

export default UserPage;
