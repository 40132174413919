import React, { useState, useEffect } from 'react';

import {
  Col,
  Container,
  Dropdown,
  FormControl,
  Image,
  Row,
  Stack,
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import { FaBell, FaRegUserCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import '../../SCSS/Views/Topbar.scss';
import { Role } from '../../_helpers';
import { authenticationService } from '../../_services/authentication.service';

export const Topbar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser) {
      let adminRole = false;
      const isAdminPermission = [
        Role.caseWorker,Role.ss, Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist
      ];
      const userRole = JSON.parse(localStorage.currentUser).authorites[0];
      if (isAdminPermission.includes(userRole)) {
        adminRole = true;
      }
      setIsAdmin(adminRole);
    }
  }, []);

  const history = useHistory();

  const handleLogout = async () => {
    authenticationService.logout();
    if (!isAdmin) {
      history.push('/welcome');
    } else {
      history.push('/welcome/admin/login');
    }
  };
  const handleProfile = async () => {
    if (!isAdmin) {
      history.push('/user/UserProfileView');
    } else {
      history.push('/admin/AdminProfileView');
    }
  };
  return (
    <>
      <Navbar
        className='justify-content-center esi-topbar'
        sticky='top'
        collapseOnSelect
        expand='sm'
      // bg="dark"
      // variant="dark"
      >
        {/* <Container> */}
        <Navbar.Brand href='#'>
          <a>
            <Image
              src='https://planning.karnataka.gov.in/frontend/opt1/images/cm_logo/chief_minister_of_karrnataka_en.png'
              alt='Shri Basavaraj Bommai'
              className='image-chief-min-icon-topbar'
            ></Image>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='m-auto'>
            <Image
              src='https://kscpcr.karnataka.gov.in/frontend/opt1/images/center_logo/kar_main_logo.png'
              className='kar-main-logo-img'
              alt='Kar Logo'
            ></Image>
            <Stack direction='vertical' gap={1}>
              <div className='heading'>
                <div className='heading1'>
                  <b>Employee's State Insurance Scheme Medical Service</b>
                </div>
                <div className='div2' margin-top='54px'>
                  Government of Karnataka
                </div>
              </div>
            </Stack>
          </Nav>
          <Nav>
            {/* <Nav.Link href='#deets'>
              {' '}
              <div className='fabell-icon'>
                <FaBell />
              </div>
            </Nav.Link> */}
            <Nav.Link eventKey={2} href='#memes'>
              <NavDropdown
                title={<FaRegUserCircle fontSize='2rem' color='#172d66' />}
                
              >
                {isAdmin ? (
                  <>
                    <NavDropdown.Item onClick={() => handleProfile()}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleLogout()}>
                      Logout
                    </NavDropdown.Item>
                  </>
                ) : (
                  <>
                    <NavDropdown.Item onClick={() => handleProfile()}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleLogout()}>
                      Logout
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            </Nav.Link>
              
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>

      {/* <Row>
        <Col md={2} xl={1}>
          <a>
            <Image
              src="https://planning.karnataka.gov.in/frontend/opt1/images/cm_logo/chief_minister_of_karrnataka_en.png"
              alt="Shri Basavaraj Bommai"
              className="image-chief-min-icon"
            ></Image>
          </a>
        </Col>

        <Col md={8} l={1} ClassName="col-mid-pos">
          <Image
            src="https://kscpcr.karnataka.gov.in/frontend/opt1/images/center_logo/kar_main_logo.png"
            className="kar-main-logo-img"
            alt="Kar Logo"
          ></Image>
          <Stack direction="vertical" gap={1}>
            <div className="heading">
              <div className="heading1">
                <b>Employee's State Insurance Medical Scheme Medical Service</b>
              </div>
              <div className="div2" margin-top="54px">
                Government of Karnataka
              </div>
            </div>
          </Stack>
        </Col>

        <Col className="topbar-col-class-right" md={2} xs={2}>
          <div className="fabell-icon">
            <FaBell />
          </div>
          <Dropdown
            className="user-profile-dropdown"
            id="user-profile-dropdown1"
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <FaRegUserCircle fontSize="2rem" color="#172d66" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="user-profile-dropdown-menu">
              <Dropdown.Item eventKey="1" active>
                Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()} eventKey="2">
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row> */}
    </>
  );
};

export default Topbar;
