import { React, useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../SCSS/Views/Login.scss";
import { Role } from "../../_helpers";
import { authenticationService } from "../../_services";
import { Formik } from "formik";
import * as yup from "yup";
import TextError from "../../CommonComponents/TextError";
import { EsiLoader } from "../../CommonComponents/EsiLoader";

const Register = () => {
  const [ipNumber, setIpNumber] = useState("");
  const [ipPhone, setIpPhoneNumber] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  useEffect(() => {
    // checkAuthAndContinue();
  }, []);

  const schema = yup.object().shape({
    ipNumber: yup
      .number()
      .min(1000000000, "10 Digits Only")
      .max(9999999999, "10 Digits Only")
      .required("IP Number is Required"),
    ipPhone: yup
      .number()
      .min(1000000000, "10 Digits Only")
      .max(9999999999, "10 Digits Only")
      .required("IP Phone is Required"),
    ipName: yup
      .string()
      .trim()
      .max(50, "50 Chars Only")
      .matches(/^[ A-Za-z]*$/, "Only alphabets are allowed for this field ")
      .required("IP Name is Required"),
    ipEmail: yup
      .string()
      .trim()
      .matches(/.+@.+\..+/, "Please enter a valid email")
      .required("Email is Required"),
    ipPassword: yup
      .string()
      .required("Please Enter Password")
      .min(8, "Password length must be at least 8 characters")
      .max(20, "Password length must be up to 20 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    ipConfirmPassword: yup
      .string()
      .oneOf([yup.ref('ipPassword'), null], 'Passwords must match')
      .required("Please confirm your password")

  });

  const checkAuthAndContinue = async () => {
    if (
      authenticationService.currentUserToken &&
      authenticationService.currentUserToken.jwt
    ) {
      setLoaderStatus(true);
      authenticationService.sessionService().then(
        (response) => {
          setLoaderStatus(false);

          if (response.category === Role.Admin) {
            history.push("/admin");
          } else if (response.category === Role.User) {
            history.push("/welcome/otpValidation");
          }
        },
        (error) => {
          setLoaderStatus(true);
          localStorage.removeItem("currentUser");
          localStorage.removeItem("userToken");
          authenticationService.updateJwtToken(null);
          authenticationService.updateUserData(null);
        }
      );
    }
  };

  const handleFormSubmit = async (e) => {
    setLoaderStatus(true);
    await authenticationService
      .generateOtp({
        ipNumber: e.ipNumber,
        ipPhone: e.ipPhone,
        ipName: e.ipName,
        ipEmail: e.ipEmail,
        ipPassword: e.ipPassword
      }).then(
        (response) => {
          console.log(response);
          if (ipNumber !== "" && ipPhone !== "") {
            localStorage.username = e.ipNumber;
            localStorage.password = e.ipPhone;
            localStorage.ipName = e.ipName;
          }
          setLoaderStatus(false);
          if (response.ipNumber) {
            history.push({
              pathname: "/welcome/otpValidation",
              state: {
                ipNumber: e.ipNumber,
                ipPhone: e.ipPhone,
                ipName: e.ipName,
                ipEmail: e.ipEmail,
                ipPassword: e.ipPassword
              },
            });
          } else if (response.errorCode) {
            setShowError(true);
            setLoaderStatus(false);
            setErrorMessage(response.message);
          }
        },
        (error) => {
          console.error(error);
          setShowError(true);
          setLoaderStatus(false);
        }
      );
  };




  const valid_numbers = (e) => {

    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not 
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }

  }

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}

      <div className="container-login-card">
        {showError && (
          <TextError>
            <b><p key="error" variant="danger">
              {/* Username or Password is invalid */}
              {errorMessage != null && errorMessage}
            </p></b>
          </TextError>
        )}
        <Stack className="login-heading">
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Insured Person Beneficiary Register

          </div>
        </Stack>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            ipNumber: "",
            ipPhone: "",
            ipName: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form className="esi-login-form" onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={1}>
                <div>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Ip Name/ವಿಮಾದಾರರ ಹೆಸರು</Form.Label>
                    <Form.Control
                      
                      type="text"
                      name="ipName"
                      onBlur={handleBlur}
                      value={values.ipName}
                      onChange={handleChange}
                      isValid={touched.ipName && !errors.ipName}
                      isInvalid={!!errors.ipName}
                      placeholder="Enter IP Name"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formBasicNumber">
                    <Form.Label>Ip Number/ವಿಮಾದಾರರ ಸಂಖ್ಯೆ</Form.Label>
                    <Form.Control
                      
                      type="text"
                      name="ipNumber"
                      value={values.ipNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipNumber && !errors.ipNumber}
                      isInvalid={!!errors.ipNumber}
                      placeholder="Enter 10 digit IP Number"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={10}
                      onKeyPress={valid_numbers}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipNumber}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formBasicPhone">
                    <Form.Label>Ip Phone Number/ವಿಮಾದಾರರ ದೂರವಾಣಿ ಸಂಖ್ಯೆ</Form.Label>
                    <Form.Control
                      
                      type="text"
                      name="ipPhone"
                      value={values.ipPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipPhone && !errors.ipPhone}
                      isInvalid={!!errors.ipPhone}
                      placeholder="Enter 10 digit Phone Number"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={10}
                      onKeyPress={valid_numbers}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipPhone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formBasicPhone">
                    <Form.Label>Ip Email/ವಿಮಾದಾರರ ಇಮೇಲ್ ಐಡಿ</Form.Label>
                    <Form.Control
                   
                      type="text"
                      name="ipEmail"
                      value={values.ipEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipEmail && !errors.ipEmail}
                      isInvalid={!!errors.ipEmail}
                      placeholder="Enter your Email ID"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipEmail}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formBasicPhone">
                    <Form.Label>Password/
                      ಪಾಸ್ವರ್ಡ್</Form.Label>
                      <Form.Control
                        type="text" 
                        name="ipPassword"
                        value={values.ipPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.ipPassword && !!errors.ipPassword}
                        placeholder="Enter your Password"
                        maxLength={20}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.ipPassword}
                      </Form.Control.Feedback>
                    
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formBasicPhone">
                    <Form.Label>Confirm Password/ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ದೃಢೀಕರಿಸಿ</Form.Label>
                    <Form.Control
                    
                      type="password"
                      name="ipConfirmPassword"
                      value={values.ipConfirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipConfirmPassword && !errors.ipConfirmPassword}
                      isInvalid={!!errors.ipConfirmPassword}
                      placeholder="Confirm your password"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={20}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipConfirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ textAlignLast: "center", marginBottom: "5%" }}>
                  <Button to="/welcome"
                    variant="primary"
                    className="esi-navy-blue-button"
                    type="submit"
                    style={{width:"100%"}}
                  >
                    Register
                  </Button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Register;
