import "../SCSS/CommonComponentsCSS/CustomServerTable.scss";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { IpClaimService } from "../_services/IpClaimService";
import { Form, Stack, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowDownShort } from "react-icons/bs";
import { BiCheck, BiSearch } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { VscClearAll } from "react-icons/vsc";
import Select from 'react-select';
import Moment from "react-moment";

export const CustomServerTable = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("createdTimestamp");
  const [columnSortDirection, setColumnSortDirection] = useState("desc");
  const [searchByClaimNumber, setSearchByClaimNumber] = useState(null);
  const [searchBySakaalaNumber, setSearchBySakaalaNumber] = useState(null);
  const [searchByIpNumber, setSearchByIpNumber] = useState(null);
  const [searchByPatientName, setSearchByPatientName] = useState(null);
  const [searchQueryArray, setSearchQueryArray] = useState([]);
  const [listOfDispensaries, setListOfDispensaries] = useState([]);
  const [selectedDispensary, setSelectedDispensary] = useState(null);

  const fetchClaims = async (page, size = perPage, column, sortDirection) => {
    setLoading(true);
    const res = await IpClaimService.getIpClaimListPaginated({
      page: page,
      results: size,
      orderBy: column || sortColumn,
      sort: sortDirection || columnSortDirection,
      searchQueryArray: searchQueryArray
    });
    setData(res);
    await IpClaimService.getIpClaimListPaginatedCount({
      searchQueryArray: searchQueryArray,
    }).then((res) => {
      setTotalRows(res.count);
    });
    setLoading(false);
  };

  useEffect(() => {
    getDispensaryData();
  }, []);

  useEffect(() => {
    fetchClaims(1);
  }, [searchQueryArray]);

  useEffect(() => {
    if (selectedDispensary && selectedDispensary.length > 0) {
      handleSearch();
    }
  }, [selectedDispensary]);

  const setDispensaryLists = (disValues = JSON.parse(localStorage.currentUser).dispensaries, mappedDisp = listOfDispensaries) => {
    let selectedDispensary = [];
    if (disValues.length > 0) {
      disValues.forEach(element => {
        mappedDisp.forEach(disp => {
          if (disp.value === element) {
            selectedDispensary.push(disp);
          }
        })
      });
    }
    setSelectedDispensary(selectedDispensary);
  }

  const getDispensaryData = async () => {
    const disList = await IpClaimService.getDispensaries();
    const mappedDisp = [];
    disList.forEach(element => {
      mappedDisp.push(
        {
          value: element.dispenseryId,
          label: element.dispensaryName
        }
      )
    });
    setListOfDispensaries(mappedDisp);
    const disValues = JSON.parse(localStorage.currentUser).dispensaries;
    setDispensaryLists(disValues, mappedDisp);
  };

  const columns = useMemo(() => [
    {
      name: "Case Number",
      selector: "claimRefNum",
      sortable: true,
    },
    {
      name: "Patient's Name",
      selector: "patientName",
      sortable: true,
    },
    {
      name: "Claim Amount",
      selector: "appliedClaimAmount",
      sortable: true,
      cell: (row) => {
        return(new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR'
        }).format(row.appliedClaimAmount));
      },
    },
    {
      name: "Contact Number",
      selector: "contactNum",
      sortable: true,
    },
    {
      name: "Claim Date",
      sortable: true,
      selector: "createdTimestamp",
      cell: (row) => {
        //return formatDateAndTime(row.createdTimestamp);
        return<Moment format={"DD-MM-YYYY hh:mm A"}>
        {row.createdTimestamp }
      </Moment>
      },
    },
    {
      name: "Sakala Number",
      sortable: true,
      selector: "sakalaNumber",
    },
    {
      name: "IP Number",
      sortable: true,
      selector: "ipNumber",
    },
    {
      name: "Status",
      sortable: true,
      selector: "claimStatus",
      cell: (row) => {
        return (
          <>
            {row.claimStatus === "Approved" && (
              <button type="button" className="approved-btn fix-width">
                <BiCheck
                  size={20}
                  style={{
                    marginTop: "-0.1rem",
                    fontWeight: "bold",
                  }}
                />
                {row.claimStatus}
              </button>
            )}
            {row.claimStatus === "Submitted" && (
              <button type="button" className="submitted-btn fix-width">
                {"Submitted"}
              </button>
            )}
            {row.claimStatus === "Rejected" && (
              <button type="button" className="rejected-btn fix-width">
                <ImCross
                  size={10}
                  style={{
                    marginRight: "0.5rem",
                    fontWeight: "bold",
                  }}
                />
                {row.claimStatus}
              </button>
            )}
            {row.claimStatus === "Objection" && (
              <button type="button" className="received-btn fix-width">
                {row.claimStatus}
              </button>
            )}
            {(row.claimStatus !== "Submitted" && row.claimStatus !== "Approved" && row.claimStatus !== "Objection" && row.claimStatus !== "Rejected") && (
              <button type="button" className="submitted-btn fix-width">
                {row.claimStatus}
              </button>
            )}
          </>
        );
      },
    },
  ]);

  const handlePageChange = (page) => {
    fetchClaims(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchClaims(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleRowClicked = (row) => {
    history.push(`${props.data.path}/${row.claimUuid}`);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setColumnSortDirection(sortDirection);
    fetchClaims(1, perPage, column.selector, sortDirection);
  };

  const handleClear = () => {
    const dispensaryList = IpClaimService.getCUDispensaries;
    setSearchByClaimNumber("");
    setSearchByIpNumber("");
    setSearchByPatientName("");
    setSearchBySakaalaNumber("");
    setSearchQueryArray([]);
    setDispensaryLists()
    fetchClaims(1);
  };

  const handleSearch = () => {
    setSearchQueryArray(
      IpClaimService.getSearchRequestData(
        searchByClaimNumber,
        searchBySakaalaNumber,
        searchByIpNumber,
        searchByPatientName,
        selectedDispensary
      )
    );
  };

  const handleDispensarySelect = (selectedDispensary) => {
    setSelectedDispensary(selectedDispensary);
  }

  return (
    <>
      <Stack direction="vertical" gap={2}>
        <div>
          <Row>
            <Stack direction="horizontal" gap={3}>
              <div>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Search by Case Number"
                    className="search"
                    value={searchByClaimNumber}
                    onChange={(e) => setSearchByClaimNumber(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <div className="search1">
                    <BiSearch onClick={() => handleSearch()} />
                  </div>
                </Form.Group>
              </div>
              <div>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Search by Patient Name"
                    className="search"
                    value={searchByPatientName}
                    onChange={(e) => setSearchByPatientName(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <div className="search1">
                    <BiSearch onClick={() => handleSearch()} />
                  </div>
                </Form.Group>
              </div>
              <div>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Search by Sakala Number"
                    className="search"
                    value={searchBySakaalaNumber}
                    onChange={(e) => setSearchBySakaalaNumber(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <div className="search1">
                    <BiSearch onClick={() => handleSearch()} />
                  </div>
                </Form.Group>
              </div>
              <div>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Search by IP Number"
                    value={searchByIpNumber}
                    className="search"
                    onChange={(e) => setSearchByIpNumber(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <div className="search1">
                    <BiSearch onClick={() => handleSearch()} />
                  </div>
                </Form.Group>
              </div>
              <div style={{ position: "relative", minWidth: "300px", maxWidth: "300px" }}>
                <Form.Group>
                  <Select
                    isMulti
                    name="colors"
                    options={listOfDispensaries}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedDispensary}
                    onChange={handleDispensarySelect}
                  />
                </Form.Group>
              </div>
              <div>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Clear Search</Tooltip>}
                >
                  <span className="d-inline-block">
                    <VscClearAll
                      className="search-clear-icon"
                      cursor="pointer"
                      onClick={() => handleClear()}
                      fontSize={30}
                    />
                  </span>
                </OverlayTrigger>
              </div>
            </Stack>
          </Row>
        </div>
        <div>
          <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pointerOnHover={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
            onRowClicked={handleRowClicked}
            onSort={handleSort}
            sortServer
            sortIcon={<BsArrowDownShort />}
          />
        </div>
      </Stack>
    </>
  );
};
