import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";

export const ApprovedToggleModal = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(false);

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
          <ImCross className="modal-im-cross" onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className="confirm-modal-body">
          <FaCheck size={70} style={{ color: "#172d66" }} />
          {props.description && <><br />
            <div className="approved-text">
              <p className="approved-subtext">
                <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "16px", color: "#172d66" }}>
                  {props.description}
                </span>
              </p>
            </div>
          </>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="esi-confirm-modal-button" onClick={props.onHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
