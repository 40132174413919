import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Stepper.scss";
import {
  formatDate,
  formatTime,
} from "../_helpers/date-format";

export default class Stepper extends Component {
  constructor() {
    super();
    this.state = {
      // Completed - to add a check mark
      // Selected - to fill step with color
      // Highlighted - to make text of selected step bold
      steps: [],
    };
  }

  componentDidMount() {
    const { steps, currentStepNumber } = this.props;

    const stepsState = steps.map((step, index) => {
      const stepObj = {};
      stepObj.description = step.status;
      stepObj.highlighted = index === 0 ? true : false;
      stepObj.selected = index === 0 ? true : false;
      stepObj.completed = false;
      stepObj.date = step.createdTimestamp;
      return stepObj;
    });

    const currentSteps = this.updateStep(currentStepNumber, stepsState);

    this.setState({
      steps: currentSteps,
    });
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.state;
    const currentSteps = this.updateStep(this.props.currentStepNumber, steps);

    if (prevProps.currentStepNumber !== this.props.currentStepNumber)
      this.setState({
        steps: currentSteps,
      });
  }

  updateStep(stepNumber, steps) {
    const newSteps = [...steps];
    let stepCounter = 0;

    // Completed - to add a check mark
    // Selected - to fill step with color
    // Highlighted - to make text of selected step bold

    while (stepCounter < newSteps.length) {
      // Current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future step
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }

    return newSteps;
  }

  render() {
    const { direction } = this.props;
    const { steps } = this.state;
    const stepsJSX = steps.map((step, index) => {
      return (
        <>
          <div className="step-wrapper" key={index}>
            <div className="step-left-label">{formatDate(step.date)}</div>
            <div
              className={`step-number ${
                step.selected ? "step-number-selected" : "step-number-disabled"
              }`}
              style={{ background: getBackgroundColor(step.description) }}
            >
              {/* {step.completed ? <span>&#10003;</span> : index + 1} */}
            </div>
            <div
              className={`step-description ${
                step.highlighted && "step-description-active"
              }`}
              style={{ color: getBackgroundColor(step.description) }}
            >
              {step.description}-
              <span style={{ fontSize: "10px" }}>
                Claim {step.description} on {formatDate(step.date)} @
                {formatTime(step.date)} hrs
              </span>
            </div>
            {index !== steps.length - 1 && (
              <div className={`divider-line divider-line-${steps.length}`} />
            )}
          </div>
        </>
      );
    });

    return <div className={`stepper-wrapper-${direction}`}>{stepsJSX}</div>;
  }
}

Stepper.propTypes = {
  direction: PropTypes.string.isRequired,
  currentStepNumber: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  stepColor: PropTypes.string.isRequired,
};

const getBackgroundColor = (status) => {
  switch (status.toUpperCase()) {
    case "SUBMITTED":
      return "#353636";
    case "RECEIVED":
      return "#ffc107";
    case "DOCUMENT VERIFICATION":
      return "#3971ca";
    case "APPROVED":
      return "green";
    case "REJECTED":
      return "red";
    default:
      return "white";
  }
};
