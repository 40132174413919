import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { ClaimStageCommonComponent } from '../../../CommonComponents/ClaimStageComponent/ClaimStageComponent';
import { EsiLoader } from '../../../CommonComponents/EsiLoader';
import '../../../SCSS/Views/ClaimStage.scss'
import { IpClaimService } from '../../../_services/IpClaimService';
import _ from 'lodash';

export const ClaimStageComponent = ({ claimId }) => {
    const [claimDetail, setClaimDetails] = useState({});
    const [claimsStages, setClaimStages] = useState([]);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [presentStage, setPresentStage] = useState("");


    const checkAddEven = (number) => {
        if (number % 2 === 0) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        loadCliamStages();
    }, [])

    const loadCliamStages = async () => {
        setLoaderStatus(true);
        const stagesResult = await IpClaimService.getClaimStageService(claimId);
        setLoaderStatus(false);
        if (stagesResult) {
            setClaimDetails(stagesResult);
        }
        try {
            const items = _.cloneDeep(stagesResult.claimStatusByRole);
            const n = 3;
            if (items && items.length > 0) {
                const result = new Array(Math.ceil(items.length / n))
                    .fill()
                    .map((_) => { return items.splice(0, n) });
                setClaimStages(result);
                stagesResult.claimStatusByRole.forEach(element => {
                    if (element?.claimStatus?.enable) {
                        setPresentStage(element?.claimStatus?.state);
                    }
                });
            } else {

            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {loaderStatus ? <EsiLoader /> : null}
            <div className='outter-layer'>
                <div className='header-layer'>Current Stage: {presentStage}</div>
                <div className='inner-layer'>
                    {claimsStages && claimsStages.map((chunks, i, claimsStages) => (
                        <>
                            <Row className={(i % 2 === 0) ? "" : "flexReverse"}>
                                {chunks.map((status, index, chunks) => (
                                    <>
                                        <ClaimStageCommonComponent status={status}></ClaimStageCommonComponent>
                                        {(index !== (chunks.length - 1)) ? i % 2 === 0 ? index !== 2 ? <div className='arrow-left'></div> : '' : index !== 2 ? <div className='arrow-right'></div> : '' : ''}
                                    </>
                                ))
                                }
                            </Row>
                            {(i !== (claimsStages.length - 1)) &&
                                <Row className={(i % 2 === 0 ? "rightAlign" : "leftAlign")}>
                                    <div className="arrow-bottom"></div>
                                </Row>
                            }
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}