import { React, useEffect, useState } from "react";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../SCSS/Views/Login.scss";
import { Role } from "../../_helpers";
import { authenticationService } from "../../_services";
import { Formik } from "formik";
import * as yup from "yup";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ipNumber, setIpNumber] = useState("");

  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showIpNumberError, setShowIpNumberError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const schema = yup.object().shape({
    ipNumber: yup
    .number()
    .min(1000000000, "10 Digits Only")
    .max(9999999999, "10 Digits Only")
    .required("IP Number is Required"),
    password: yup
    .string().required("Please Enter Password")
    .max(20, "Password length must be upto 20 characters")
    .min(8, "Password length must be atleast 8 characters"),
  });

  useEffect(() => {
    // checkAuthAndContinue();
  }, []);

  const handleFormSubmit = async (e) => {
    setLoaderStatus(true);

    await authenticationService.validateAndLogin({ userName:e.ipNumber, password: e.password}).then(
      (response) => {
        console.log(response.jwt);
        if (response.jwt) {
          authenticationService.sessionService().then((res) => {
            if (localStorage.getItem("currentUser")) {
              setLoaderStatus(false);
              history.push("/user");
            }
          });
        } else if (response.errorCode) {
          setShowError(true);
          setLoaderStatus(false);
        }
      },
      (error) => {
        console.error(error);
        setShowError(true);
        setLoaderStatus(false);
      }
    );
  }
  
  const forgotPassword = async (e) => {
    setLoaderStatus(true);
    if(ipNumber == null || ipNumber == ""){
      e.preventDefault();
      setShowIpNumberError(true);
      setLoaderStatus(false);
    }else{
      localStorage.setItem('ipNumber', ipNumber);
      await authenticationService.forgotIpPassword({ ipNumber: ipNumber}).then(
        (response) => {
          console.log(response);
          if (response == true) {
            setLoaderStatus(false);
            history.push("/welcome/emailverification");
          } else {
            setLoaderStatus(false);
            setShowIpNumberError(true);
          }
        },
        (error) => {
          console.error(error);
          setLoaderStatus(false);
          setShowIpNumberError(true);
        }
      );
  }
  }

  const valid_numbers = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not 
    else {
      e.preventDefault();
      return false;
    }
  }

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <div className="container-employeelogin-card">
        {showError && (
          <TextError>
           <b> <p key="error" variant="danger">
              Username or Password is invalid
            </p></b>
          </TextError>
        )}
        {showIpNumberError && (
          <TextError>
            <b><p key="error" variant="danger">
              IP Number is invalid
            </p></b>
          </TextError>
        )}
        <Stack className="login-heading">
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            User Login
          </p>
        </Stack>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            ipNumber: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form className="esi-login-form" onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={1} >
                <div>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Ip Number/ವಿಮಾದಾರರ ಸಂಖ್ಯೆ</Form.Label>
                    <Form.Control
                      type="text"
                      name="ipNumber"
                      value={values.ipNumber}
                      onChange={e=> {handleChange(e); setIpNumber(e.target.value)}}
                      onBlur={handleBlur}
                      isValid={touched.ipNumber && !errors.ipNumber}
                      isInvalid={!!errors.ipNumber}
                      placeholder="Enter 10 digit IP Number"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={10}
                      onKeyPress={valid_numbers}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipNumber}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4 password-input"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password/ಪಾಸ್ವರ್ಡ್</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      minLength={8}
                      maxLength={20}
                      onChange={handleChange} 
                      placeholder="Enter Password"
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    {showPassword ? (
                      <div className="eye-icon">
                        <AiFillEye
                          cursor="pointer"
                          onClick={() => setShowPassword(false)}
                          size={23}
                        />
                      </div>
                    ) : (
                      <div className="eye-icon">
                        <AiFillEyeInvisible
                          cursor="pointer"
                          onClick={() => setShowPassword(true)}
                          size={23}
                        />
                      </div>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div style={{display:"flex",justifyContent:"space-between"}}>
                   
                       
                </div>

                <div style={{ textAlignLast: "center" }}>
                  <Button
                    variant="primary"
                    className="esi-navy-blue-button"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    style={{width:"100%"}}
                  >
                    Login
                  </Button>
                </div>
                <div>&nbsp;</div>
                <div style={{textAlign: "left",margin:"0 auto",widthg:"50%"}}>
                        <a 
                         variant="primary"
                         type="forgot password"
                         value={values.ipNumber}
                         onClick={forgotPassword}
                         style={{cursor:"pointer",color:"grey"}}>
                            Forget Password?
                        </a>
                        </div>
                        <div>&nbsp;</div>

                        <div style={{textAlign: "right",margin:"0 auto",widthg:"50%"}}>
                          Don't have an account? 
                        <Link to="/welcome/register"
                         variant="primary"
                         type="register"
                         className="blue-link"
                         style={{margin:"0 5px"}}>
                             Register Now
                        </Link>
                        </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Login;
