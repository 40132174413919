import { config } from '../constants';
import { http } from './http.service';

export const esiAdminService = {
    getRoles,
    getUsers,
    updateRole,
    updatePassword,
    createSingleUser,
    createMultipleUser
};

async function getRoles() {
    const url = `${config.apiUrl}/admin/roles`;
    return http.getData(url).then((roleList) => {
        return roleList;
    });
}

async function getUsers() {
    const url = `${config.apiUrl}/admin/list-users`;
    return http.getData(url).then((usersList) => {
        return usersList;
    });
}

async function updateRole(requestData) {
    const url = `${config.apiUrl}/admin/party/${requestData.userId}/update-role?role=${requestData.role}`;
    return http.putData(url).then((response) => {
        return response;
    });
}

async function updatePassword(requestData) {
    const url = `${config.apiUrl}/admin/party/${requestData.userId}/update-pwd?pwd=${requestData.password}`;
    return http.putData(url).then((response) => {
        return response;
    });
}

async function createSingleUser(params) {
    const url = `${config.apiUrl}/admin/party`;
  
    return http.postData(url, params.data).then((data) => {
      return data;
    });
  }
  
  async function createMultipleUser(params) {
    const url = `${config.apiUrl}/admin/party-bulk`;
  
    return http.postData(url, params.data).then((data) => {
      return data;
    });
  }