import { Card } from "react-bootstrap";
import React from "react";

export const CustomCard = (props) => {
  const { cardTitle, cardValue, backgroundColor } = props.data;

  return (
    <>
      <Card
        style={{
          width: "100%",
          height: "7rem",
          backgroundColor: backgroundColor,
          textAlign: "center",
          boxShadow: "4px 4px 5px #666",
          color: "0px 0px 2px 1px rgba(148,148,148,1)",
        }}
      >
        <Card.Body>
          <Card.Title className="h6"
            style={{
              color: "#696969",
            }}
          >
            {cardTitle}
          </Card.Title>
          <Card.Text
            style={{
              fontSize: "2rem",
            }}
          >
            {cardValue}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
