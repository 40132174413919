import React, { useEffect, useState } from "react";
import { Col, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CommonAccordian } from "../../CommonComponents/CommonAccordian";
import { CustomCard } from "../../CommonComponents/CustomCard";
import { CustomServerTable } from "../../CommonComponents/CwServerTable";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import "../../SCSS/Views/IpDashboard.scss";
import { IpClaimService } from "../../_services/IpClaimService";

const IpClaimHistoryAdmin = () => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [showClaimResults, setShowClaimResults] = useState(true);
  const [dashboardResponse, setDashboardResponse] = useState({});
  const [showError, setShowError] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);

  useEffect(() => {
    getDashBoardCounts();
  }, []);

  const getDashBoardCounts = async () => {
    const results = await IpClaimService.getDashBoardCounts().then(
      (res) => {
        setDashboardResponse(res);
      },
      (error) => {
        console.error(error);
        setShowError(true);
      }
    );
  };

  const redirectToAdd = () => {
    history.push("/user/addNewClaim");
  };
  const handleSelect = (key) => {
    console.log(key);
    if (key === "dependants") {
      setShowClaimResults(false);
    } else if (key === "overview") {
      setShowClaimResults(true);
    }
  };
  const data = {
    cardTitle: "Total",
    cardValue: `${
      !dashboardResponse.totalCount ? 0 : dashboardResponse.totalCount
    }`,
    backgroundColor: "#dff0f8",
  };
  const data2 = {
    cardTitle: "Submitted",
    cardValue: `${
      !dashboardResponse.submittedCount ? 0 : dashboardResponse.submittedCount
    }`,
    backgroundColor: "#fef7da",
  };

  const data3 = {
    cardTitle: "In Progress",
    cardValue: `${
      !dashboardResponse.inProgressCount ? 0 : dashboardResponse.inProgressCount
    }`,
    backgroundColor: "#77bbbbbf",
  };

  const data4 = {
    cardTitle: "Approved",
    cardValue: `${
      !dashboardResponse.approvedCount ? 0 : dashboardResponse.approvedCount
    }`,
    backgroundColor: "#e2ffdd",
  };

  const data5 = {
    cardTitle: "Rejected",
    cardValue: `${
      !dashboardResponse.rejectedCount ? 0 : dashboardResponse.rejectedCount
    }`,
    backgroundColor: "#ffdcdc",
  };

  const data6 = {
    cardTitle: "Objected",
    cardValue: `${
      !dashboardResponse.objectionCount ? 0 : dashboardResponse.objectionCount
    }`,
    backgroundColor: "#fbf89be0",
  }

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}

      <Stack direction="vertical">
        <div>
          <Tabs
            defaultActiveKey="overview"
            id="esi-tabs"
            className="mb-3"
            onSelect={(e) => handleSelect(e)}
          >
            <Tab eventKey="overview" title="Overview">
              <Row className="dashboard-card-row">
                <Col xs={12} lg={2}>
                  <CustomCard data={data} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data2} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data3} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data6} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data5} />
                </Col>
                <Col xs={12} lg={2}>
                  <CustomCard data={data4} />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="individualBills"
              title="Create Individual Bills"
              disabled
            ></Tab>
            <Tab
              eventKey="groupBills"
              title="Create Group Bills"
              disabled
            ></Tab>
          </Tabs>
        </div>
        <div className="acc-container">
          {showClaimResults ? (
            <CommonAccordian data={{ title: "Claim application history", eventKey: "0" }}>
              <CustomServerTable data={{ path: "/admin/ipClaimView" }} />
            </CommonAccordian>
          ) : null}
        </div>
      </Stack>
    </>
  );
};

export default IpClaimHistoryAdmin;
