import { Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";

export const ActivityCard = (props) => {
  const { createdByUsername, createdByUserRole, message, createdAt } = props.data;

  return (
    <>
      <Card style={{ width: '100%', border: '2px solid #172d66', 'border-radius': '20px', 'font-size': '14px' }}>
        <Card.Body>
          <Card.Title>
            <div className='row'>
              <div className='col-sm-4' style={{ 'font-size': '16px', 'color': 'grey' }}>
                {createdByUsername}
              </div>
              <div className='col-sm-2'>
              </div>
              <div className='col-sm-6' style={{ 'font-size': '14px', 'color': 'grey', 'text-align': 'right' }}>
                <Moment format={"DD-MM-YYYY hh:mm A"}>{createdAt}</Moment>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12' style={{ 'font-size': '14px', 'color': 'grey' }}>
                {{
                  ROLE_IP_USER: <div>Role: IP User</div>,
                  ROLE_CASE_WORKER: <div>Role: case Worker</div>,
                  ROLE_PHARMACIST: <div>Role: Pharmacist</div>,
                  ROLE_OS: <div>Role: OS</div>,
                  ROLE_SS: <div>Role: SS</div>,
                  ROLE_AO: <div>Role: OA</div>,
                  ROLE_CSO: <div>Role: CSO</div>,
                  ROLE_DDA: <div>Role: DDA</div>,
                  ROLE_DDO: <div>Role: DDO</div>,
                  ROLE_D_CASE_WORKER: <div>Role: Directorate Case Worker</div>,
                  ROLE_D_PHARMACIST: <div>Role: Directorate Pharmacist</div>,
                  ROLE_ADMIN: <div>Role: Admin</div>
                }[createdByUserRole]}
              </div>
            </div>

          </Card.Title>
          <Card.Text>
            <div className='row'>
              <div className='col-sm-12' style={{ 'font-size': '15px', 'color': '#172d66', 'text-align': 'right' }} dangerouslySetInnerHTML={{ __html: message}}>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
