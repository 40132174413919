import { config } from "../constants";
import { claimTabledata } from "../Test/Data/ClaimTableData";
import { OneIpClaimData } from "../Test/Data/OneIpClaimData";

import { claimStatusList } from "../Test/Data/ClaimStatusData";
import { http } from "./http.service";
import { authenticationService } from ".";
import { Role } from "../_helpers";

export const PcClaimService = {
    getPCClaimList,
    getPCClaimByNumber,
    getClaimComments,
    getClaimStatusList,
    getSOBList,
    createComments,
    addSOBList,
    downloadCSV,
    getStates
};

async function getPCClaimList() {
    return claimTabledata;
};

async function getPCClaimByNumber() {
    return OneIpClaimData;
};

async function getClaimComments(claimNumber) {
    const url = `${config.claimsApiUrl}/comment/get-comments?claimId=${claimNumber}`;
  return http.getData(url).then((res) => {
    return res;
  });
  // return CommentData;
};

async function getClaimStatusList(){
    return claimStatusList;
}
async function getSOBList(claimNumber){
    const url = `${config.claimsApiUrl}/sob/get-claim-sob?claimId=${claimNumber}`;
    return http.getData(url).then((res) => {
        return res;
    });
}

async function addSOBList(params){
    const url = `${config.claimsApiUrl}/sob/save-sob`;
    const requestBody = {
        "claimId": params.claimId,
        "bills":  params.bills
    };

    return http.postData(url, requestBody).then((res) => {
        return res;
    });
}

async function createComments(params) {
    const url = `${config.claimsApiUrl}/comment/add`;
    const requestBody = {
      comment: params.comment,
      claimId: params.claimId,
      ipUser: checkPharmacist(),
      external: params.external,
    };
  
    return http.postData(url, requestBody).then((res) => {
      return res;
    });
}

async function downloadCSV(sobUUID) {
  const url = `${config.claimsApiUrl}/sob/download?sobUUID=${sobUUID}`;
  const headers = {
    responseType: 'arraybuffer'
  }
  return http.getData(url, headers).then((download) => {
    return download;
  });
}

async function getStates() {
  const url = `${config.claimsApiUrl}/data/get-states`;
  return http.getData(url).then((res) => {
    return res;
  })
}

  function checkPharmacist() {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser.authorites.indexOf(Role.pharmacist) == -1) {
      return false;
    }
    return true;
  }
