import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component-footer";

import "../../../SCSS/Views/SOBCard.scss";
import { useParams } from "react-router-dom";
import versionHistoryIcon from "../../../assests/svg/version-history.svg";
import downloadIcon from "../../../assests/svg/csv.svg";
import editIcon from "../../../assests/svg/edit.svg";
import editRound from "../../../assests/svg/edit-round.svg";
import sobLatest from "../../../assests/svg/sob-icon.svg";
import { AddSOB } from "./AddSOB";
import { VersionHistorySOB } from "./VersionHistorySOB";
import { PcClaimService } from "../../../_services/PcClaimService";
import Moment from "react-moment";
import { formatDate, formatDateAndTime } from "../../../_helpers/date-format";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";

const numWords = require("num-words");

export const ViewSOBTable = (props) => {

    const { data } = props;
    const { caseNumber } = useParams();
    const [totalBillAmount, setTotalBillAmount] = useState(0)
    const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [checkEditOption, setCheckEditOption] = useState(-1);
    const [dataList, setDataList] = useState([]);
    const [showEditButton, setShowEditButton] = useState(false);
    const [showLatestButton, setShowLatestButton] = useState(false);

    const [loaderStatus, setLoaderStatus] = useState(false);

    useEffect(() => {            
        calculateApprovedAmount(data.sobList);
        calculateBillAmount(data.sobList);
        setDataList(data.sobList);
        if (JSON.parse(localStorage.currentUser).dispensaries.includes(data.claim?.dispenseryId)) {
            setShowEditButton(true);
        }
        if (data.claim.claimStatus === 'Approved' ||
            data.claim.claimStatus === 'Rejected' ||
            data.claim.claimStatus === 'Objection') {
            setShowEditButton(false);
        }
        if(data.showButton){
            setShowLatestButton(data.showButton);
        }
       if( data.sobDisplayStatus === true )setShowEditButton(true)
        else setShowEditButton(false)
    }, [])

    const setSOBList = (sobList) => {
        data.setSOBList(sobList);
    }
    const getSOBData = async () => {
        //setLoaderStatus(true);
        await PcClaimService.getSOBList(caseNumber).then((res) => {
            if (res.length > 0) {
                res.map((sob) => {
                    if (sob.active) {
                        setSOBList(sob.bills);
                        setDataList(sob.bills);
                        setLatestData(sob.bills);
                        //setLoaderStatus(false);
                    }
                })
            }
        })
    }
    const calculateBillAmount = async (sList) => {
        let billAmount = 0;
        if (sList && sList.length != 0) {
            sList.map((sob) => {
                billAmount = billAmount + parseInt(sob.billAmount);
            })
        }
        setTotalBillAmount(billAmount);
    }

    const calculateApprovedAmount = async (sList) => {
        let approvedAmount = 0;
        if (sList && sList.length != 0) {
            sList.map((sob) => {
                approvedAmount = approvedAmount + parseInt(sob.approvedAmount);
            })
        }
        setTotalApprovedAmount(approvedAmount);

    }

    const handleEdit = async (i) => {
        setCheckEditOption(i)
    }

    const handleAllBills = async () => {
        setCheckEditOption(100)
    }

    const handleSubmit = () => {
        setCheckEditOption(-1);
        getSOBData();
        if(props.handleRefresh){
            props.handleRefresh();
        }
    }

    const handleCancel = () => {
        setCheckEditOption(-1);
        setDataList(data.sobList);
    }

    const download = async (e) => {
        try {
            const response = await PcClaimService.downloadCSV(data.sobUUID)
            var a = document.createElement("a");
            document.body.appendChild(a);
            let blob = new Blob([s2ab(atob(response))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'sobDetails.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
        }
    };
    const s2ab = s => {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#d3d3d3",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                //width: "99.6%"
            }
        },
        headCells: {
            style: {
                fontSize: "16px !important",
                fontWeight: "bold !important",
                backgroundColor: "#d3d3d3",
                //borderRight: "1px solid #172d66",
            }
        },
        'headCells:last-child': {
            style: {
                borderRight: "none"
            }
        },
        cells: {
            style: {
                fontSize: "14px",
                borderBottom: "1px solid #d3d3d3",
                //borderRight: "1px solid #172d66"
            }
        },
        'cells:last-child': {
            style: {
                display: "none !important"
            }
        }
    }

    const columns = [
        {
            name: "Sl No",
            selector: 'id',
            center: true,
            maxWidth: "5%"
        },
        {
            name: "Bill Number",
            selector: 'billNumber',
            center: true,
            maxWidth: "15%"
        },
        {
            name: "Bill Date",
            cell:(row) => formatDate(row.billDate),
            center: true,
            maxWidth: "15%"
        },
        {
            name: "Description",
            selector: 'description',
            center: true,
            maxWidth: "25%"
        },
        {
            name: "Bill Amount",
            selector: "billAmount",
            right: true,
            maxWidth: "15%"
        },
        {
            name: "Eligible Amount",
            selector: "approvedAmount",
            right: true,
            maxWidth: "15% "
        },
        {
            name: "Edit",
            cell: (row, index) => (index != -1) ? <div> {showEditButton && <><img src={editIcon} style={{ width: "25px", cursor:"pointer" }} onClick={() => { handleEdit(index) }} /> </>}</div> : <div></div>,
            center: true,
            maxWidth: "5%"
        }
    ]
    const footer = {
        description: "Total",
        billAmount: '₹' + totalBillAmount,
        approvedAmount: '₹' + totalApprovedAmount
    }
    const getNumtoWords = (amount) => {
        try {
            return numWords(parseFloat(amount)) + " Rupees only";
        } catch (e) {
            return "Invalid Data";
        }
    };

    const setLatestData = (list) => {
        setDataList(list);
        setShowLatestButton(false);
        calculateApprovedAmount(list);
        calculateBillAmount(list);
    }

    return (
        <div>
            {loaderStatus ? <EsiLoader /> : null}
            {(checkEditOption != -1) ? <AddSOB data={{ sobList: data.sobList, edit: checkEditOption, claim: data.claim }} handleSubmit={ handleSubmit } handleCancel={handleCancel} /> :
                (showVersionHistory) ? <VersionHistorySOB data={{ claim: data.claim, billAmount: totalBillAmount, approvedAmount: totalApprovedAmount, bills: data.sobList.length }} /> :
                        <div>
                            <Row className="pc-sob-stack">
                                <Col style={{ flex: '2', maxWidth: 'fit-content' }}>
                                    <div className="pc-sob-label">Total Eligible Amount: <span className="currency-color">₹</span>{totalApprovedAmount} </div>
                                    <div className="pc-sob-words">({getNumtoWords(totalApprovedAmount)})</div>
                                </Col>
                                <Col style={{ flex: '2', maxWidth: 'fit-content' }}>
                                    <div className="pc-sob-label">Total Bill Amount: <span className="currency-color">₹</span>{totalBillAmount}</div>
                                    <div className="pc-sob-words">({getNumtoWords(totalBillAmount)})</div>
                                </Col>
                                <Col style={{ flex: '1' }}>
                                    <div className="pc-sob-label">Total Bills: {dataList ? dataList.length : 0} </div>
                                    <div></div>
                                </Col>
                                <Col className="ms-auto"></Col>
                                <Col className={showLatestButton ? "icon-content-button" : "no-display"}>
                                    <Button className="sob-icon-button float-right"><img src={sobLatest} style={{ width: "32px" }} onClick={() => setLatestData(data.latest)} />
                                        <label className="button-text">LATEST SOB</label></Button>
                                </Col>
                                <Col className="icon-content-button">
                                    <Button className="sob-icon-button float-right"><img src={versionHistoryIcon} style={{ width: "43px" }} onClick={() => setShowVersionHistory(true)} />
                                        <label className="button-text">VERSION HISTORY</label> </Button>
                                </Col>
                                <Col className="icon-content-button">
                                    <Button className="sob-icon-button float-right"><img src={downloadIcon} style={{ width: "35px" }} onClick={() => download()} />
                                        <label className="button-text">DOWNLOAD</label></Button>
                                </Col>
                                {showEditButton && <Col className="icon-content-button">
                                    <Button className="sob-icon-button float-right"><img src={editRound} style={{ width: "45px" }} onClick={() => handleAllBills()} />
                                        <label className="button-text">EDIT BILLS</label></Button>
                                </Col>}
                            </Row>
                            <div className="table-container">
                                <DataTable className="view-sob-table"
                                    columns={columns}
                                    data={dataList}
                                    customStyles={customStyles}
                                    footer={footer}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="200px" />
                            </div>
                        </div>
            }
        </div>
    )
}