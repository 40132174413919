import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import NotFound from "./NotFound";
import WelcomePage from "./Welcome/Welcome";
import Register from "./Welcome/Register";
import Login from "./Welcome/Login";
import ResetPassword from "./Welcome/ResetPassword";
import IpClaimHistory from "./User/IpClaimHistory";
import AdminHome from "./Admin/AdminHome";
import AdminLogin from "./Admin/AdminLogin";
import IpClaimHistoryAdmin from "./Admin/IpClaimHistoryAdmin";
import { PrivateRoute } from "./Auth/PrivateRoute";
import { Role } from "../_helpers";
import OtpValidation from "./Welcome/OtpValidation";
import EmailVerification from "./Welcome/EmailVerification";
import UserPage from "./User/User";
import AddNewClaim from "./User/AddNewClaim";
import { IpClaimView } from "./User/IpClaimView";
import AdminPage from "./Admin/Admin";
import Topbar from "./Topbar/Topbar";
import UserProfileView from "./User/UserProfileView";
import { ClaimTabView } from "./Admin/IpClaimView/ClaimTabView";
import AdminProfileView from "./Admin/AdminProfileView";
import EsiAdminLogin from "./UserManagement/EsiAdminLogin";
import AdminHomePage from "./UserManagement/AdminHomePage";






const Routers = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/welcome" />
      </Route>
      <Route
        path={"/welcome"}
        render={({ match: { path } }) => (
          <WelcomePage>
            <Switch>
              <Route exact path={path + "/"} component={Login} />
              <Route exact path={path + "/esiadmin"} component={EsiAdminLogin} />
              <Route path={`${path}/register`} component={Register} />
              <Route path={`${path}/resetpassword`} component={ResetPassword} />
              <Route path={`${path}/emailverification`} component={EmailVerification} />
              {/* <Route path={`${path}/otpValidation`} component={OtpValidation} /> */}
              <Route path={`${path}/admin/login`} component={AdminLogin} />
              <Redirect exact from={path + "/*"} to={"/welcome"} />
            </Switch>
          </WelcomePage>
        )}
      />
      <div>
        <Topbar></Topbar>
        <Route
          path={"/user"}
          render={({ match: { path } }) => (
            <UserPage>
              <Switch>
                <PrivateRoute
                  exact
                  path={path + "/"}
                  roles={[Role.ipUser]}
                  component={IpClaimHistory}
                />
                <PrivateRoute
                  path={`${path}/addNewClaim`}
                  roles={[Role.ipUser]}
                  component={AddNewClaim}
                />
                <PrivateRoute
                  path={`${path}/ipClaimView/:caseNumber`}
                  roles={[Role.ipUser]}
                  component={IpClaimView}
                />

                <PrivateRoute
                  path={`${path}/UserProfileView`}
                  roles={[Role.ipUser]}
                  component={UserProfileView}
                />
                <Redirect exact from={path + "/*"} to={"/welcome"} />
              </Switch>
            </UserPage>
          )}
        />

        <Route
          path={"/admin"}
          render={({ match: { path } }) => (
            <AdminPage>
              <Switch>

                <Route
                  path={`${path}/homePage`}
                  component={AdminHomePage}
                />

                
                <PrivateRoute
                  exact
                  path={path + "/"}
                  roles={[Role.caseWorker,Role.ss, Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist]}
                  component={IpClaimHistoryAdmin}
                />
                <PrivateRoute
                  path={`${path}/adminHome`}
                  roles={[Role.caseWorker]}
                  component={AdminHome}
                />
                <PrivateRoute
                  path={`${path}/ipClaimView/:caseNumber?`}
                  roles={[Role.caseWorker, Role.ss,Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist]}
                  component={ClaimTabView}
                />
                <PrivateRoute
                  path={`${path}/AdminProfileView`}
                  roles={[Role.caseWorker, Role.ss,Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist]}
                  component={AdminProfileView}
                />
                <Redirect
                  exact
                  from={path + "/*"}
                  to={"/welcome/admin/login"}
                />

              </Switch>
            </AdminPage>
          )}
        />
      </div>
      <Route path="/not-found" component={NotFound} />
      <Redirect exact from={"*"} to={"/not-found"} />
    </Switch>
  );
};

export default Routers;
