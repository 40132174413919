import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { IpClaimService } from "../../../_services/IpClaimService";
import { useHistory } from "react-router-dom";

export const RejectedModal = (props) => {
  const history = useHistory();
  const handleClick = async () => {
    const res = await IpClaimService.rejectClaim(props.data.caseNumber);
    props.onHide();
    history.push("/admin");
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form>
          <ImCross className="imcross" onClick={props.onHide} />
          <Modal.Body>
            <Container>
              <Row>
                <ImCross
                  style={{
                    color: "red",

                    marginBottom: "2rem",
                  }}
                  onClick={props.onHide}
                  size={40}
                />
              </Row>
              <span style={{ marginLeft: "11rem" }}>
                
                Are you sure you want to reject the claim
              </span>
            </Container>
          </Modal.Body>
          <Button onClick={handleClick} className="esi-modal-blue-button">
            OK
          </Button>
        </Form>
      </Modal>
    </>
  )
}
