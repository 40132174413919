import "../../SCSS/Views/Footbar.scss";
import React, { Component } from "react";

export default class Footbar extends Component {
  render() {
    return (
      <>
        {/* <Grid className="footbar" columns="equal">
          <Grid.Row>
            <Grid.Column className="footer-logo">
              <a href="https://www.meity.gov.in/" target="_blank">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/Meity_logo.png"
                  alt="Meity_Logo"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a href="https://digitalindia.gov.in/" target="_blank">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/digital-india-logo.png"
                  alt="digital"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a href="https://data.gov.in/" target="_blank">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/data-gov-logo.png"
                  alt="data"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a href="https://www.india.gov.in/" target="_blank">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/india-gov-logo.png"
                  alt="India"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a href="https://www.pmindia.gov.in/en/" target="_blank">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/pm-india-logo.png"
                  alt="PM"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a
                href="https://www.karnataka.gov.in/frontend/opt1/images/GIGW.jpeg"
                target="_blank"
              >
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/WCAG.jpeg"
                  alt="gigw"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a
                href="https://validator.w3.org/check?uri=referer"
                target="_blank"
                className="footer_image"
              >
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/w3c.png"
                  alt="w3c"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
            <Grid.Column className="footer-logo">
              <a className="footer_image">
                <Image
                  src="https://www.karnataka.gov.in/frontend/opt1/images/kp_kn.jpeg"
                  alt="kp_kn"
                  className="footer_image"
                ></Image>
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}
      </>
    );
  }
}
//   return (
//     <>
//       <div class="footbar">
//         <div class="">
//           <ul class="list-inline ">
//             <li>
//               <a
//                 href="https://www.meity.gov.in/"
//                 target="_blank"
//                 class="md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/Meity_logo.png"
//                   alt="Meity_logo"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://digitalindia.gov.in/"
//                 target="_blank"
//                 class="md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/digital-india-logo.png"
//                   alt="digital"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://data.gov.in/"
//                 target="_blank"
//                 class="md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/data-gov-logo.png"
//                   alt="data"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://www.india.gov.in/"
//                 target="_blank"
//                 class="md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/india-gov-logo.png"
//                   alt="India"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://www.pmindia.gov.in/en/"
//                 target="_blank"
//                 class="md-opjjpmhoiojifppkkcdabiobhakljdgm_doc"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/cm_logo/pm-india-logo.png"
//                   alt="pm"
//                   class="footer_image"
//                 />
//               </a>
//             </li>

//             <li class="footer4_divider"></li>
//             <li></li>

//             <li>
//               <a class="no-pointer">
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/GIGW.jpeg"
//                   alt="gigw"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a class="no-pointer">
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/WCAG.jpeg"
//                   alt="wcag"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a class="no-pointer">
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/SSLcertificate.png"
//                   alt="ssl"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://validator.w3.org/check?uri=referer"
//                 target="_blank"
//               >
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/w3c.png"
//                   alt="w3c"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//             <li>
//               <a class="no-pointer">
//                 <img
//                   src="https://www.karnataka.gov.in/frontend/opt1/images/kp_kn.jpeg"
//                   alt="kp_kn"
//                   class="footer_image"
//                 />
//               </a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// }
