import React, { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";

export const DeleteSOBConfirm = (props) => {


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Are You Sure</Modal.Title>

                    <ImCross className="modal-im-cross" onClick={props.onHide} />
                </Modal.Header>
                <Modal.Body className="confirm-modal-body">
                    <AiOutlineWarning color="red" size={100}></AiOutlineWarning>
                    <p> Are you sure you want to Delete This SOB </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="esi-confirm-modal-button" type="submit"
                        onClick={() => { props.onConfirm(props.data) }}>
                        YES
                    </Button>
                    <Button
                        className="esi-confirm-modal-cancel-button"
                        onClick={() => { props.onHide() }}
                    >
                        NO
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
