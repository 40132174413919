import { React, useEffect, useState } from "react";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../SCSS/Views/Login.scss";
import { Role } from "../../_helpers";
import { authenticationService } from "../../_services";
import { Formik } from "formik";
import * as yup from "yup";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const AdminLogin = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ipName, setIpName] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const schema = yup.object().shape({
    userName: yup
      .string()
      .matches(/^[ A-Za-z0-9]*$/, "Only alphabets are allowed for this field ")
      .required("User Name is Required"),
    password: yup.string().required("Please Enter Password"),
  });

  useEffect(() => {
    // checkAuthAndContinue();
  }, []);

  const checkAuthAndContinue = async () => {
    if (
      authenticationService.currentUserToken &&
      authenticationService.currentUserToken.jwt
    ) {
      setLoaderStatus(true);
      authenticationService.sessionService().then(
        (response) => {
          setLoaderStatus(false);

          if (response.category === Role.Admin) {
            history.push("/admin");
          } else if (response.category === Role.User) {
            history.push("/welcome/otpValidation");
          }
        },
        (error) => {
          setLoaderStatus(true);
          localStorage.removeItem("currentUser");
          localStorage.removeItem("userToken");
          authenticationService.updateJwtToken(null);
          authenticationService.updateUserData(null);
        }
      );
    }
  };

  const handleFormSubmit = async (e) => {
    // e.preventDefault();
    setLoaderStatus(true);
    setUserName(e.userName);
    setPassword(e.password);
    await authenticationService
      .validateAndLogin({
        userName: e.userName,
        password: e.password,
      })
      .then(
        (response) => {
          console.log(response.jwt);
          if (!userName && !password) {
            localStorage.username = userName;
            localStorage.password = password;
          }
          setLoaderStatus(false);
          if (response.jwt) {
            authenticationService.sessionService().then((res) => {
              if (localStorage.getItem("currentUser")) history.push("/admin");
            });
          } else if (response.response) {
            setShowError(true);
          }
        },
        (error) => {
          console.error(error);
          setShowError(true);
          setLoaderStatus(false);
        }
      );
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <div className="container-employeelogin-card">
        {showError && (
          <TextError>
            <p key="error" variant="danger">
              Username or Password is invalid
            </p>
          </TextError>
        )}
        <Stack className="login-heading">
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Employee Login
          </p>
        </Stack>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            userName: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form className="esi-login-form" onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={1}>
                <div>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Employee Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="userName"
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.userName && !errors.userName}
                      isInvalid={!!errors.userName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.userName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4 password-input"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Employee Password</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    {showPassword ? (
                      <div className="eye-icon">
                        <AiFillEye
                          cursor="pointer"
                          onClick={() => setShowPassword(false)}
                          size={23}
                        />
                      </div>
                    ) : (
                      <div className="eye-icon">
                        <AiFillEyeInvisible
                          cursor="pointer"
                          onClick={() => setShowPassword(true)}
                          size={23}
                        />
                      </div>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ textAlignLast: "center" }}>
                  <Button
                    variant="primary"
                    className="esi-navy-blue-button"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AdminLogin;
