import { authenticationService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    let token = "";
    if (authenticationService.currentUserToken && authenticationService.currentUserToken.jwt) {
        token = authenticationService.currentUserToken;
    } else if (localStorage.getItem('userToken')) {
        token = JSON.parse(localStorage.getItem('userToken'));
    };

    if (token) {
        return { Authorization: `Bearer ${token.jwt}` };
    } else {
        return {};
    }
}