import { Modal } from "react-bootstrap";
import "../../../SCSS/CommonComponentsCSS/ActivityModal.scss";
import { ActivityStateComponent } from "../../../CommonComponents/ActivityStateComponent";
import  closeActivity from "../../../assests/svg/close-activity.svg";
import { useEffect, useState } from "react";
import { DependantService } from "../../../_services/DependantService";

export const ActivityModal = (props) => {
const [data, setData] = useState([]);
const [showError, setShowError] = useState(false);
const [errorMessage, setErrorMessage] = useState(undefined);
const [loaderStatus, setLoaderStatus] = useState(false);
const [timelineData, setTimelineData] = useState([]);

useEffect(() => {
    fetchClaimActivities();
  }, [props.caseDetails]);

useEffect(() => {
  fetchTimeLineSeries();
},[data]);

  const fetchTimeLineSeries = () => {
    let mytimelineData = [];
    console.log("Timeline" ,data) 
    const activityLength = data.length;
    console.log("activity length ",activityLength)
    for (let i = 0; i < activityLength; i++) {
      mytimelineData.push({});
    }
    setTimelineData(mytimelineData);
    console.log("Time line data",timelineData)
  }

  const fetchClaimActivities = async () => {
    console.log("calling fetch claim activities")
    await DependantService.getClaimActivities(props.caseDetails).then(
      (res) => {
        setLoaderStatus(false);
        setData(res);
      },
      (error) => {
        console.error(error);
        setShowError(true);
        setLoaderStatus(false);
        setErrorMessage("Something went wrong..., contact administrator!!!");
      }
    );
  }

  const TimelineItem = ({timeData}) => (
    <div className="timeline-item">
        <div className="timeline-item-content">
          <span className="circle" />
        </div> 
    </div>
  );

  const Timeline = () =>
    timelineData.length > 0 && (
      <div className="timeline-container">
          {timelineData.map((timeData, idx) => (
              <TimelineItem data={timeData} key={idx} />
          ))}
      </div>
  ); 

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="activity-modal-content"
      > 
     <div>  
    
    </div>
   
        <Modal.Body>
            <div className="activity-box"> 
                <div className="activity-close-container">
                  <img src={closeActivity} className="activity-close" onClick={props.onHide} /> 
                </div>
                <div className="activity-header">
                  ACTIVITIES
                </div>
                <div className="activity-subheader">
                  {data.length} logged events
                </div>
                <div className="activity-scroll">
                  <div className='row'>
                    <div className='col-sm-2'>
                      <Timeline/>
                      </div>
                      <div className='col-sm-10'>
                        {data.map((activity,index)=>(
                            <div className="activity-content">
                            <ActivityStateComponent activityData={activity}/>
                            </div>
                        ))}
                      </div>
                    </div>
                </div>
            </div>  
            
          </Modal.Body>
      </Modal>
      </>
  );
};
