import React from "react";
import Button from "react-bootstrap/Button";
import "../../SCSS/Views/OtpValidation.scss";
import { authenticationService } from "../../_services";
import { Link } from "react-router-dom";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import Countdown from "react-countdown";

class Otpinput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      disable: true,
      error: false,
      loaderStatus: false,
      activateReset: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value });
    console.log(localStorage.getItem("userData"));
  }

  async handleSubmit(event) {
    console.log('Inside handle submit')
    this.setState({ loaderStatus: true });
    event.preventDefault();
    const ipNumber = localStorage.getItem("ipNumber");
    const verificationCode = Number(
        this.state.otp1 +
        this.state.otp2 +
        this.state.otp3 +
        this.state.otp4 +
        this.state.otp5 +
        this.state.otp6
    );

    await authenticationService.validateVerificationCode({ ipNumber: ipNumber, verificationCode: verificationCode}).then(
      (response) => {
        console.log(response);
        if (response.jwt != null) {   
          this.setState({ loaderStatus: false });          
          this.props.history.push("/welcome/resetpassword");
        } else if (response.errorCode) {
          this.setState({ error: true });   
          this.setState({ loaderStatus: false });        
        }else{
          this.setState({ error: true });   
          this.setState({ loaderStatus: false }); 
        }
      },
      (error) => {
        console.error(error);
        this.setState({ error: true });   
        this.setState({ loaderStatus: false });      
      }
    );
  }
  
  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  render() {

    return (
      <>
        {/* {this.state.loaderStatus ? <EsiLoader /> : null} */}

        <div className="otp-container">
          {this.state.error && (
            <TextError>
              <b>
              <p key="error" variant="danger">
                Invalid verification code, Try again.
              </p></b>
            </TextError>
          )}
          <form className="esi-login-form-otp" onSubmit={this.handleSubmit}>
            <div style={{ textAlignLast: "center" }}>
              <div className="login-headings">
                <b>Insured Person Beneficiary Login</b>
              </div>
              <Link className="login-heading1" to="/welcome">
                GO BACK
              </Link>
              <div className="login-heading2">Enter 6-digit code </div>
              <div className="login-heading3">
                We have sent 6-digit code to your registered email address
              </div>
            </div>
            <div className="otp-input-box-div">
              <input
                name="otp1"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp1}
                onKeyPress={this.keyPressed}
                onChange={(e) => this.handleChange("otp1", e)}
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp2}
                onChange={(e) => this.handleChange("otp2", e)}
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp3}
                onChange={(e) => this.handleChange("otp3", e)}
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp4}
                onChange={(e) => this.handleChange("otp4", e)}
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />

              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp5}
                onChange={(e) => this.handleChange("otp5", e)}
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />

              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={this.state.otp6}
                onChange={(e) => this.handleChange("otp6", e)}
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
                required
              />
            </div>
            {/* <div style={{ textAlignLast: "center" }}>
              <Countdown date={Date.now() + 30000} renderer={this.renderer} />
            </div> */}
            <div style={{ textAlignLast: "center" }}>
              <Button to="/welcome/forgotpassword"
                variant="primary"
                className="esi-navy-blue-button"
                id="esi-navy-blue-button1"
                type="submit"
              >
                Confirm
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }
}


export default Otpinput;
