import React, { useState } from "react";
import * as yup from "yup";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import { AiOutlineWarning } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { DependantService } from "../../../_services/DependantService";
import TextError from "../../../CommonComponents/TextError";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import EsiDatePicker from "../../../CommonComponents/EsiDatePicker";

export const ConfirmModal = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const sendForDelete = async (e) => {
    e.preventDefault();
    setLoaderStatus(true);
    await DependantService.deleteDependant(props.data.rowData).then(
      (res) => {
        setLoaderStatus(false);
        console.log(res);
        props.onHide();
      },
      (error) => {
        console.error(error);
        setShowError(true);
        setLoaderStatus(false);
        setErrorMessage("Something went wrong... contact administrator!!!");
      }
    );
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={sendForDelete}>
          <Modal.Header>
            {/* <button type="button" class="btn-close" aria-label="Close"></button> */}
            <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>

            <ImCross className="modal-im-cross" onClick={props.onHide} />
          </Modal.Header>
          <Modal.Body className="confirm-modal-body">
            {showError ? <TextError>{errorMessage}</TextError> : null}
            <AiOutlineWarning color="red" size={100}></AiOutlineWarning>
            <p>Are you sure you want to delete? </p>

            <p> Dependant Name is {props.data.dependentRecord.dependentName} and UHID is {props.data.dependentRecord.patientUhid} </p>
            {/* {props.data.shashkey}
            {props.mydata} */}
          </Modal.Body>
          <Modal.Footer>
            <Button className="esi-confirm-modal-button" type="submit">
              YES
            </Button>
            <Button
              className="esi-confirm-modal-cancel-button"
              onClick={props.onHide}
              setShowError={setShowError}
            >
              NO
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
