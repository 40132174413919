export const IpClaimDataList = [
  {
    caseNumber: "C36745",
    ipNumber: "54635356",
    patientName: "Madhusudhan",
    claimAmount: "4000.50",
    approvedAmount: "5000",
    contactNumber: "9675666634",
    relationship: "son",
    status: "submitted",
    claimDate: "01/03/2021",
  },
  {
    caseNumber: "C36746",
    ipNumber: "54635356",
    patientName: "Madhusudhan",
    claimAmount: "4000.50",
    approvedAmount: "5000",
    contactNumber: "9675666634",
    relationship: "son",
    status: "submitted",
    claimDate: "01/03/2021",
  },
  {
    caseNumber: "C36747",
    ipNumber: "54635356",
    patientName: "Madhusudhan",
    claimAmount: "4000.50",
    approvedAmount: "5000",
    contactNumber: "9675666634",
    relationship: "son",
    status: "submitted",
    claimDate: "01/03/2021",
  },
  {
    caseNumber: "C36748",
    ipNumber: "54635356",
    patientName: "Madhusudhan",
    claimAmount: "4000.50",
    approvedAmount: "5000",
    contactNumber: "9675666634",
    relationship: "son",
    status: "submitted",
    claimDate: "01/03/2021",
  },
];
