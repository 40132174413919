import React, { Component } from 'react'
import { ImBin, ImCross, ImEye } from 'react-icons/im'
import SignaturePad from 'react-signature-canvas'

import styles from './styles.module.css'

export default class SignCanvas extends Component {
  state = { trimmedDataURL: null }
  sigPad = {}

  clear = () => {
    this.sigPad.clear();
  }
  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/jpg') });
    this.props.parentCallback(this.sigPad.getTrimmedCanvas().toDataURL('image/jpg'));
  }


  render() {
    let { trimmedDataURL } = this.state
    return <div className={styles.container}>
      <div className={styles.sigContainer}>
        <SignaturePad canvasProps={{ className: styles.sigPad }}
          ref={(ref) => { this.sigPad = ref }} />
      </div>
      <div className='float-end'>
        <div className={styles.buttons} style={{cursor:"pointer"}} onClick={this.clear}><ImCross style={{ color: "red" }}></ImCross> Clear </div>
        <div className={styles.buttons} style={{cursor:"pointer"}} onClick={this.trim}><ImEye style={{ color: "#172d66" }}></ImEye> Preview </div>
      </div>
      {trimmedDataURL
        ? <img className={styles.sigImage}
          src={trimmedDataURL} />
        : null}
    </div>
  }
}
