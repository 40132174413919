import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './ClaimStageComponent.scss';
import { Role } from "../../_helpers";
import Moment from 'react-moment';

export const ClaimStageCommonComponent = ({ status, flow, position }) => {
    return (
        <>
            <div className={["outter-card", (flow)].join(' ')}>
                <div className='role-card'>Role : {status.displayName}</div>
                <div className='inner-Card'>
                    <Row>
                        <Col xs={3}>
                            <div className='iconNumber'>
                                <div className='icon_div'>
                                    <div className={["esiUserIcon-svg", "iconClass"].join(' ')}></div>
                                </div>
                                <div>
                                    {status.role === Role.ipUser && <div className='name_div'>IP No</div>}
                                    {status.role !== Role.ipUser && <div className='name_div'>EMP No</div>}
                                    <div className='value_div' >{status?.claimStatus?.userId}</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={9} className='info-card'>
                            <Row className='infoRow'>
                                <Col xs={6}>
                                    {status.role === Role.ipUser && <span className='infoKey'>IP Name</span>}
                                    {status.role !== Role.ipUser && <span className='infoKey'>Name</span>}
                                    <span className='dilimetry'>:</span>
                                </Col>
                                <Col xs={6} className='infoValue' title={status?.claimStatus?.name}>
                                    {status?.claimStatus?.name}
                                </Col>
                            </Row>
                            <Row className='infoRow'>
                                <Col xs={6}>
                                    <span className='infoKey'>Claim Amount</span>
                                    <span className='dilimetry'>:</span>
                                </Col>
                                <Col xs={6} className='infoValue' title={status?.claimStatus?.claimAmount}>
                                    {status?.claimStatus?.claimAmount}
                                </Col>
                            </Row>
                            <Row className='infoRow'>
                                <Col xs={6}>
                                    {status.role === Role.ipUser && <span className='infoKey'>Claim Date</span>}
                                    {status.role !== Role.ipUser && <span className='infoKey'>Processed Date</span>}
                                    <span className='dilimetry'>:</span>
                                </Col>
                                <Col xs={6} className='infoValue' title={status?.claimStatus?.date}>
                                    {status?.claimStatus?.date && <Moment format={"DD-MM-YYYY hh:mm A"}>{status?.claimStatus?.date}</Moment>}
                                </Col>
                            </Row>
                            {status.role === Role.ipUser &&
                                <Row className='infoRow'>
                                    <Col xs={6}>
                                        <span className='infoKey'>Relationship</span>
                                        <span className='dilimetry'>:</span>
                                    </Col>
                                    <Col xs={6} className='infoValue' title={status?.claimStatus?.Relationship}>
                                        {status?.claimStatus?.relationship}
                                    </Col>
                                </Row>
                            }
                            {status.role === Role.caseWorker &&
                                <Row className='infoRow'>
                                    <Col xs={6}>
                                        <span className='infoKey'>IP CARD VERIFIED</span>
                                        <span className='dilimetry'>:</span>
                                    </Col>
                                    <Col xs={6} className='infoValue' title={status?.claimStatus?.validationCheck}>
                                        {status?.claimStatus?.validationCheck ? 'YES' : 'NO'}
                                    </Col>
                                </Row>
                            }
                            {status.role !== Role.ipUser && status.role !== Role.caseWorker &&
                                <Row className='infoRow'>
                                    <Col xs={6}>
                                        <span className='infoKey'>Eligible Amount</span>
                                        <span className='dilimetry'>:</span>
                                    </Col>
                                    <Col xs={6} className='infoValue' title={status?.claimStatus?.approvedAmount}>
                                        {status?.claimStatus?.approvedAmount}
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </div >
                <div className={["tail-card", (status?.claimStatus?.enable ?
                    status?.claimStatus?.state === 'Objection' ? 'objected' : status?.claimStatus?.state === 'Rejected' ? 'rejected' : 'submitted' : 'submit')].join(' ')}>{status?.claimStatus?.state}</div>
            </div >
        </>
    )
}