import { React, useEffect, useState } from "react";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../SCSS/Views/Login.scss";
import { Role } from "../../_helpers";
import { authenticationService } from "../../_services";
import { Formik } from "formik";
import * as yup from "yup";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const ResetPassword = () => {
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUnAuthError, setShowUnAuthError] = useState(false);
  const history = useHistory();

  const schema = yup.object().shape({
    ipPassword: yup
    .string().required("Please Enter Password")
    .max(20, "Password length must be upto 20 characters")
    .min(8, "Password length must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
    ipConfirmPassword: yup
    .string()
    .required("Please Re-enter the password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
     .oneOf([yup.ref('ipPassword'), null], 'Passwords must match')
  });

  useEffect(() => {
    // checkAuthAndContinue();
  }, []);

  const handleFormSubmit = async (e) => {
    setLoaderStatus(true);
    const ipNumber = localStorage.getItem("ipNumber");

    await authenticationService.resetIpPassword({ ipNumber: ipNumber, password: e.ipPassword}).then(
      (response) => {
        console.log(response);
        if (response == true) {
          setLoaderStatus(false);
          localStorage.clear();
          history.push("/welcome");
        }
        else if(response.error != null || response.error != ""){
          setShowErrorMessage(response.error);
          setLoaderStatus(false);
          setShowError(true);
        }
        else if(response.message != null || response.message != ""){
          setShowErrorMessage(response.message);
          setLoaderStatus(false);
          setShowError(true);
        }
        else {
          setLoaderStatus(false);
        }
      },
      (error) => {
        console.error(error);
        setLoaderStatus(false);
        setShowUnAuthError(true);
      }
    );
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <div className="container-employeelogin-card">
        {showError && (
          <TextError>
            <b><p key="error" variant="danger">
              {showErrorMessage}
            </p></b>
          </TextError>
        )}
        {showUnAuthError && (
          <TextError>
            <b><p key="error" variant="danger">
              Unauthorized login 
            </p></b>
          </TextError>
        )}
        <Stack className="login-heading">
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
Reset Password          </p>
        </Stack>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            ipPassword: "",
            ipConfirmPassword:""
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form className="esi-login-form" onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={1}>
                <div>
                 
                   <Form.Group className="mb-4" controlId="formBasicPhone">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      // type="text"
                      // onChange={(e) => setIpPhoneNumber(e.target.value)}
                      type="text"
                      name="ipPassword"
                      value={values.ipPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipPassword && !errors.ipPassword}
                      isInvalid={!!errors.ipPassword}
                      placeholder="Enter new Password"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={20}
                      // onKeyPress={valid_numbers}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPhone">
                    <Form.Label>Confirm new Password</Form.Label>
                    <Form.Control
                      // type="text"
                      // onChange={(e) => setIpPhoneNumber(e.target.value)}
                      type="password"
                      name="ipConfirmPassword"
                      value={values.ipConfirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.ipConfirmPassword && !errors.ipConfirmPassword}
                      isInvalid={!!errors.ipConfirmPassword}
                      placeholder="Confirm new Password"
                      onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                      maxLength={20}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ipConfirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                

                <div style={{ textAlignLast: "center" }}>
                  <Button
                    variant="primary"
                    className="esi-navy-blue-button"
                    type="submit"
                    // disabled={!isValid || isSubmitting}
                  >
                    Reset Password
                  </Button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPassword;
