export const HorizontalDivider = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 2,
      marginRight: -10,
      marginLeft: -10,
    }}
  />
);
