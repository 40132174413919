import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import { Form, Button, Row, Col, Stack } from "react-bootstrap";
import * as yup from "yup";

import "../../../SCSS/Views/SOBCard.scss";
import addButton from "../../../assests/svg/add.svg";
import deleteButton from "../../../assests/svg/trash.svg";

import React, { useState, useEffect, useRef } from "react";
import { PcClaimService } from "../../../_services/PcClaimService";

import { SuccessModal } from "../Modals/SuccessModal";
import TextError from "../../../CommonComponents/TextError";
import _ from 'lodash';
import { DeleteSOBConfirm } from "../Modals/DeleteSOBConfirm";
const numWords = require("num-words");

export const AddSOB = (props) => {
    const { data, children } = props;

    const [count, setCount] = useState(1);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [totalBillAmount, setBillAmount] = useState(0);
    const [totalApprovedAmount, setApprovedAmount] = useState(0);
    const [billError, setBillError] = useState(false);
    const [showAddButton, setShowAddButton] = useState(false);
    const [deleteSob, setdeleteSob] = useState({});
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

    const billsEndRef = useRef(null);
    const scrollToBottom = () => {
        const domNode = billsEndRef.current;
        if (domNode) {
            domNode.scrollTop = domNode.scrollHeight + 285;
        }
    };

    useEffect(() => {
        if (data && data.edit != undefined) {
            calculateInitialAmount(data.sobList);
            setCount(data.sobList.length);
        }
        if (JSON.parse(localStorage.currentUser).dispensaries.includes(data.claim?.dispenseryId)) {
            setShowAddButton(true);
        }
        if (data.claim.claimStatus === 'Approved' ||
            data.claim.claimStatus === 'Rejected' ||
            data.claim.claimStatus === 'Objection') {
            setShowAddButton(false);
        }
    }, [])

    const calculateInitialAmount = (list) => {
        if (list && list.length != 0) {
            let billAmount = _.sum(_.map(list, d => parseInt(d.billAmount)));
            setBillAmount(billAmount);

            let approvedAmount = _.sum(_.map(list, d => d.approvedAmount));
            setApprovedAmount(approvedAmount);
        }
    }


    const calculateBillAmount = async (sList) => {
        let billAmount = 0;
        if (sList && sList.length != 0) {
            billAmount = _.sum(_.map(sList, d => d.billAmount ? parseInt(d.billAmount) : 0))
        }
        if (data?.edit !== -1 && data.edit !== 100 && data.sobList) {
            let totalBill = _.sum(_.map(data?.sobList, 
                d => parseInt(d?.billAmount))) - data?.sobList[data.edit]?.billAmount + billAmount;
            if (totalBill <= data?.claim?.appliedClaimAmount) {
                setBillAmount(totalBill);
                setBillError(false);
            } else {
                setBillAmount(totalBill);
                setBillError(true);
            }
        } else {
            if (billAmount <= data?.claim?.appliedClaimAmount) {
                setBillAmount(billAmount);
                setBillError(false);
            } else {
                setBillAmount(billAmount);
                setBillError(true);
            }
        }
    }

    const calculateApprovedAmount = async (sList) => {
        let approvedAmount = 0;
        if (sList && sList.length != 0) {
            approvedAmount = _.sum(_.map(sList, d => d?.approvedAmount ? parseInt(d?.approvedAmount) : 0))
        }
        if (data?.edit !== -1 && data.edit !== 100 && data.sobList) {
            let totalApproved = _.sum(_.map(data?.sobList, d => parseInt(d?.approvedAmount))) - data?.sobList[data.edit]?.approvedAmount + approvedAmount;
            setApprovedAmount(totalApproved);
        } else
            setApprovedAmount(approvedAmount);
    }



    const billInitialize = (data && data.edit != undefined) ? (data.edit == 100) ? data.sobList : [data.sobList[data.edit]] :
        [{
            id: 1,
            billNumber: "",
            billDate: "",
            billAmount: "",
            approvedAmount: "",
            description: ""
        }];


    const schema = yup.object().shape({
        bills: yup.array()
            .of(
                yup.object().shape({
                    id: yup
                        .number()
                        .notRequired(),
                    billNumber: yup
                        .string()
                        .max(20, "Please Enter Valid Bill Number")
                        .required("Please Enter Bill Number")
                        .matches(/^[ A-Za-z0-9]*$/, "No Special Characters Are Allowed"),
                    billDate: yup
                        .date()
                        .required("Please Select Bill Date")
                        .nullable(),
                    billAmount: yup
                        .number()
                        .typeError('You Must Specify A Number')
                        .required("Please Provide Bill Amount"),
                    approvedAmount: yup
                        .number()
                        .typeError('You Must Specify A Number')
                        .max(yup.ref('billAmount'), "Eligible Amount Should Be Less Than Bill Amount")
                        .required("Please Enter Eligible Amount"),
                    description: yup
                        .string()
                        .max(50, "50 Chars Only")
                        .matches(/^[A-Za-z0-9 ,.-]+$/, "No special characters allowed")
                        .required("Please Enter Description"),
                })
            )
    });


    const incrementCount = e => {
        setCount(count + 1);
    };

    const decrementCount = e => {
        setCount(count - 1);
    }

    const prepPayload = (payload) => {
        let finalData = []
        payload.map((bill, index) => {
            let billData = {
                id: index + 1,
                billNumber: bill.billNumber,
                billDate: bill.billDate,
                billAmount: bill.billAmount,
                approvedAmount: bill.approvedAmount,
                description: bill.description
            }
            finalData.push(billData);
        })
        return finalData;
    }

    async function handleBillSubmit(e) {
        let billData = prepPayload(e.bills);
        if (billData) {
            let payload = {
                claimId: data.claim.claimUuid,
                bills: billData
            };
            if (data.edit != undefined) {
                if (data.edit == 100) {
                    payload = {
                        claimId: data.claim.claimUuid,
                        bills: billData
                    }
                } else {
                    let requestData = [...data.sobList];
                    requestData[data.edit] = e.bills[0];
                    payload = {
                        claimId: data.claim.claimUuid,
                        bills: requestData
                    }
                }

            }

            await PcClaimService.addSOBList(payload).then(
                (response) => {
                    setSuccessModalShow(true);
                    if (props.handleSubmit) {
                        props.handleSubmit();
                    } else {
                        if (props.handleCancel) {
                            props.handleCancel();
                        }
                        if (props.handleRefresh) {
                            props.handleRefresh();
                        }
                    }
                }
            );
        }
    };

    const getNumtoWords = (amount) => {
        try {
            return numWords(parseFloat(amount)) + " Rupees only";
        } catch (e) {
            return "Invalid Data";
        }
    };

    const handleAddBills = (arrayHelpers) => {
        arrayHelpers.insert(count + 1, { id: count + 1, billNumber: '', billDate: '', billAmount: '', approvedAmount: '', description: '' })
        incrementCount();
        scrollToBottom();
    }

    const handleDelete = (arrayHelpers, index, list) => {
        setDeleteConfirmModal(true);
        setdeleteSob({
            arrayHelpers: arrayHelpers,
            index: index,
            list: list
        });
    }

    const closeModal = () => {
        setDeleteConfirmModal(false);
    }

    const onDeleteYes = ({ arrayHelpers, index, list }) => {
        setDeleteConfirmModal(false);
        decrementCount();
        let data = arrayHelpers.remove(index);
        const result = list.filter((_, i) => i !== index);

        if (data) {
            calculateBillAmount(result);
            calculateApprovedAmount(result);
        }
    }

    const goBack = () => {
        props.handleCancel();
    }

    return (
        <>
            <Formik
                enableReinitialize
                validationSchema={schema}
                initialValues={{
                    bills: billInitialize
                }}
                onSubmit={handleBillSubmit}
            >
                {
                    ({
                        handleSubmit,
                        handleReset,
                        handleChange,
                        validateField,
                        setFieldTouched,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting,
                        form,

                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FieldArray name='bills' render={
                                (arrayHelpers) => {
                                    return (<div>
                                        <Row className="pc-sob-stack">
                                            <Col style={{ flex: '2', maxWidth: 'fit-content' }}>
                                                <div className="pc-sob-label">Total Eligible Amount: {totalApprovedAmount}<span className="currency-color">₹</span> </div>
                                                <div className="pc-sob-words">({getNumtoWords(totalApprovedAmount)})</div>
                                            </Col>
                                            <Col style={{ flex: '2', maxWidth: 'fit-content' }}>
                                                <div className="pc-sob-label">Total Bill Amount: {totalBillAmount}<span className="currency-color">₹</span></div>
                                                <div className="pc-sob-words">({getNumtoWords(totalBillAmount)})</div>
                                            </Col>
                                            <Col style={{ flex: '1' }}>
                                                <div className="pc-sob-label">Total Bills: {count} </div>
                                                <div></div>
                                            </Col>
                                            <Col className="ms-auto"></Col>
                                            {showAddButton && <Col className={(data && data.edit != undefined && data.edit != 100) ? "no-display" : "icon-content-button"}>
                                                <Button className="sob-icon-button float-right" onClick={() => { handleAddBills(arrayHelpers) }}><img src={addButton} style={{ width: '40px' }} />
                                                    <label className="button-text">ADD BILLS</label></Button>
                                            </Col>}
                                            <Col xs={12}>
                                                {billError ? <div style={{ color: "#dc3545", fontSize: "0.875em", }} className="error">
                                                    Sum total of Bill Amount should be less than Claim Amount</div> : null}
                                            </Col>
                                        </Row>
                                        <div className="card-container" ref={billsEndRef}>
                                            {values.bills.map((bill, index) => {
                                                return (
                                                    <>
                                                        <div key={index} >
                                                            <div className="sob-card-form">
                                                                <div id="box">
                                                                    {index == 0 ? null : <div><img src={deleteButton} key={index} className="sob-button-delete" onClick={() => { handleDelete(arrayHelpers, index, values.bills) }} /></div>}
                                                                    <Row className="mb-3 sob-row-margin">
                                                                        <Form.Group as={Col} controlId="id">
                                                                            <Form.Label className="sob-label">Serial Number</Form.Label>
                                                                            <Field type="text" className="form-control" name={`bills.${index}.id`}
                                                                                value={(data && data.edit != undefined && data.edit != 100) ? bill.id : index + 1} readOnly={true} isValid={!errors.id}></Field>
                                                                            {errors.id ? (
                                                                                <div>{errors.id}</div>
                                                                            ) : null}
                                                                            <ErrorMessage component={TextError} name={`bills.${index}.id`} />

                                                                        </Form.Group>
                                                                        <Form.Group as={Col} controlId="billNumber">
                                                                            <Form.Label className="sob-label">Bill Number</Form.Label>
                                                                            <Field type="text" className="form-control"
                                                                                name={`bills.${index}.billNumber`} isValid={!errors.billNumber} ></Field>
                                                                            {errors.billNumber && touched.billNumber ? (
                                                                                <div>{errors.billNumber}</div>
                                                                            ) : null}
                                                                            <ErrorMessage component={TextError} name={`bills.${index}.billNumber`} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} controlId="billDate">
                                                                            <Form.Label className="sob-label">Bill Date</Form.Label>
                                                                            <Field type="date" className="form-control"
                                                                                name={`bills.${index}.billDate`} min={data.claim.treatmentFrom} max={data.claim.treatmentTo}
                                                                                isValid={!errors.billDate} ></Field>
                                                                            {errors.billDate && touched.billDate ? (
                                                                                <div>{errors.billDate}</div>
                                                                            ) : null}
                                                                            <ErrorMessage component={TextError} name={`bills.${index}.billDate`} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} controlId="billAmount">
                                                                            <Form.Label className="sob-label">Bill Amount</Form.Label>
                                                                            <div className="input-group">
                                                                                <Field
                                                                                    className="right-align form-control currency-field"
                                                                                    inputMode="numeric"
                                                                                    name={`bills.${index}.billAmount`}
                                                                                    isValid={!errors.billAmount}
                                                                                    onBlur={() => { validateField(`bills.billAmount`); setFieldTouched(`bills.${index}.billAmount`); calculateBillAmount(values.bills) }}></Field>
                                                                                <span className="input-group-addon">₹</span>
                                                                            </div>
                                                                            {errors.bills && totalBillAmount && touched.billAmount ? (
                                                                                <div>{errors.billAmount}</div>
                                                                            ) : null}

                                                                            <ErrorMessage component={TextError} name={`bills.${index}.billAmount`} />
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row className="mb-3 sob-row-margin" md={4}>
                                                                        <Form.Group as={Col} controlId="approvedAmount">
                                                                            <Form.Label className="sob-label">Eligible Amount</Form.Label>
                                                                            <div className="input-group">
                                                                                <Field className="right-align form-control currency-field" inputMode="numeric"
                                                                                    name={`bills.${index}.approvedAmount`} isValid={!errors.approvedAmount}
                                                                                    onBlur={() => { validateField(`bills.approvedAmount`); setFieldTouched(`bills.${index}.approvedAmount`); calculateApprovedAmount(values.bills) }}></Field>
                                                                                <span className="input-group-addon">₹</span>
                                                                            </div>
                                                                            {errors.approvedAmount && touched.approvedAmount ? (
                                                                                <div>{errors.approvedAmount}</div>
                                                                            ) : null}
                                                                            <ErrorMessage component={TextError} name={`bills.${index}.approvedAmount`} />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md={6} controlId="description">
                                                                            <Form.Label className="sob-label">Description</Form.Label>
                                                                            <Field type="text" className="form-control" maxLength={50}
                                                                                name={`bills.${index}.description`} isValid={!errors.description} ></Field>
                                                                            <span style={{ fontSize: "10px" }}>Maximum 50 characters</span>
                                                                            {errors.description && touched.description ? (
                                                                                <div>{errors.description}</div>
                                                                            ) : null}
                                                                            <ErrorMessage component={TextError} name={`bills.${index}.description`} />
                                                                        </Form.Group>
                                                                    </Row>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        {showAddButton && <>{(data && data.edit != undefined) ?
                                            <div>
                                                <Row>
                                                    <Col xs={12} md lg={{ span: 2, offset: 8 }}>
                                                        <div className=" mg-auto">
                                                            <Button className="esi-white-cancel-button-lg"
                                                                onClick={() => goBack()}>CANCEL</Button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md lg={{ span: 2, offset: 0 }}>
                                                        <div className="">
                                                            <Button className="esi-navy-blue-button-lg"
                                                                type="submit"
                                                                disabled={!isValid || billError || isSubmitting}
                                                            >
                                                                SUBMIT
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <div>
                                                <Row>
                                                    <Col xs={12} md lg={{ span: 2, offset: 8 }}>
                                                        <div className="mg-auto">
                                                            <Button className="esi-white-cancel-button-lg"
                                                                onClick={() => { handleReset(); setBillAmount(0); setApprovedAmount(0); setBillError(false) }}>CANCEL</Button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md lg={{ span: 2, offset: 0 }}>
                                                        <div>
                                                            <Button className="esi-navy-blue-button-lg"
                                                                type="submit"
                                                                disabled={!isValid || billError || isSubmitting}
                                                            >
                                                                SUBMIT
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }</>}
                                    </div>)
                                }

                            } />
                        </Form>
                    )
                }
            </Formik>
            <SuccessModal
                show={successModalShow}
                onHide={() => setSuccessModalShow(false)}
                data={{
                    text: "SOB Saved Successfully"
                }}
            />
            <DeleteSOBConfirm
                show={deleteConfirmModal}
                onHide={() => { closeModal() }}
                onConfirm={() => { onDeleteYes(deleteSob) }}
                data={deleteSob} />
        </>
    )

}