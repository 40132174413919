import { Stack } from "react-bootstrap";
import { FaLock, FaUserCircle, FaGlobeAmericas } from "react-icons/fa";
import { formatDateAndTime } from "../../_helpers/date-format";

export const UserCommentCard = (props) => {
  return (
    <Stack className="case-comments-h-stack" direction="horizontal" gap={3}>
      <div>
        <FaUserCircle className="react-user-icon" />
      </div>
      <div>
        <Stack direction="vertical" gap={3}>
          <div>
            <span style={{ fontWeight: "bold" }}>USER</span>
            <br />
            <span style={{ fontSize: "11px" }}>
              {formatDateAndTime(props.data.createdTimestamp)}&nbsp;
            </span>
            {props.data.external ? (
              <FaGlobeAmericas className="react-globe-icon" />
            ) : (
              <FaLock className="react-lock-icon" />
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: props.data.comment }} />
        </Stack>
      </div>
    </Stack>
  );
};
