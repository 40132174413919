export const Role = {
  ipUser: "ROLE_IP_USER",
  caseWorker: "ROLE_CASE_WORKER",
  client: "CLIENT",
  pharmacist: "ROLE_PHARMACIST",
  ddo: "ROLE_DDO",
  d_caseWorker: "ROLE_D_CASE_WORKER",
  os: "ROLE_OS",
  ao: "ROLE_AO",
  ss: "ROLE_SS",
  dda: "ROLE_DDA",
  cso: "ROLE_CSO",
  d_pharmacist: "ROLE_D_PHARMACIST"
};