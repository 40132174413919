export const attachmentList = [
  {
    attachment_Id: 'DC01',
    attchment_En: "Insured Person's Medical Claim",
    attachment_Kn: 'ವಿಮಾದಾರರ ವೈದ್ಯಕೀಯ ಹಕ್ಕು',
  },
  {
    attachment_Id: 'DC02',
    attchment_En: 'e-Pehchan Card',
    attachment_Kn: 'ಇ-ಗುರುತಿನ ಚೀಟಿ',
  },
  {
    attachment_Id: 'DC03',
    attchment_En:
      "Insured Person's Address Proof (Aadhaar Card/Ration Card copy/Voter ID)",
    attachment_Kn:
      'ವಿಮಾದಾರರ ವಿಳಾಸ ಪುರಾವೆ (ಆಧಾರ್ ಕಾರ್ಡ್/ಪಡಿತರ ಕಾರ್ಡ್ ನಕಲು/ವೋಟರ್ ಐಡಿ)',
  },
  {
    attachment_Id: 'DC04',
    attchment_En:
      "Employment Letter with Insured Person's photo (signed employeer)",
    attachment_Kn: 'ವಿಮಾದಾರರ ಫೋಟೋದೊಂದಿಗೆ ಉದ್ಯೋಗ ಪತ್ರ (ಸಹಿ ಮಾಡಿದ ಉದ್ಯೋಗಿ)',
  },
  {
    attachment_Id: 'DC05',
    attchment_En: "Hospital Doctor's Prescription Letter",
    attachment_Kn: 'ಆಸ್ಪತ್ರೆ ವೈದ್ಯರ ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಪತ್ರ',
  },
  {
    attachment_Id: 'DC06',
    attchment_En: 'Original Copy of Admission and Discharge summary',
    attachment_Kn: 'ಪ್ರವೇಶ ಮತ್ತು ಡಿಸ್ಚಾರ್ಜ್ ಸಾರಾಂಶದ ಮೂಲ ಪ್ರತಿ',
  },
  {
    attachment_Id: 'DC07',
    attchment_En:
      "Death Certificate, if insured Person of Insured Person's dependent is dead",
    attachment_Kn:
      'ಮರಣ ಪ್ರಮಾಣಪತ್ರ, ವಿಮಾದಾರರ ಅವಲಂಬಿತ ವ್ಯಕ್ತಿಯ ವಿಮಾದಾರರು ಸತ್ತಿದ್ದರೆ',
  },
  {
    attachment_Id: 'DC08',
    attchment_En: 'Accident Report, if happened in an Industry',
    attachment_Kn: 'ಅಪಘಾತ ವರದಿ, ಉದ್ಯಮದಲ್ಲಿ ಸಂಭವಿಸಿದಲ್ಲಿ',
  },
  {
    attachment_Id: 'DC09',
    attchment_En: 'Accident Report, if happened on road(FIR Copy)',
    attachment_Kn: 'ಅಪಘಾತ ವರದಿ, ರಸ್ತೆಯಲ್ಲಿ ಸಂಭವಿಸಿದಲ್ಲಿ (ಎಫ್‌ಐಆರ್ ಪ್ರತಿ)',
  },
  {
    attachment_Id: 'DC10',
    attchment_En:
      "Original cash bills for medicines purchased as per the prescription. Photo copies and original copies of the bills with amount>1000 require Doctor's Signature and Hospital Seal",
    attachment_Kn:
      'ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಪ್ರಕಾರ ಖರೀದಿಸಿದ ಔಷಧಿಗಳಿಗೆ ಮೂಲ ನಗದು ಬಿಲ್‌ಗಳು. 1000 ಮೊತ್ತದ ಬಿಲ್‌ಗಳ ಫೋಟೋ ಪ್ರತಿಗಳು ಮತ್ತು ಮೂಲ ಪ್ರತಿಗಳಿಗೆ ವೈದ್ಯರ ಸಹಿ ಮತ್ತು ಆಸ್ಪತ್ರೆ ಮುದ್ರೆಯ ಅಗತ್ಯವಿದೆ',
  },
  {
    attachment_Id: 'DC11',
    attchment_En: 'Current Salaray Certificate of Insured Person',
    attachment_Kn: 'ವಿಮಾದಾರ ವ್ಯಕ್ತಿಯ ಪ್ರಸ್ತುತ ಸಂಬಳದ ಪ್ರಮಾಣಪತ್ರ',
  },
  {
    attachment_Id: 'DC12',
    attchment_En: 'Proof for First Noted Desease Date',
    attachment_Kn: 'ಮೊದಲ ಗುರುತಿಸಲಾದ ರೋಗದ ದಿನಾಂಕದ ಪುರಾವೆ',
  },
  {
    attachment_Id: 'DC13',
    attchment_En: 'Photo copy of Bank pass book or Cheque',
    attachment_Kn: 'ಬ್ಯಾಂಕ್ ಪಾಸ್ ಬುಕ್ ಅಥವಾ ಚೆಕ್ ನ ಫೋಟೋ ಪ್ರತಿ',
  },
  {
    attachment_Id: 'DC14',
    attchment_En: 'Identified Desease',
    attachment_Kn: 'ಗುರುತಿಸಲಾದ ರೋಗ',
  },
];

// export const attachmentList = [
//   {
//     id: 1,
//     declarationType: "CHECK_BOX",
//     mandatory: true,
//     declartionTextEn: "Insured Person's Medical Claim",
//     status: true,
//     fieldType: "CHECK_BOX",
//     declartionTextKn: "ವಿಮಾದಾರರ ವೈದ್ಯಕೀಯ ಹಕ್ಕು",
//     effectiveDate: "2021-12-18T22:23:53.667",
//     endDate: "2021-12-18T22:23:53.667",
//     declarationOrder: 1,
//     declarationCode: "DC01",
//   },
//   {
//     id: 2,
//     declarationType: "CHECK_BOX",
//     mandatory: true,
//     declartionTextEn: "e-Pehchan Card",
//     status: true,
//     fieldType: "CHECK_BOX",
//     declartionTextKn: "ಇ-ಗುರುತಿನ ಚೀಟಿ",
//     effectiveDate: "2021-12-18T22:23:53.667",
//     endDate: "2021-12-18T22:23:53.667",
//     declarationOrder: 2,
//     declarationCode: "DC02",
//   },
// ];
export const attachmentListIpInfo = [
  {
    attachment_Id: '1',
    attchment_En: 'Insured Person Name',
    attachment_Kn: 'ವಿಮೆ ಮಾಡಿದ ವ್ಯಕ್ತಿಯ ಹೆಸರು',
  },

  {
    attachment_Id: '2',
    attchment_En: 'Insured Person Number',
    attachment_Kn: 'ವಿಮಾದಾರರ ಇ.ಎಸ್.ಐ ಸಂಖ್ಯೆ',
  },
  {
    attachment_Id: '3',
    attchment_En: 'Aadhaar Number',
    attachment_Kn: 'ಆಧಾರ್ ಸಂಖ್ಯೆ',
  },
  {
    attachment_Id: '4',
    attchment_En: 'Insured Person Contact Number',
    attachment_Kn: 'ವಿಮೆ ಮಾಡಿದ ವ್ಯಕ್ತಿಯ ಫೋನ್ ಸಂಖ್ಯೆ',
  },
  {
    attachment_Id: '5',
    attchment_En: 'Address',
    attachment_Kn: 'ವಿಳಾಸ',
  },
];

export const attachmentListUserInfo = [
  {
    attachment_Id: '1',
    attchment_En: 'Name',
    attachment_Kn: 'ಹೆಸರು',
  },

  {
    attachment_Id: '2',
    attchment_En: 'Insured Person Number',
    attachment_Kn: 'ವಿಮಾದಾರರ ಇ.ಎಸ್.ಐ ಸಂಖ್ಯೆ',
  },
  {
    attachment_Id: '3',
    attchment_En: 'Role',
    attachment_Kn: 'ಪಾತ್ರ',
  },
  {
    attachment_Id: '4',
    attchment_En: 'Contact Number',
    attachment_Kn: 'ಫೋನ್ ಸಂಖ್ಯೆ',
  },
  {
    attachment_Id: '5',
    attchment_En: 'Address',
    attachment_Kn: 'ವಿಳಾಸ',
  },
  {
    attachment_Id: '6',
    attchment_En: 'Dispensary Details',
    attachment_Kn: 'ಔಷಧಾಲಯದ ವಿವರಗಳು',
  },
];
export const attachmentListPatientInfo = [
  {
    attachment_Id: '1',
    attchment_En: 'Patient Name',
    attachment_Kn: 'ರೋಗಿಯ ಹೆಸರು',
  },
  {
    attachment_Id: '2',
    attchment_En: 'Patient Age',
    attachment_Kn: 'ರೋಗಿಯ ವಯಸ್ಸು',
  },
  {
    attachment_Id: '3',
    attchment_En: 'Relationship',
    attachment_Kn: 'ಸಂಬಂಧ',
  },
  {
    attachment_Id: '4',
    attchment_En: 'Date of Birth',
    attachment_Kn: 'ಹುಟ್ಟಿದ ದಿನಾಂಕ',
  },
  {
    attachment_Id: '5',
    attchment_En: 'Select Patient Name',
    attachment_Kn: 'ರೋಗಿಯ ಹೆಸರನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  },
];
export const attachmentListTreatmentInfo = [
  {
    attachment_Id: '1',
    attchment_En: 'Dispensary',
    attachment_Kn: 'ಡಿಸ್ಪೆನ್ಸರಿ',
  },
  {
    attachment_Id: '2',
    attchment_En: 'Claim Amount',
    attachment_Kn: 'ಕ್ಲೈಮ್ ಮೊತ್ತ',
  },
  {
    attachment_Id: '3',
    attchment_En: 'Treatment From',
    attachment_Kn: 'ನಿಂದ ಚಿಕಿತ್ಸೆ',
  },
  {
    attachment_Id: '4',
    attchment_En: 'Treatment To',
    attachment_Kn: 'ಗೆ ಚಿಕಿತ್ಸೆ',
  },
  {
    attachment_Id: '5',
    attchment_En: ' Is the Treatment Referred by ESI hospital',
    attachment_Kn: 'ಇಎಸ್‌ಐ ಆಸ್ಪತ್ರೆಯಿಂದ ಚಿಕಿತ್ಸೆಯನ್ನು ಸೂಚಿಸಲಾಗುತ್ತದೆ',
  },
  {
    attachment_Id: '6',
    attchment_En: 'Claim Amount in Words',
    attachment_Kn: 'ಪದಗಳಲ್ಲಿ ಕ್ಲೈಮ್ ಮೊತ್ತ',
  },
  {
    attachment_Id: '7',
    attchment_En: 'If Others, Please specify.',
    attachment_Kn: 'ಇತರರು ಇದ್ದರೆ, ದಯವಿಟ್ಟು ನಿರ್ದಿಷ್ಟಪಡಿಸಿ.',
  },
  {
    attachment_Id: '8',
    attchment_En: 'Count of Prescription Attached',
    attachment_Kn: 'ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್‌ನ ಎಣಿಕೆ ಲಗತ್ತಿಸಲಾಗಿದೆ',
  },
  {
    attachment_Id: '9',
    attchment_En: 'Count of bills Attached',
    attachment_Kn: 'ಲಗತ್ತಿಸಲಾದ ಬಿಲ್‌ಗಳ ಎಣಿಕೆ',
  },
  {
    attachment_Id: '10',
    attchment_En:
      'I hearby confirm that requested amount has not been claimed from any other organisation. In case it is proven,I shall be responsible for the consequences.',
    attachment_Kn:
      'ಆ ನಿಗದಿತ ಮೊತ್ತ ಇಲ್ಲ ಎಂದು ನಾನು ಈ ಮೂಲಕ ದೃಢೀಕರಿಸುತ್ತೇನೆ ಬೇರೆ ಯಾವುದೇ ಸಂಸ್ಥೆಯಿಂದ ಪಾವತಿಸಲಾಗಿದೆ. ಒಂದು ವೇಳೆ ಅದು ಸಾಬೀತಾದರೆ, ಪರಿಣಾಮಗಳಿಗೆ ನಾನು ಜವಾಬ್ದಾರನಾಗಿರುತ್ತೇನೆ.',
  },
];
