import DOMPurify from 'dompurify';
import { convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Field, Formik } from "formik";
import { RiEditBoxLine } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { BiCalendar } from "react-icons/bi";
import EsiDatePicker from "../../CommonComponents/EsiDatePicker";
import {
  attachmentListPatientInfo,
  attachmentListTreatmentInfo,
} from "../../Views/User/Data/Attachment_List";
import { DependantService } from "../../_services/DependantService";

import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
  Stack,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import { MdKeyboardBackspace } from "react-icons/md";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminCommentCard } from "../../CommonComponents/CommentComponent/AdminCommentCard";
import { UserCommentCard } from "../../CommonComponents/CommentComponent/UserCommentCard";
import { CommonAccordian } from "../../CommonComponents/CommonAccordian";
import "../../SCSS/Views/IpClaimView.scss";
import Stepper from "../../stepper/Stepper";
import { IpClaimService } from "../../_services/IpClaimService";
import { formatDateAndTime } from "../../_helpers/date-format";
import { HorizontalDivider } from "../../CommonComponents/HorizontalDivider";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import { CommentAccordian } from "../../CommonComponents/CommentAccordian";
import { IconDescription } from "../../CommonComponents/IconDescription";
import Moment from "react-moment";

const numWords = require("num-words");

export const IpClaimView = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const history = useHistory();
  const [birthDate, setBirthDate] = useState(null);
  const [claimAmount, setClaimAmount] = useState(0);
  const [dispenseryList, setDispenseryList] = useState([]);
  const { caseNumber } = useParams();
  const [ipClaim, setIpClaim] = useState([]);
  const [comments, setComments] = useState([]);
  const [dispensaryId, setDispensaryId] = useState(null);
  const [isKannada, setIsKannada] = useState(false);
  const [dependantData, setDependantData] = useState([]);
  const [declarationArray, setDeclarationArray] = useState([]);
  const [age, setAge] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);
  const [isExternal, setIsExternal] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isReffered, setIsReffered] = useState(false);
  const [dependantRow, setDependantRow] = useState(null);
  const [dependentId, setDependentId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);
  const [isObjected, setIsObjected] = useState(true);


  useEffect(() => {
    getDropDownData();
  }, []);
  useEffect(() => {
    getCaseDetails();
    getIpClaimDeclaration();
  }, [isBusy])

  const getDropDownData = async () => {
    setLoaderStatus(true);
    const result = await Promise.all([
      getDispensaryData(),
      fetchDependants(),
      getComments(),
      getAttachmentMasterData()
    ]);
    if (result) {
      setIsBusy(false);
    }
    setLoaderStatus(false);
  }
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);

  const editorJSON = JSON.stringify(
    convertToRaw(editorState.getCurrentContent())
  );

  const valid_numbers = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }
  };

  const handleSearchChange = (e) => {
    console.log(e);
    if (e) {
      setDispensaryId(e.value);
    }
  };

  const getAttachmentMasterData = async () => {
    setDeclarationArray(await IpClaimService.getAttachmentMasterData());
  };
  const getIpClaimDeclaration = async () => {
    const result = await IpClaimService.getIpClaimDeclarationByNumber(caseNumber);
    const merged = [...declarationArray.concat(result).reduce((m, o) =>
      m.set(o.declarationCode, Object.assign(m.get(o.declarationCode) || {}, o))
      , new Map()).values()];
    setDeclarationArray(merged);
  };

  const redirectToUserHome = () => {
    setCancelModel(true)
  };

  const claimInfoList = [
    {
      fieldName: "CASE NUMBER",
      fieldValue: `${ipClaim.claimRefNum ? ipClaim.claimRefNum : "-"}`,
      fieldIcon: "numberIcon-svg"
    },
    {
      fieldName: "IP NAME",
      fieldValue: `${ipClaim.ipName ? ipClaim.ipName : "-"}`,
      fieldIcon: "userIcon-svg"
    },
    {
      fieldName: "PATIENT NAME",
      fieldValue: `${ipClaim.patientName ? ipClaim.patientName : "-"}`,
      fieldIcon: "userIcon-svg"
    },
    {
      fieldName: "RELATIONSHIP",
      fieldValue: `${ipClaim.relationshipType ? ipClaim.relationshipType : "-"}`,
      fieldIcon: "groupIcon-svg"
    },
    {
      fieldName: "DISPENSARY",
      fieldValue: `${ipClaim.dispenseryName ? ipClaim.dispenseryName : "-"}`,
      fieldIcon: "hospital-svg"
    },    
    {
      fieldName: "CLAIM DATE",
      fieldValue: ipClaim.createdTimestamp ? <Moment format={"DD-MM-YYYY hh:mm A"}>
        {ipClaim.createdTimestamp}
      </Moment> : '-',
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "CLAIM AMOUNT",
      fieldValue: `${ipClaim.appliedClaimAmount ? Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
      }).format(ipClaim.appliedClaimAmount) : "-"
        }`,
      fieldIcon: "rupee-svg"
    },
    {
      fieldName: "ELIGIBLE AMOUNT",
      fieldValue: `${ipClaim.approvedClaimAmount ? Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
      }).format(ipClaim.approvedClaimAmount) : "-"}`,
      fieldIcon: "rupee-svg"
    },
    {
      fieldName: "STATUS",
      fieldValue: `${ipClaim.claimStatus ? ipClaim.claimStatus : "-"}`,
      fieldIcon: "infoIcon-svg"
    },
    {
      fieldName: "SAKALA NUMBER",
      fieldValue: `${ipClaim.sakalaNumber ? ipClaim.sakalaNumber : "-"}`,
      fieldIcon: "sakala-svg"
    },
    {
      fieldName: "SAKALA CREATED DATE",
      fieldValue: `${ipClaim.gscDate ? ipClaim.gscDate : "-"}`,
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "SAKALA DUE DATE",
      fieldValue: `${ipClaim.dueDate ? ipClaim.dueDate : "-"}`,
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "SAKALA UPDATED DATE",
      fieldValue: `${ipClaim.updatedDate ? ipClaim.updatedDate : "-"}`,
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "SAKALA OVERDUE DAYS",
      fieldValue: `${ipClaim.overdueDays ? ipClaim.overdueDays : "-"}`,
      fieldIcon: "sakala-svg"
    },
  ];

  const checkDecimalPlaces = async (e) => {
    let decimalValueArray = ["0", "5", "00", "50"];
    let decimalValue = claimAmount.toString().split(".")[1];
    if (decimalValue) {
      if (decimalValue.length >= 2 || e.key === "e") {
        if (
          !(e.key === "Delete" || e.key === "Backspace" || e.key === "Enter")
        ) {
          e.preventDefault();
        }
      }
    } else if (e.key === "e") {
      console.log("checking e");
      e.preventDefault();
    }
  };

  const getNumtoWords = () => {
    try {
      let amount;
      if (claimAmount.includes(".")) {
        amount = claimAmount.toString().split(".")[0];
      } else {
        amount = claimAmount;
      }
      return numWords(parseFloat(amount)) + " rupees only/-";
    } catch (e) {
      return "Invalid Claim Amount.";
    }
  };

  const getDispensaryData = async () => {
    const result = await IpClaimService.getDispensaries();
    setDispenseryList(result);
    return result;
  };

  const getComments = async () => {
    const commentsData = await IpClaimService.getClaimComments(caseNumber);
    setComments(commentsData);
  };

  const addComments = async () => {
    console.log(convertedContent, isExternal);
    if (!convertedContent || convertedContent === "<p><br></p>") {
      setShowError(true);
      setErrorMessage("Add text to submit comments!!");
    } else {
      setShowError(false);
      setLoaderStatus(true);
      const safeContent = DOMPurify.sanitize(convertedContent);
      await IpClaimService.createComments({
        comment: safeContent,
        claimId: ipClaim.claimUuid,
        external: isExternal,
      });
      setLoaderStatus(false);
      setErrorMessage("");
      ChangeTextAreaCount(0);
      getComments();
      setEditorState(EditorState.createEmpty());
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = stateToHTML(editorState.getCurrentContent());
    // Sanitize the HTML content to remove any unwanted scripts or tags
    let sanitizedContent = DOMPurify.sanitize(currentContentAsHTML);
    setConvertedContent(sanitizedContent);
  };
  const recalculate = e => {
    ChangeTextAreaCount(e.blocks[0].text.length);
  };

  const checkOptionChange = (e, data) => {
    let oneDependant;
    if (e) {
      oneDependant = dependantData.find((element) => {
        return element.id == e.target.value;
      });
    } else {
      oneDependant = dependantData.find((element) => {
        return element.id == data;
      });
    }

    if (oneDependant) {
      setDependantRow(oneDependant);
      setAge(oneDependant.age);
      setRelationship(oneDependant.relationshipType);
      setBirthDate(oneDependant.dob);
      setDependentId(oneDependant.id);
    } else {
      console.log("not found");
      setDependantRow({});
      setAge("");
      setRelationship("");
      setBirthDate("");
      setDependentId("");
    }
  };

  const modalClosed = (oneDependant) => {
    setModalShow(false);
    console.log(oneDependant);
    fetchDependants();
    setDependantRow(oneDependant);
    setAge(oneDependant.age);
    setRelationship(oneDependant.relationshipType);
    setBirthDate(oneDependant.dob);
    setDependentId(oneDependant.id);
  };

  const fetchDependants = async () => {
    const dependantData = await DependantService.getIpDependants();
    setDependantData(dependantData);
    return dependantData;
  };

  const currentStep = 2;

  async function getCaseDetails() {
    const ipCaseDetails = await IpClaimService.getIpClaimByNumber(caseNumber);
    if (ipCaseDetails.errorCode) {
      setErrorMessage(ipCaseDetails.message);
    } else {
      dispenseryList.forEach(item => {
        if (item.dispenseryId === ipCaseDetails.dispenseryId) {
          ipCaseDetails.dispensaryValue = {
            label: item.dispensaryName,
            value: item.dispenseryId
          };
        }
        checkOptionChange(false, ipCaseDetails.patientId);
      })
      setIpClaim(ipCaseDetails);
      setIsReffered(ipCaseDetails.esiHospitalReferral)
      if (ipCaseDetails.claimStatus === 'Objection') {
        setIsObjected(false);
      } else {
        setIsObjected(true);
      }
    }
  }

  const schema = yup.object().shape({
    treatmentDescription: yup.string()
    .matches(/^[\w\s]+$/, "No special characters allowed")
    .required("Required"),
  
    grievanceMeetingDescription: yup.string()
      .matches(/^[\w\s]+$/, "No special characters allowed")
      .required("Required"),

    external: yup.boolean()
      .required("Required"),

    caseNumber: yup.string()
      .matches(/^\d+$/, "Case Number must be a number and can contain digits only"),

    sakalaNumber: yup.string()
      .matches(/^\d+$/, "Sakala Number must be a number and can contain digits only"),

    ipNumber: yup.string()
      .matches(/^\d+$/, "IP Number must be a number and can contain digits only"),

    patientName: yup.string()
      .matches(/^[\p{L} '-]+$/u, "Patient Name can only contain letters, spaces, dashes, and apostrophes"),

    claimAmount: yup
      .number()
      .min(0, "Min value 0.")
      .max(99999999.99, "You Have Reached Max Calim Amount")
      .required("Add Claim amount"),
    dispensery: yup.object().shape({
      value: yup.string().required("Please Select One").nullable(),
    }),
    dependantName: yup.string().required("Select One"),
    DC01: yup.string().required("Select One"),
    DC02: yup.string().required("Select One"),
    DC03: yup.string().required("Select One"),
    DC04: yup.string().required("Select One"),
    DC05: yup.string().required("Select One"),
    DC06: yup.string().required("Select One"),
    DC07: yup.string().required("Select One"),
    DC08: yup.string().required("Select One"),
    DC09: yup.string().required("Select One"),
    DC10: yup.string().required("Select One"),
    DC11: yup.string().required("Select One"),
    DC12: yup.string().required("Select One"),
    DC13: yup.string().required("Select One"),
    DC14: yup.string().required("Select One"),
    DC15: yup
      .string()
      .max(250, "Only 250 Chars Allowed")
      .matches(/^[A-Za-z0-9 ,.-]+$/, "No special characters allowed to disable HTML scripting"),
    //.required("Mandatory Field"),
    DC16: yup
      .number()
      .min(0, "Min value 0.")
      .max(99, "2 digits only")
      .required("Required Field"),
    DC17: yup
      .number()
      .min(0, "Min value 0.")
      .max(99, "2 digits only")
      .required("Required Field"),
    DC18: yup
      .bool()
      .oneOf([true], "Consent is mandatory "),
    treatMentFromDate: yup
      .date()
      .required("Treatment from is Required")
      .nullable(),
    treatMentToDate: yup
      .date()
      .required("Treatment to is Required")
      .nullable(),
  });

  const initializeForm = {
    dependantName: ipClaim.patientId ? Number(ipClaim.patientId) : 0,
    dispensery: ipClaim.dispensaryValue,
    relationship: ipClaim.relationshipType,
    age: ipClaim.dependentAge,
    isReffered: ipClaim.esiHospitalReferral,
    claimAmount: ipClaim.appliedClaimAmount,
    treatMentFromDate: ipClaim.treatmentFrom ? new Date(ipClaim.treatmentFrom) : new Date(),
    treatMentToDate: ipClaim.treatmentTo ? new Date(ipClaim.treatmentTo) : new Date(),
    DC01: declarationArray[0]?.declarationVal,
    DC02: declarationArray[1]?.declarationVal,
    DC03: declarationArray[2]?.declarationVal,
    DC04: declarationArray[3]?.declarationVal,
    DC05: declarationArray[4]?.declarationVal,
    DC06: declarationArray[5]?.declarationVal,
    DC07: declarationArray[6]?.declarationVal,
    DC08: declarationArray[7]?.declarationVal,
    DC09: declarationArray[8]?.declarationVal,
    DC10: declarationArray[9]?.declarationVal,
    DC11: declarationArray[10]?.declarationVal,
    DC12: declarationArray[11]?.declarationVal,
    DC13: declarationArray[12]?.declarationVal,
    DC14: declarationArray[13]?.declarationVal,
    DC15: declarationArray[14]?.declarationVal, // othersTextArea
    DC16: declarationArray[15]?.declarationVal, //presCount
    DC17: declarationArray[16]?.declarationVal, //billsCount
    DC18: declarationArray[17]?.declarationVal, //selfsigned
  };

  const handleFormSubmit = async (e) => {
    setLoaderStatus(true);
    const requestData = {
      dispenseryId: e.dispensery.value,
      appliedClaimAmount: e.claimAmount,
      patientId: e.dependantName,
      esiHospitalReferral: isReffered,
      treatmentFrom: e.treatMentFromDate,
      treatmentTo: e.treatMentToDate,
      declarationList: getDeclarationList(e, declarationArray),
    };
    const res = await IpClaimService.updateClaim(requestData, caseNumber).then(
      (res) => {
        if (res.errorCode) {
          setErrorMessage(res.message)
        } else {
          history.push("/user");
        }
        setLoaderStatus(false);
      },
      (error) => {
        console.error(error);
        setLoaderStatus(false);
        setShowError(true);
      }
    );
  };

  const handleEdit = () => {
    console.log(dependantRow);
    setDependantRow(dependantRow);
    setModalShow(true);

  };

  const handleToggle = () => {
    if (isKannada) {
      setIsKannada(false);
    } else {
      setIsKannada(true);
    }
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <div>
        <Row className="row-breadcrumb">
          <Stack direction="horizontal" gap={2}>
            <div>
              <MdKeyboardBackspace
                className="BackIcon"
                onClick={() => history.goBack()}
              ></MdKeyboardBackspace>
            </div>
            <div>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/user"><div className="homeIcon-svg"></div> </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>View Claim</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Stack>
        </Row>
        <Row>
          <Col className="col-sub-header">ESI Claims</Col>
        </Row>
      </div>
      <HorizontalDivider color="#5277ac" />
      <div>
        <Stack className="slider-stack" direction="vertical" gap={4}>
          {errorMessage && <TextError>{errorMessage}</TextError>}
          <div>
            <CommonAccordian
              data={{
                title: "Claim Status: " + ipClaim.claimRefNum,
                eventKey: "0",
              }}
            >
              <Row>
                {claimInfoList.map((oneClaimField) => (
                  <Col className="columnspace">
                    <IconDescription icon={oneClaimField.fieldIcon} name={oneClaimField.fieldName} description={oneClaimField.fieldValue} />
                  </Col>
                ))}
              </Row>
            </CommonAccordian>
          </div>
          <div>

            <Formik
              validationSchema={schema}
              onSubmit={handleFormSubmit}
              initialValues={initializeForm}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack direction="vertical" gap={4}>
                    <div>
                      <CommonAccordian
                        data={{
                          title: "Patient Information",
                          subtitle:
                            "Name, Age and Relationship Details of the Patient",
                          eventKey: "1",
                        }}
                      >
                        {/* {!birthDate ? null : (
                          <Row className="add-claim-edit-row">
                            <p className="edit-class"></p>
                            <RiEditBoxLine
                              className="blue-edit-icon"
                              onClick={handleEdit}
                              cursor="pointer"
                            />
                          </Row>
                        )} */}
                        <Row className="new-claim-row">
                          <Col xs={12} md lg={3}>
                            <Form.Group className="md-2" controlId="formBasicEmail">
                              {/* Patient Name */}
                              <Form.Label>
                                {attachmentListPatientInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "1"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="dependantName"
                                onChange={(e) => {
                                  checkOptionChange(e);
                                  handleChange("dependantName")(e);
                                }}
                                onBlur={handleBlur}
                                value={values.dependantName} // <--- it should allow an array of strings, currently the code won't compile or won't update the form value as it has multiple in the form control
                                isValid={
                                  touched.dependantName && !errors.dependantName
                                }
                                isInvalid={!!errors.dependantName}
                                disabled={isObjected}
                              >
                                <option
                                  value=""
                                  defaultValue
                                  className="select-dropdowns"
                                >
                                  Select Patient Name
                                </option>
                                {dependantData.map((dependant) => (
                                  <option
                                    className="select-dropdowns"
                                    value={dependant.id}
                                  >
                                    {dependant.dependentName},
                                    {dependant.relationshipType}
                                  </option>
                                ))}
                                <Dropdown.Divider />
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.dependantName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={3}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              {/* Patient Age */}
                              <Form.Label>
                                {attachmentListPatientInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "2"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control type="text" value={age} readOnly />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={3}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              {/* Relationship */}
                              <Form.Label>
                                {attachmentListPatientInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "3"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={relationship}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={3}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              {/* Date of Birth */}
                              <Form.Label>
                                {attachmentListPatientInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "4"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Group>
                                <Form.Control
                                  placeholder={() => <FaCalendarAlt />}
                                  type="text"
                                  value={birthDate}
                                  readOnly
                                />
                                <div className="calendar-icon1">
                                  <BiCalendar size={23} />
                                </div>
                              </Form.Group>
                            </Form.Group>
                          </Col>
                        </Row>
                      </CommonAccordian>
                    </div>
                    <div>
                      <CommonAccordian
                        data={{
                          title: "Treatment Information",
                          subtitle:
                            "Claim Information and Time Period at which the Treatment was Taken",
                          eventKey: "2",
                        }}
                      >
                        <Row className="new-claim-row">
                          <Col xs={12} md lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              {/* Dispensary */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "1"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              {isObjected ? <Form.Control
                                type="text"
                                name="dispensery"
                                value={values?.dispensery?.label}
                                readOnly={isObjected}
                              /> :
                                <>
                                  <Select
                                    aria-label="Floating label select example"
                                    isClearable={true}
                                    name="dispensery"
                                    value={values.dispensery}
                                    // value={values.dispensery}
                                    // onChange={handleSearchChange}
                                    onChange={(selectedOption) => {
                                      // This inline function can now completely be reaplce by handleChange("year")
                                      // handleChange("dispensery")(selectedOption);

                                      setFieldValue("dispensery", selectedOption);
                                    }}
                                    options={dispenseryList.map((opt) => ({
                                      label: opt.dispensaryName,
                                      value: opt.dispenseryId,
                                    }))}
                                    disabled={isObjected}
                                  />
                                  <TextError>
                                    {errors.dispensery
                                      ? "Please Select a Dispensary"
                                      : ""}
                                  </TextError></>}
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={4}>
                            <Form.Group className="mb-3 claim-col">
                              {/* Claim Amount */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "2"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="claimAmount"
                                value={values.claimAmount}
                                onChange={handleChange}
                                isValid={touched.claimAmount && !errors.claimAmount}
                                isInvalid={!!errors.claimAmount}
                                onKeyUp={(e) => setClaimAmount(e.target.value)}
                                onKeyDown={(e) => checkDecimalPlaces(e)}
                                readOnly={isObjected}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.claimAmount}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={4}>
                            {!claimAmount ? (
                              ""
                            ) : (
                              <Stack className="claim-stack">
                                <div
                                  style={{ color: "#172d66", fontWeight: "bold" }}
                                >
                                  Claim Amount In Words
                                </div>
                                <div>{getNumtoWords()}</div>
                              </Stack>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md lg={4}>
                            {/* Treatment From */}
                            <Form.Group
                              className="mb-3 treatment-col"
                              controlId="formBasicEmail"
                            >
                              <Form.Label >
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "3"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Group >
                                <EsiDatePicker
                                  maxDate={new Date()}
                                  name="treatMentFromDate"
                                  readOnly={isObjected}
                                />
                              </Form.Group>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              {/* Treatment To */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "4"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Group >
                                <EsiDatePicker
                                  maxDate={new Date()}
                                  minDate={new Date(values.treatMentFromDate)}
                                  name="treatMentToDate"
                                  readOnly={isObjected}
                                />
                              </Form.Group>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md lg={4}>
                            <Form.Group
                              className="mb-3 div"
                              controlId="formBasicEmail"
                            >
                              {/* Is the Treament Referred by ESI hospital */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "5"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <div>
                                <label class="switch" style={{ float: "left" }}>
                                  <input
                                    checked={values.isReffered}
                                    type="checkbox"
                                    onChange={(e) =>
                                      setIsReffered(e.target.checked)
                                    }
                                    id="togBtn"
                                    disabled={isObjected}
                                  />
                                  <div class="slider round">
                                    <span class="on">YES</span>
                                    <span class="off">NO</span>
                                  </div>
                                </label>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </CommonAccordian>
                    </div>

                    <div>
                      <CommonAccordian
                        data={{
                          title: "Declaration",
                          subtitle: "Details of the Attachment to be Submitted",
                          eventKey: "3",
                        }}
                      >
                        <Table responsive>
                          <thead className="table">
                            <tr>
                              <th>Document Details</th>
                              <th style={{ textAlign: "center" }}>N/A</th>
                              <th style={{ textAlign: "center" }}>
                                Original Document
                              </th>
                              <th style={{ textAlign: "center" }}>
                                Copy of Document
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {declarationArray.map((eachDeclaration) =>
                              eachDeclaration.fieldType === "CHECK_BOX" ? (
                                <tr>
                                  <td>
                                    {isKannada
                                      ? eachDeclaration.declartionTextKn
                                      : eachDeclaration.declartionTextEn}
                                  </td>
                                  <td
                                    style={{ textAlign: "center", width: "9rem" }}
                                  >
                                    <Field
                                      name={eachDeclaration.declarationCode}
                                      type="radio"
                                      className="form-check-input"
                                      id={eachDeclaration.declarationCode}
                                      onBlur={handleBlur}
                                      checked={
                                        values[eachDeclaration.declarationCode] ===
                                        "N/A"
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          eachDeclaration.declarationCode,
                                          "N/A"
                                        )
                                      }
                                      disabled={isObjected}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <Field
                                      name={eachDeclaration.declarationCode}
                                      type="radio"
                                      className="form-check-input"
                                      id={eachDeclaration.declarationCode}
                                      onBlur={handleBlur}
                                      checked={
                                        values[eachDeclaration.declarationCode] ===
                                        "true"
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          eachDeclaration.declarationCode,
                                          "true"
                                        )
                                      }
                                      disabled={isObjected}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <Field
                                      name={eachDeclaration.declarationCode}
                                      type="radio"
                                      className="form-check-input"
                                      id={eachDeclaration.declarationCode}
                                      onBlur={handleBlur}
                                      checked={
                                        values[eachDeclaration.declarationCode] ===
                                        "false"
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          eachDeclaration.declarationCode,
                                          "false"
                                        )
                                      }
                                      disabled={isObjected}
                                    />
                                  </td>
                                  <TextError>
                                    {errors[eachDeclaration.declarationCode]}
                                  </TextError>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </Table>
                        <HorizontalDivider />
                        <Row>
                          <Col xs={12} md lg={6}>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              {/* If Others, Please specify. */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "7"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                name="DC15"
                                value={values.DC15}
                                onChange={handleChange}
                                isValid={touched.DC15 && !errors.DC15}
                                isInvalid={!!errors.DC15}
                                rows={1}
                                readOnly={isObjected}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.DC15}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md lg={3}>
                            <Form.Group className="mb-3">
                              {/* Count of Prescription Attached */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "8"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="DC16"
                                value={values.DC16}
                                onChange={handleChange}
                                isValid={touched.DC16 && !errors.DC16}
                                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                maxLength={2}
                                onKeyPress={valid_numbers}
                                isInvalid={!!errors.DC16}
                                readOnly={isObjected}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.DC16}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md lg={3}>
                            <Form.Group className="mb-3">
                              {/* Count of bills Attached */}
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) => attachment.attachment_Id === "9"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="DC17"
                                value={values.DC17}
                                onChange={handleChange}
                                isValid={touched.DC17 && !errors.DC17}
                                isInvalid={!!errors.DC17}
                                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                maxLength={2}
                                onKeyPress={valid_numbers}
                                readOnly={isObjected}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.DC17}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Stack direction="horizontal" gap={3}>
                            <div>
                              <TextError>{errors.DC18}</TextError>

                              <label class="switch">
                                <input
                                  name="DC18"
                                  checked={values.DC18}
                                  onChange={handleChange}
                                  type="checkbox"
                                  id="togBtn"
                                  disabled={isObjected}
                                />
                                <div class="slider round">
                                  <span class="on">YES</span>
                                  <span class="off">NO</span>
                                </div>
                              </label>
                            </div>

                            <div style={{ fontWeight: "400", fontSize: "1rem" }}>
                              <Form.Label>
                                {attachmentListTreatmentInfo
                                  .filter(
                                    (attachment) =>
                                      attachment.attachment_Id === "10"
                                  )
                                  .map((eachAttachment) => (
                                    <>
                                      {isKannada
                                        ? eachAttachment.attachment_Kn
                                        : eachAttachment.attchment_En}
                                    </>
                                  ))}
                              </Form.Label>
                            </div>
                          </Stack>
                        </Row>
                      </CommonAccordian>
                    </div>
                    <div>
                      <CommentAccordian data={{ title: "Comments", eventKey: "1" }}>
                        <Row>
                          {comments.map((comment) =>
                            comment.ipUser === false ? (
                              <AdminCommentCard data={comment}></AdminCommentCard>
                            ) : (
                              <UserCommentCard data={comment}></UserCommentCard>
                            )
                          )}
                        </Row>
                        <Row className="editor-row">
                          {/*className="fixed-bottom" */}
                          <Col className="editor-col" xs={12} md={6} lg={6}>
                            {showError ? <TextError>{errorMessage}</TextError> : null}
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={handleEditorChange}
                              onChange={recalculate}
                              handleBeforeInput={val => {
                                const textLength = editorState.getCurrentContent().getPlainText().length;
                                if (val && textLength >= 250) {
                                  return 'handled';
                                }
                                return 'not-handled';
                              }}
                              handlePastedText={val => {
                                const textLength = editorState.getCurrentContent().getPlainText().length;
                                return ((val.length + textLength) >= 250);
                              }}
                            />
                            <p>{textAreaCount}/250</p>
                          </Col>
                          <Col className="comment-col" md={6}>
                            <Stack direction="horizontal" gap={2}>
                              <div>
                                <Button
                                  className="esi-navy-blue-button"
                                  variant="primary"
                                  size="lg"
                                  active
                                  onClick={addComments}
                                >
                                  Comment
                                </Button>
                              </div>
                            </Stack>
                          </Col>
                        </Row>
                      </CommentAccordian>
                    </div>
                    {/* Form submit Buttons*/}
                    <div>
                      {errorMessage && <TextError>{errorMessage}</TextError>}
                      {!isObjected && <Row>
                        <Col xs={12} md lg={{ span: 2, offset: 8 }}>
                          <div className=" mg-auto">
                            <Button
                              className="esi-white-cancel-button-lg"
                              onClick={redirectToUserHome}
                            >
                              CANCEL

                            </Button>

                          </div>
                        </Col>
                        <Col xs={12} md lg={{ span: 2, offset: 0 }}>
                          <div className="">
                            <Button
                              className="esi-navy-blue-button-lg"
                              disabled={!isValid || isSubmitting}
                              type="submit"
                            // onSubmit={handleSubmit}
                            >
                              SUBMIT
                            </Button>
                          </div>
                        </Col>
                      </Row>}
                    </div>
                  </Stack>
                </Form>
              )}
            </Formik>

          </div>
        </Stack>
      </div>
    </>
  );
};

function getDeclarationList(e, masterDeclarationArray) {
  let declarationList = [];
  masterDeclarationArray.map((oneDeclaration) => {
    let decId = oneDeclaration.declarationCode;
    switch (decId) {
      case "DC01":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC01));
        return true;
      case "DC02":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC02));
        return true;
      case "DC03":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC03));
        return true;
      case "DC04":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC04));
        return true;
      case "DC05":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC05));
        return true;
      case "DC06":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC06));
        return true;
      case "DC07":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC07));
        return true;
      case "DC08":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC08));
        return true;
      case "DC09":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC09));
        return true;
      case "DC10":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC10));
        return true;
      case "DC11":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC11));
        return true;
      case "DC12":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC12));
        return true;
      case "DC13":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC13));
        return true;
      case "DC14":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC14));
        return true;
      case "DC15":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC15));
        return true;
      case "DC16":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC16));
        return true;
      case "DC17":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC17));
        return true;
      case "DC18":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC18));
        return true;
      default:
        return true;
    }
  });
  console.log(declarationList);
  return declarationList;
}

function getDeclarationObject(key, value) {
  return {
    declarationMasterId: key,
    declarationVal: value,
  };
}
