import { authHeader, handleResponse } from "../_helpers";
import { config } from "../constants";

export const http = {
  postData,
  getData,
  putData,
  deleteData,
  fileData,
};
async function postData(url, data, headers) {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Target-URL": `${config.targetUrl}`,
      Authorization: auth.Authorization,
      ...headers,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getData(url, headers) {
  const auth = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Target-URL": `${config.targetUrl}`,
      Authorization: auth.Authorization,
      ...headers
    },
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    });
}
async function putData(url, data, headers) {
  const auth = authHeader();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Target-URL": `${config.targetUrl}`,
      Authorization: auth.Authorization,
      ...headers,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    });
}
async function deleteData(url) {
  const auth = authHeader();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Target-URL": `${config.targetUrl}`,
      Authorization: auth.Authorization,
    },
    // body: JSON.stringify(data),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function fileData(url, data, headers) {
  const auth = authHeader();
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: auth.Authorization,
      "Target-URL": `${config.targetUrl}`,
      ...headers
    },
    body: data,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    });
}
