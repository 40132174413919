import React, { useState, useEffect } from 'react';
import { Button, Form, ButtonToolbar, Col, FormControl, InputGroup, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CommonAccordian } from '../../../CommonComponents/CommonAccordian';
import "../../../SCSS/Views/OfficeNotesMemo.scss";
import { Document, Page } from 'react-pdf';
import { OfficeNotesAndMemo } from '../../../_services';
import { Formik } from 'formik';
import { DigitalSignModal } from '../Modals/DigitalSignModal';
import { EsiLoader } from '../../../CommonComponents/EsiLoader';
import { Role } from '../../../_helpers';
import _ from 'lodash';
import TextError from '../../../CommonComponents/TextError';
import { ApprovedToggleModal } from '../Modals/ApprovedToggleModal';

const urlPdf = "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";
export const OfficeNotesComponent = (props) => {

    const { caseNumber } = useParams();
    const [file, setFile] = useState();
    const [omFile, setOMFile] = useState();
    const [digitalModalShow, setDigitalModalShow] = useState(false);
    const [notesDetails, setNotesDetails] = useState({});
    const [signature, setSignature] = useState();
    const [isViewOnlyMode, setViewOnlyMode] = useState(true);
    const [isViewOnlyModeOM, setViewOnlyModeOM] = useState(true);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [showOM, setShowOM] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [errorOMMessage, setErrorOMMessage] = useState(null);
    const [errorONMessage, setErrorONMessage] = useState(null);
    const [approvedModalShow, setApprovedModalShow] = useState(false);
    const [description, setDescription] = useState('')

    useEffect(() => {
        setUserRole(JSON.parse(localStorage.currentUser).authorites[0]);
        
        if (props?.data?.claimDetails?.claimStatus === "Approved" ||
            props?.data?.claimDetails?.claimStatus === "Objection" ||
            props?.data?.claimDetails?.claimStatus === "Rejected") {
            setViewOnlyMode(false)
        }
    }, [userRole])
    useEffect(() => {
        if (userRole) {
            getOfficeNotesPDF();
            checkToDisplayOM();
        }
    }, [userRole])

    const checkToDisplayOM = () => {
        const statesGrouped = _.keyBy(props?.data?.states, "wrkFlowCode");
        const currentStatus = props?.data?.claimDetails?.claimStatus;
        let currentStateId;
        props?.data?.states?.forEach((ele) => {
            if (ele.displayName === currentStatus) {
                currentStateId = ele.id
            }
        });
        if (currentStateId >= statesGrouped["PH_VERIFIED"].id) {
            getOfficeMemoPDF();
        }       
        
        switch (userRole) {            
            case Role.d_caseWorker:
                if (currentStateId == statesGrouped["PH_VERIFIED"].id) {
                    setShowOM(true);
                }else{
                    setShowOM(true);
                    setViewOnlyModeOM(false);
                }
                break;
            case Role.os:
                if (currentStateId == statesGrouped["OM_PREPARED"].id) {
                    setShowOM(true);
                }else{
                    setShowOM(true);
                    setViewOnlyModeOM(false);
                }
                break;
            case Role.ao:
                if (currentStateId == statesGrouped["OS_APPROVED"].id||currentStateId == statesGrouped["OS_FORWARD_AO_OBJECTION"].id||currentStateId == statesGrouped["OS_FORWARD_AO_AO_OBJECTION"].id) {
                    setShowOM(true);
                }else{
                    setShowOM(true);
                    setViewOnlyModeOM(false);
                }
                break;            
            case Role.cso:
                if (currentStateId == statesGrouped["AO_APPROVED"].id||currentStateId == statesGrouped["AO_FORWARD_CSO_OBJECTION"].id||currentStateId == statesGrouped["AO_FORWARD_CSO_CSO_OBJECTION"].id) {
                    setShowOM(true);
                }else{
                    setShowOM(true);
                    setViewOnlyModeOM(false);
                }
                break;                
            default:
                setShowOM(false);
                break;
        }
        if (props?.data?.claimDetails?.claimStatus === "Approved") {
            setShowOM(true);
        }
    }

    const getOfficeMemoPDF = async () => {
        try {
            setLoaderStatus(true);
            const response = await Promise.all([
                OfficeNotesAndMemo.getOMReportService(caseNumber)
            ]);
            if (response[0].errorCode) {
                setErrorOMMessage(response[0].message);
            } else {
                setOMFile(encodeURI(response[0]));
            }
            setLoaderStatus(false);
        } catch (error) {
            setLoaderStatus(false);
            console.error(error);
        }
    }

    const getOfficeNotesPDF = async () => {
        try {            
            setLoaderStatus(true);
            const response = await Promise.all([
                OfficeNotesAndMemo.getONReportService(caseNumber),
                OfficeNotesAndMemo.getOfficeNotesDetails(caseNumber),
                getSignatureData()
            ]);
            if (response[0].errorCode) {
                setErrorONMessage(response[0].message);
            } else {
                setFile(encodeURI(response[0]));
            }
            if (response[1].errorCode) {
                setErrorONMessage(response[1].message);
                setNotesDetails({
                    claimType: '',
                    grievanceMeetingInfo: '',
                    treatmentInfo: '',
                    notes: '',
                    enable: isViewOnlyMode
                })
            } else {                
                setNotesDetails(response[1]);
            }
            if (response[2].errorCode) {
                setErrorONMessage(response[2].message);
            } else {                
                setSignature(response[2].sign);
            }
            const statesGrouped = _.keyBy(props?.data?.states, "wrkFlowCode");
            const currentStatus = props?.data?.claimDetails?.claimStatus;
            let currentStateId;
            props?.data?.states?.forEach((ele) => {
                if (ele.displayName === currentStatus) {
                    currentStateId = ele.id
                }
            });  
            debugger
            switch (userRole) {                        
                case Role.d_caseWorker:
                    if (currentStateId == statesGrouped["SOB_VERIFIED"].id||currentStateId == statesGrouped["DDO_FORWARD_DCW_DCW_OBJECTION"].id||currentStateId == statesGrouped["SS_REJECTED_DCW_OBJECTION"].id) {
                        setNotesDetails(response[1]);
                        setViewOnlyMode(true);
                    }else{
                        setNotesDetails(response[1]);
                        setViewOnlyMode(false);
                    }
                    break; 
                    case Role.os:
                        if (currentStateId == statesGrouped["ON_PREPARED"].id||currentStateId == statesGrouped["DDO_FORWARD_OS_OS_OBJECTION"].id) {
                            setNotesDetails(response[1]);
                            setViewOnlyMode(true);
                        }else{
                            setNotesDetails(response[1]);
                            setViewOnlyMode(false);
                        }
                    break; 
                    case Role.ss:
                        if (currentStateId == statesGrouped["OS_VERIFIED"].id||currentStateId == statesGrouped["OS_FORWARD_SS_OBJECTION"].id||currentStateId == statesGrouped["OS_FORWARD_SS_SS_OBJECTION"].id) {
                            setNotesDetails(response[1]);
                            setViewOnlyMode(true);
                        }else{
                            setNotesDetails(response[1]);
                            setViewOnlyMode(false);
                        }
                    break; 
                    case Role.d_pharmacist:
                        if (currentStateId == statesGrouped["sS_VERIFIED"].id||currentStateId == statesGrouped["SS_FORWARD_PH_OBJECTION"].id||currentStateId == statesGrouped["SS_FORWARD_PH_PH_OBJECTION"].id) {
                            setNotesDetails(response[1]);
                            setViewOnlyMode(true);
                        }else{
                            setNotesDetails(response[1]);
                            setViewOnlyMode(false);
                        }
                    break; 
                default:
                    setNotesDetails(response[1]);
                    setViewOnlyMode(false);
            }
            setLoaderStatus(false);
        } catch (error) {
            setLoaderStatus(false);
            setErrorONMessage(JSON.stringify(error));
            console.error(error);
        }
    }
    const s2ab = s => {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    const saveData = (data, fileName) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        let blob = new Blob([s2ab(atob(data))], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const getSignatureData = async () => {
        const value = await OfficeNotesAndMemo.getSignatureService();
        return value;
    }

    const initialForm = {
        claimType: '',
        grievanceMeetingInfo: '',
        treatmentInfo: '',
        notes: '',
        enable: isViewOnlyMode
    };

    const handleSubmit = async (e) => {
        setLoaderStatus(true);
        const result = await OfficeNotesAndMemo.saveOfficeNotesDetails(e, caseNumber);
        if (result.errorCode) {
            setErrorONMessage(result.message);
        } else {
            setDescription("Office Notes Added Successfully")
            setApprovedModalShow(true);
        }
        setLoaderStatus(false);
    }
    const handleCancel = async (values) => {
        values(initialForm);
    };
    const handleDigitalSignPopUp = () => {
        setDigitalModalShow(true)
    }
    const modalClosed = (res) => {
        setDigitalModalShow(false);
        if (res) {
            getSignatureData().then(
                data => {
                    if (data.errorCode) {
                        setErrorONMessage(data.message);
                    } else {
                        setErrorONMessage(null);
                        setSignature(data.sign);
                    }
                }
            )
        }
    };
    const initialMemoForm = {
        enable: isViewOnlyMode
    }
    const handleMemoCancel = async (values) => {
        values(initialForm);
    };
    const handleMemoSubmit = async (e) => {
        setLoaderStatus(true);
        const result = await OfficeNotesAndMemo.saveOfficeMemoDetails(e, caseNumber);
        if (result.errorCode) {
            setErrorONMessage(result.message);
        } else {
            setDescription("Office Memorandum Added Successfully")
            setApprovedModalShow(true);
        }
        setLoaderStatus(false);
    }
    return (
        <>
            {loaderStatus ? <EsiLoader /> : null}
            <Stack className="slider-stack office-info" direction="vertical" gap={3} >
                <div>
                    <CommonAccordian
                        data={{
                            title: "Office Notes",
                            eventKey: "0",
                        }}
                        clsBody={true}
                    >

                        {errorONMessage && <TextError>{errorONMessage}</TextError>}
                        <Row className='firstRow'>
                            <Col>
                                {file && <div className='pdfDisplay'>
                                    <object width="100%" height="1200px" data={`data:application/pdf;base64,${file}`} type="application/pdf">   </object>
                                </div>}
                            </Col>
                        </Row>
                        <Row className='secondRow'>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <div>
                                    <div className="me-5 previewCls" title='Preview'></div>
                                    <div className='ctnTxt'>Preview</div>
                                </div> */}
                                <div onClick={() => { saveData(file, "officeNotes.pdf") }}>
                                    <div className="downloadCls" title='Download'></div>
                                    <div className='ctnTxt'>Download</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='notes-card-form'>
                                    <div className='notes-card-data'>
                                        <Formik
                                            onSubmit={handleSubmit}
                                            initialValues={notesDetails ? notesDetails : initialForm}
                                            enableReinitialize={true}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                resetForm,
                                                values,
                                                isValid,
                                                touched,
                                                errors,
                                                isSubmitting
                                            }) => (
                                                <Form onSubmit={handleSubmit} className="add-notes-container">
                                                    {Role.d_caseWorker === userRole && <Row>
                                                        <Col xs={4}>
                                                            <Form.Group className="mb-3" controlId="treatmentInfo">
                                                                <Form.Label>Treatment Info</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="treatmentInfo"
                                                                    value={values.treatmentInfo}
                                                                    onChange={handleChange}
                                                                    isValid={touched.treatmentInfo && !errors.treatmentInfo}
                                                                    isInvalid={!!errors.treatmentInfo}
                                                                    maxLength={50}
                                                                    readOnly={!values.enable}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.treatmentInfo}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Form.Group className="mb-3" controlId="grievanceMeetingInfo">
                                                                <Form.Label>Grievance Meeting Info</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="grievanceMeetingInfo"
                                                                    value={values.grievanceMeetingInfo}
                                                                    onChange={handleChange}
                                                                    isValid={touched.grievanceMeetingInfo && !errors.grievanceMeetingInfo}
                                                                    isInvalid={!!errors.grievanceMeetingInfo}
                                                                    readOnly={!values.enable}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.grievanceMeetingInfo}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Form.Group className="mb-3" controlId="claimType">
                                                                <Form.Label>External</Form.Label>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="claimType"
                                                                        value={values.claimType}
                                                                        onChange={handleChange}
                                                                        isValid={touched.claimType && !errors.claimType}
                                                                        isInvalid={!!errors.claimType}
                                                                        readOnly={!values.enable}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.claimType}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col xs={12} className="mb-3">
                                                            <Form.Group controlId="notes">
                                                                <Form.Label>Notes</Form.Label>
                                                                <InputGroup className="mt-3"
                                                                >
                                                                    <FormControl
                                                                        type="text"
                                                                        name="notes"
                                                                        value={values.notes}
                                                                        onChange={handleChange}
                                                                        isValid={touched.notes && !errors.notes}
                                                                        isInvalid={!!errors.notes}
                                                                        readOnly={!values.enable}
                                                                        maxLength={150}
                                                                        max={150}
                                                                    />
                                                                    <Button className='digiSign' onClick={() => { handleDigitalSignPopUp() }}>
                                                                        <div className='signSVG'></div> Digi Sign
                                                                    </Button>
                                                                </InputGroup>
                                                                <span>Maximum 150 Characters</span>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.notes}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {signature && isViewOnlyMode && notesDetails.enable && <Row>
                                                        <Col xs={{ span: 2, offset: 8 }}>
                                                            <div className=" mg-auto">
                                                                <Button
                                                                    className="esi-white-cancel-button-lg"
                                                                    onClick={() => handleCancel(resetForm)}
                                                                >
                                                                    CANCEL

                                                                </Button>

                                                            </div>
                                                        </Col>
                                                        <Col xs={{ span: 2, offset: 0 }}>
                                                            <div className="">
                                                                <Button
                                                                    className="esi-navy-blue-button-lg"
                                                                    disabled={!isValid || isSubmitting}
                                                                    type="submit"
                                                                >
                                                                    SAVE
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>}
                                                </Form>
                                            )}

                                        </Formik>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CommonAccordian>
                </div>
                <div>
                    {showOM && <CommonAccordian
                        data={{
                            title: "Office Memorandum",
                            eventKey: "1",
                        }}
                        clsBody={true}
                    >
                        {errorOMMessage && <TextError>{errorOMMessage}</TextError>}
                        <Row className='firstRow'>
                            <Col>
                                {omFile && <div className='pdfDisplay'>
                                    <object width="100%" height="1200px" data={`data:application/pdf;base64,${omFile}`} type="application/pdf">   </object>
                                </div>}
                            </Col>
                        </Row>
                        <Row className='secondRow'>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <div>
                                    <div className="me-5 previewCls" title='Preview'></div>
                                    <div className='ctnTxt'>Preview</div>
                                </div> */}
                                <div onClick={() => { saveData(omFile, "officeMemrandum.pdf") }}>
                                    <div className="downloadCls" title='Download'></div>
                                    <div className='ctnTxt'>Download</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='notes-card-form'>
                                    <div className='notes-card-data'>
                                        <Formik
                                            onSubmit={handleMemoSubmit}
                                            initialValues={initialMemoForm}
                                            enableReinitialize={true}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                resetForm,
                                                values,
                                                isValid,
                                                touched,
                                                errors,
                                                isSubmitting
                                            }) => (
                                                <Form onSubmit={handleSubmit} className="add-notes-container">

                                                    <Row>
                                                        <Col xs={6}>
                                                            <Form.Group className="mb-3" controlId="notes">
                                                                <Form.Label>Digital Signature</Form.Label>
                                                                <Button className='digiSignOm' onClick={() => { handleDigitalSignPopUp() }}>
                                                                    <div className='signSVG'></div> Digi Sign
                                                                </Button>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={2}></Col>
                                                        {signature && isViewOnlyModeOM && <>
                                                            <Col xs={2} className="omButtons mb-3">
                                                                <div className=" mg-auto">
                                                                    <Button
                                                                        className="esi-white-cancel-button-lg"
                                                                        onClick={() => handleMemoCancel(resetForm)}
                                                                    >
                                                                        CANCEL

                                                                    </Button>

                                                                </div>
                                                            </Col>
                                                            <Col xs={2} className="omButtons mb-3">
                                                                <div className="">
                                                                    <Button
                                                                        className="esi-navy-blue-button-lg"
                                                                        disabled={!isValid || isSubmitting}
                                                                        type="submit"
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </div>
                                                            </Col></>
                                                        }
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CommonAccordian>}
                </div>
            </Stack>
            {<ApprovedToggleModal
                title={"Success"}
                description={description}
                show={approvedModalShow}
                onHide={() => {
                    getOfficeNotesPDF();
                    getOfficeMemoPDF();
                    setApprovedModalShow(false);
                }}
            />}
            {digitalModalShow && <DigitalSignModal show={digitalModalShow} onHide={modalClosed} data={signature}></DigitalSignModal>}
        </>
    );
}