import { config } from '../constants';
import { http } from './http.service';

export const DependantService = {
  createDependant,
  deleteDependant,
  getIpDependants,
  getRelationships,
  getIpInfo,
  updateIpInfo,
  sendCardInfo,
  getIpdCardInfo,
  getUserInfo,
  getDispensaries,
  updateUserInfo,
  getWorkFlowState,
  postWorkFlowState,
  getClaimActivities
};

async function sendCardInfo(requestBody) {
  const url = `${config.apiUrl}/admin/card-info`;
  if (requestBody?.id) {
    return http.putData(url, requestBody).then((res) => {
      return res;
    });
  } else {
    return http.postData(url, requestBody).then((res) => {
      return res;
    });
  }
}

async function getIpdCardInfo(ipNumber) {
  const url = `${config.apiUrl}/admin/card-info?ipNumber=${ipNumber}`;
  return http.getData(url).then((res) => {
    return res;
  });
}


async function createDependant(requestData) {
  const url = `${config.apiUrl}/dependents/ip-dependents`;
  console.log(requestData);
  if (!requestData.id) {
    return http.postData(url, requestData).then((user) => {
      return user;
    });
  } else {
    return http.putData(url, requestData).then((user) => {
      return user;
    });
  }

  // dependantTableData.push(newdata);
  // return dependantTableData;
}

async function deleteDependant(requestData) {
  const url = `${config.apiUrl}/dependents/ip-dependents?dependentId=${requestData.id}`;
  console.log(requestData);
  return http.deleteData(url).then((user) => {
    return user;
  });
}

async function getIpDependants() {
  const url = `${config.apiUrl}/dependents/get-ip-dependents`;
  return http.getData(url).then((dependantData) => {
    return dependantData;
  });
  // return dependantTableData;
}

async function getRelationships() {
  const url = `${config.apiUrl}/dependents/ip-relationships`;
  return http.getData(url).then((relationships) => {
    return relationships;
  });
}

async function getIpInfo() {
  const url = `${config.apiUrl}/ip/ip-info`;
  return http.getData(url).then((ipInfo) => {
    return ipInfo;
  });
}

async function updateIpInfo(requestData) {
  const url = `${config.apiUrl}/ip/ip-info`;
  console.log(requestData);
  return http.putData(url, requestData).then((user) => {
    return user;
  });
}

async function getUserInfo(id) {
  const url = `${config.apiUrl}/admin/party/?partyId=${id}`;
  return http.getData(url).then((empData) => {
    return empData;
  });
}

async function getDispensaries(id) {
  const url = `${config.apiUrl}/admin/party/${id}/dispensaries`;
  return http.getData(url).then((dispensaries) => {
    return dispensaries;
  });
}

async function updateUserInfo(requestData) {
  console.log("dependency service update request", requestData)
  const url = `${config.apiUrl}/admin/party/${requestData.id}`;
  console.log(requestData);
  return http.putData(url, requestData).then((user) => {
    return user;
  });
}

async function getWorkFlowState(caseNumber) {
  const url = `${config.workFlowUrl}/workflow/state-metadata/${caseNumber}`;
  return http.getData(url).then((workflowData) => {
    return workflowData;
  });
}

async function postWorkFlowState(requestBody) {
  const url = `${config.workFlowUrl}/workflow/state-change`;
  return http.postData(url, requestBody).then((res) => {
    return res;
  });
}

async function getClaimActivities(caseNumber) {
  console.log('claim activities with case number ',caseNumber);
  const url = `${config.claimsApiUrl}/cw/claim-activity?claimId=${caseNumber}`;
  return http.getData(url).then((claimActivies) => {
    console.log('claim activities ',claimActivies);
    return claimActivies;
  });
}
