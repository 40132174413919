import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import "../../SCSS/Views/Welcome.scss";

const WelcomePage = ({ children }) => {
  return (
    <>
      {/* <div className="Container1"> */}
      <Row>
        <Col xl={6} className="d-none d-lg-block">
          <div className="welcome-left-card">
            <div className="image-service-icon"></div>
            <Stack>
              <div className="esi-claim">
                <span className="esi-claim-system-title">ESI Claim</span>
                <span
                  style={{
                    fontSize: "2.5rem",
                    color: "white",
                  }}
                >
                  Reimbursement System
                </span>
                <br />
                <span
                  style={{
                    fontSize: "1.5rem",
                    color: "white",
                  }}
                >
                  Government of Karnataka
                </span>
              </div>
            </Stack>
          </div>
        </Col>
        <Col className="welcome-col-2" xl={6}>
          <Row>
            <Col xs={12} lg={4}>
              <div className="image-chief-min-icon" />
            </Col>
            <Col xs={12} lg={6} className="col-mid-pos">
              <div className="kar-main-logo"></div>
              <Stack direction="vertical" gap={1}>
                <div className="heading1">
                  <div className="div1">
                    <b>Employee's State Insurance Scheme Medical Service</b>
                  </div>
                  <div>Government of Karnataka</div>
                </div>
              </Stack>
            </Col>

            <Col lg={2} className="col-end-pos d-none d-lg-block">
              <div className="esi-main-logo"></div>
            </Col>
          </Row>
          <div className="welcome-children">{children}</div>
        </Col>
      </Row>
    </>
  );
};

export default WelcomePage;
