import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../SCSS/CommonComponentsCSS/ToggleClaimStateStyle.scss";
import { ConfirmToggleStateModel } from "./Modals/ConfirmToggleStateModal";
import { DependantService } from "../../_services/DependantService";
import { RejectedStateModal } from "./Modals/RejectedStateModal";
import { ApprovedToggleModal } from "./Modals/ApprovedToggleModal";
import { claimStateToggleData } from "../../Test/Data/claimStateToggleData";
import { Role } from "../../_helpers";
import { RejectedToggleModal } from "./Modals/RejectedToggleModal";


export const ToggleClaimState = (props) => {

    const [show, setShow] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [approvedModalShow, setApprovedModalShow] = useState(false);
    const [workFlowStates, setWorkFlowStates] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isActive, setActive] = useState(false);
    const [left, setLeft] = useState('');
    const [right, setRight] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dispenseryMatch, setDispenseryMatch] = useState(false);
    const [userRoleSession, setUserRoleSession] = useState('');
    const [sobPresent, setSobPresent] = useState(false);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        fetchWorkflowState();
        checkToggleData();
    }, [props.data, props.ipCardData, props.sobData]);

    useEffect(() => {
        fetchWorkflowState();
    }, [completed]);

    const checkToggleData = () => {
        const isViewPermission = [
            Role.caseWorker, Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist
        ];
        const userRole = JSON.parse(localStorage.currentUser).authorites[0];
        const dispensaries = JSON.parse(localStorage.currentUser).dispensaries;
        console.log("User role ", userRole);
        setUserRoleSession(userRole);       
        if (dispensaries.includes(props.data.dispenseryId)) {          
            setDispenseryMatch(true);
        }
        if (isViewPermission.includes(userRole)) {            
            setDispenseryMatch(true);
        }
        if (props.sobData.length > 0) {            
            setSobPresent(true);
        } else {         
            setSobPresent(false);
        }
    }



    const fetchWorkflowState = async () => {
        setLoading(true);
        await DependantService.getWorkFlowState(props.caseDetails).then((res) => {
            if (res.errorCode) {
                console.log(res.message);
            } else {
                setEnabled(res.enable);
                setWorkFlowStates(res.states);
                if (res.states != null) {
                    let leftRightFlag = false;
                    for (let i = 0; i < res.states.length; i++) {
                        if (leftRightFlag == false) {
                            if (res.states[i].showInToggle == true) {                               
                                setLeft(res.states[i].state)
                                leftRightFlag = true;
                                continue
                            }
                        }
                        if (leftRightFlag == true) {
                            if (res.states[i].showInToggle == true) {                         
                                setRight(res.states[i].state)
                            }
                        }
                    }
                    // setClaimState(res.states[0].state)
                }
               
                if (dispenseryMatch == false) {
                    setEnabled(false);
                }
                else {
                    /*if(userRoleSession==Role.caseWorker && props.ipCardData==null ){
                        setEnabled(false);
                    }
                    else if(userRoleSession==Role.pharmacist && sobPresent==false ){
                        setEnabled(false);
                    }*/
                    setEnabled(res.enable);

                }
            }
            setLoading(false);
            setCompleted(true);
        });
    };

    const handleProceedClick = async (e) => {
        setLoading(true);
        const requestData = {
            claimId: props.caseDetails,
            nextState: right,
        };

        return await DependantService.postWorkFlowState(requestData).then(
            (res) => {
                setLoading(false);
                if (res.errorCode) {
                    return res.message;
                }
                return "";
            },
            (error) => {
                console.error(error);
                setShowError(true);
                setLoading(false);
                setErrorMessage("Something went wrong..., contact administrator!!!");
                return "Something went wrong..., contact administrator!!!";
            }
        );
    };


    const changeClaimState = (changedState) => {
        setRight(workFlowStates[changedState].state);
        handleShow();
        setActive(true);
        setEnabled(false);
    };

    const handleClose = () => {
        setShow(false);
        //setShowRejectModal(true);
        setRight('')
        setEnabled(true)

    }

    const handleShow = () => setShow(true);



    const saveClaimChanges = async () => {
        setShow(false);
        const res = await handleProceedClick();

        if (res == "") {
            setApprovedModalShow(true);
            props.handleRefresh()
        } else {
            setErrorMessage(res);
            setShowRejectModal(true);
            setEnabled(true);
            setRight('');
            props.handleRefresh()
        }

    }

    return (
        <>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='toggle-state-style'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div align="center">
                                        <div className="toggle-buttons">
                                            {workFlowStates.map((st, index) =>
                                            (
                                                index >= 0 && workFlowStates[index].showInToggle == false ?
                                                    (<Button className={enabled ? "btn-select-dark" : "btn-select-grey"} disabled={!enabled} onClick={() => changeClaimState(index)}>{workFlowStates[index].state.toUpperCase()}</Button>) : <></>
                                            )
                                            )}
                                            <ConfirmToggleStateModel
                                                show={show}
                                                onHide={handleClose}
                                                saveChanges={saveClaimChanges}
                                            />
                                            <RejectedToggleModal
                                                show={showRejectModal}
                                                errorMessage={errorMessage}
                                                onHide={() => setShowRejectModal(false)}
                                            />
                                            <ApprovedToggleModal
                                                title={right}
                                                show={approvedModalShow}
                                                onHide={() => setApprovedModalShow(false)}
                                            />
                                        </div>
                                        <div className="row">
                                            {enabled &&
                                                <div className="drag-click-text">
                                                    Click to change the State
                                                </div>
                                            }
                                            {!enabled &&
                                                <div className="drag-click-text">
                                                    Change State Action cannot be performed
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="toggle-bar">
                                        <div className="row">
                                            <div className="col-sm-1">
                                                <div className="button-align-right">
                                                    {/* {workFlowStates.map((st,index)=>
                                                        (
                                                         workFlowStates[index].showInToggle==true?    
                                                        (<Button className="btn-initial">{workFlowStates[index].state.toUpperCase()}</Button>):(index<1?(<Button className="btn-initial-grey">{claimState.toUpperCase()}</Button>):<></>)
                           
                                                        )      
                                                    )}  */}
                                                    {left != '' ? (<Button className="btn-initial">{left.toUpperCase()}</Button>) : (<Button className="btn-initial-grey">emptiness</Button>)}
                                                </div>
                                            </div>
                                            <div className="col-sm-10">
                                                <div align="center">
                                                    <div className='display-bar'>bar</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-1">
                                                <div className="button-align-left">

                                                    {/* {
                                                          (<Button className={!enabled?"btn-final-dark rounded":"btn-final-grey rounded"}>{claimState.toUpperCase()}</Button>):<></>
                                                        ) 
                                                    !isActive?
                                                    (workFlowStates.map((st,index)=>
                                                        (
                                                        index!=0 && workFlowStates[index].showInToggle==true? 
                                                       
                                                        (<Button className={"btn-final-dark"}>{workFlowStates[index].state.toUpperCase()}</Button>):(index<1?(<Button className={"btn-final-grey"}>{claimState.toUpperCase()}</Button>):<></>)
                                                        )
                                                        )):((<Button className={"btn-final-dark"}>{claimState.toUpperCase()}</Button>))
                                                        } */}

                                                    {right != '' ? (<Button className="btn-final-dark">{right.toUpperCase()}</Button>) : (<Button className="btn-final-grey">Less State</Button>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
