import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Stack,
  Table,
  Modal,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { CommonAccordian } from "../../CommonComponents/CommonAccordian";
import EsiDatePicker from "../../CommonComponents/EsiDatePicker";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import { HorizontalDivider } from "../../CommonComponents/HorizontalDivider";
import { IpProfileInfo } from "../../CommonComponents/IpProfileInfo";
import TextError from "../../CommonComponents/TextError";
import "../../SCSS/Views/AddNewClaim.scss";
import {
  attachmentListPatientInfo,
  attachmentListTreatmentInfo,
} from "../../Views/User/Data/Attachment_List";
import { DependantService } from "../../_services/DependantService";
import { IpClaimService } from "../../_services/IpClaimService";
import { DependentModal } from "../../CommonComponents/DependantModal";
import { RiEditBoxLine } from "react-icons/ri";
import { CancelAlert } from "../../Views/User/CancelAlert";


const numWords = require("num-words");
export const AddNewClaim = () => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        console.log(e.value);
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  const history = useHistory();
  const [dependantRow, setDependantRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);//cancelModel
  const [cancelModel, setCancelModel] = useState(false);//cancelModel

  const [birthDate, setBirthDate] = useState(null);
  const [claimAmount, setClaimAmount] = useState(0);
  const [dispenseryList, setDispenseryList] = useState([]);
  const [ipInfo, setIpinfo] = useState({});
  const [dependantData, setDependantData] = useState([]);
  const [age, setAge] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [dispensaryId, setDispensaryId] = useState(null);
  const [isKannada, setIsKannada] = useState(false);
  const [isReffered, setIsReffered] = useState(false);
  const [dependentId, setDependentId] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [declarationArray, setDeclarationArray] = useState([]);
  const formRef = useRef();
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);

  useEffect(() => {
    getDispensaryData();
    getIpInfoData();
    fetchDependants();
    getAttachmentMasterData();
  }, []);

  const schema = yup.object().shape({
    claimAmount: yup
      .number()
      .min(0, "Min value 0.")
      .max(9999999.99, "You Have Reached Max Calim Amount")
      .required("Add Claim amount"),
    treatMentFromDate: yup
      .date()
      .required("Treatment from is Required")
      .nullable(),
    treatMentToDate: yup
      .date()
      .required("Treatment to is Required")
      .nullable(),
    dispensery: yup.object().shape({
      value: yup.string().required("Please Select One").nullable(),
    }),
    dependantName: yup.string().required("Select One"),
    DC01: yup.string().required("Select One"),
    DC02: yup.string().required("Select One"),
    DC03: yup.string().required("Select One"),
    DC04: yup.string().required("Select One"),
    DC05: yup.string().required("Select One"),
    DC06: yup.string().required("Select One"),
    DC07: yup.string().required("Select One"),
    DC08: yup.string().required("Select One"),
    DC09: yup.string().required("Select One"),
    DC10: yup.string().required("Select One"),
    DC11: yup.string().required("Select One"),
    DC12: yup.string().required("Select One"),
    DC13: yup.string().required("Select One"),
    DC14: yup.string().required("Select One"),
    DC15: yup
      .string()
      .max(250, "Only 250 Chars Allowed")
      .matches(/^[A-Za-z0-9 ,.-]+$/, "No special characters allowed to disable HTML scripting"),
    //.required("Mandatory Field"),
    DC16: yup
      .number()
      .min(0, "Min value 0.")
      .max(99, "2 digits only")
      .required("Required Field"),
    DC17: yup
      .number()
      .min(0, "Min value 0.")
      .max(99, "2 digits only")
      .required("Required Field"),
    DC18: yup.bool().oneOf([true], "Consent is mandatory "),
  });

  const getAttachmentMasterData = async () => {
    setDeclarationArray(await IpClaimService.getAttachmentMasterData());
  };

  const getDispensaryData = async () => {
    setDispenseryList(await IpClaimService.getDispensaries());
  };

  const getIpInfoData = async () => {
    setIpinfo(await DependantService.getIpInfo());
  };

  const fetchDependants = async () => {
    setDependantData(await DependantService.getIpDependants());
  };

  const getNumtoWords = () => {
    try {
      let amount;
      if (claimAmount.includes(".")) {
        amount = claimAmount.toString().split(".")[0];
      } else {
        amount = claimAmount;
      }
      return numWords(parseFloat(amount)) + " rupees only/-";
    } catch (e) {
      return "Invalid Claim Amount.";
    }
  };

  const checkDecimalPlaces = async (e) => {
    let decimalValueArray = ["0", "5", "00", "50"];
    let decimalValue = claimAmount.toString().split(".")[1];
    if (decimalValue) {
      if (decimalValue.length >= 2 || e.key === "e") {
        if (
          !(e.key === "Delete" || e.key === "Backspace" || e.key === "Enter")
        ) {
          e.preventDefault();
        }
      }
    } else if (e.key === "e") {
      console.log("checking e");
      e.preventDefault();
    }
  };

  const handleFormSubmit = async (e) => {
    setLoaderStatus(true);
    const requestData = {
      dispenseryId: e.dispensery.value,
      appliedClaimAmount: e.claimAmount,
      patientId: e.dependantName,
      esiHospitalReferral: isReffered,
      treatmentFrom: e.treatMentFromDate ? convertDate(e.treatMentFromDate) : null,
      treatmentTo: e.treatMentToDate ? convertDate(e.treatMentToDate) : null,
      declarationList: getDeclarationList(e, declarationArray),
    };
    const res = await IpClaimService.createClaim(requestData).then(
      (res) => {
        if (res.errorCode) {
          setErrorMessage(res.message)
        } else {
          history.push("/user");
        }
        setLoaderStatus(false);
      },
      (error) => {
        console.error(error);
        setLoaderStatus(false);
        setShowError(true);
      }
    );
  };

  const handleSearchChange = (e) => {
    console.log(e);
    if (e) {
      setDispensaryId(e.value);
    }
  };

  const redirectToUserHome = () => {
    setCancelModel(true)
    //history.push('/user');

  };
  const deleteModalClosed = async () => {
    setCancelModel(false);
    //fetchDependants();
  };

  const checkOptionChange = (e) => {
    console.log(e.target.value);
    const oneDependant = dependantData.find((element) => {
      console.log(element.id == e.target.value);
      return element.id == e.target.value;
    });
    if (oneDependant) {
      setDependantRow(oneDependant);
      setAge(oneDependant.age);
      setRelationship(oneDependant.relationshipType);
      setBirthDate(oneDependant.dob);
      setDependentId(oneDependant.id);
    } else {
      setDependantRow({});
      setAge("");
      setRelationship("");
      setBirthDate("");
      setDependentId("");
    }
  };
  const handleEdit = () => {
    setDependantRow(dependantRow);
    setModalShow(true);
  };

  const modalClosed = (oneDependant) => {
    setModalShow(false);
    fetchDependants();
    setDependantRow(oneDependant);
    setAge(oneDependant.age);
    setRelationship(oneDependant.relationshipType);
    setBirthDate(oneDependant.dob);
    setDependentId(oneDependant.id);
  };

  const Close = () => {
    setModalShow(false);
    fetchDependants();
    setDependantRow(dependantRow);
    setAge(dependantRow.age);
    setRelationship(dependantRow.relationshipType);
    setBirthDate(dependantRow.dob);
    setDependentId(dependantRow.id);
  };

  const handleToggle = () => {
    if (isKannada) {
      setIsKannada(false);
    } else {
      setIsKannada(true);
    }
  };
  const valid_numbers = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }
  };

  const recalculate = e => {
    ChangeTextAreaCount(e.target.value.length);
  };
  const today = new Date();
  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Row className="row-breadcrumb">
        <Col xs={12} md lg={6}>
          <div>
            <MdKeyboardBackspace
              className="BackIcon"
              onClick={() => history.goBack()}
            ></MdKeyboardBackspace>
          </div>
          <div className="breadcrumb-div">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/user" className="homeIcon-svg"><div className="homeIcon-svg"></div></Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Add Claims</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
        <Col xs={5} md lg={6}>
          <div>
            <label style={{ paddingTop: "1rem" }} class="switch">
              <input onChange={handleToggle} type="checkbox" id="togBtn" />
              <div class="slider round">
                <span class="on">English</span>
                <span class="off">Kannada</span>
              </div>
            </label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md lg={12} className="col-sub-header">
          ESI Claim Reimbursement System
        </Col>
      </Row>
      <HorizontalDivider color="#5277ac" />
      <Row className="ip-profile-info-row">
        <CommonAccordian
          data={{
            title: "Insured Person Information",
            subtitle: "Insured Person Medical Claim Application",
            eventKey: "0",
          }}
        >
          <IpProfileInfo isKannada={isKannada}></IpProfileInfo>
        </CommonAccordian>
      </Row>
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            dispensery: {},
            dependantName: "",
            relationship: "",
            age: "",
            claimAmount: "",
            treatMentFromDate: "",
            treatMentToDate: "",
            DC01: "",
            DC02: "",
            DC03: "",
            DC04: "",
            DC05: "",
            DC06: "",
            DC07: "",
            DC08: "",
            DC09: "",
            DC10: "",
            DC11: "",
            DC12: "",
            DC13: "",
            DC14: "",
            DC15: "", // othersTextArea
            DC16: "", //presCount
            DC17: "", //billsCount
            DC18: false, //selfsigned
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={5}>
                <div>
                  <CommonAccordian
                    data={{
                      title: "Patient Information",
                      subtitle:
                        "Name, Age and Relationship Details of the Patient",
                      eventKey: "1",
                    }}
                  >
                    {/* {!values?.dependantName ? null : (
                      <Row className="add-claim-edit-row">
                        <p className="edit-class"></p>
                        <RiEditBoxLine
                          className="blue-edit-icon"
                          onClick={handleEdit}
                          cursor="pointer"
                        />
                      </Row>
                    )} */}
                    <Row className="new-claim-row">
                      <Col xs={12} md lg={3}>
                        <Form.Group className="md-2" controlId="formBasicEmail">
                          {/* Patient Name */}
                          <Form.Label>
                            {attachmentListPatientInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "1"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="dependantName"
                            className="select-dropdowns"
                            onChange={(e) => {
                              checkOptionChange(e);
                              handleChange("dependantName")(e);
                            }}
                            //onBlur={handleBlur}
                            value={values.dependantName} // <--- it should allow an array of strings, currently the code won't compile or won't update the form value as it has multiple in the form control
                            isValid={
                              touched.dependantName && !errors.dependantName
                            }
                            isInvalid={!!errors.dependantName}
                          >
                            <option
                              value=""
                              defaultValue
                              className="select-dropdowns"
                            >
                              Select Patient Name
                            </option>
                            {dependantData.map((dependant) => (
                              <option
                                className="select-dropdowns"
                                value={dependant.id}
                              >
                                {dependant.dependentName},
                                {dependant.relationshipType}
                              </option>
                            ))}
                            <Dropdown.Divider />
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.dependantName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={3}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* Patient Age */}
                          <Form.Label>
                            {attachmentListPatientInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "2"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={age}
                            readOnly
                            //  isValid={ age >= 1}
                            isInvalid={age == 0}
                          />
                          <Form.Control.Feedback type="invalid">
                            {"Please Update Age"}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={3}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* Relationship */}
                          <Form.Label>
                            {attachmentListPatientInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "3"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={relationship}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={3}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* Date of Birth */}
                          <Form.Label>
                            {attachmentListPatientInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "4"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Group>
                            <Field
                              //placeholder={() => <FaCalendarAlt />}
                              type="date"
                              className="form-control"
                              value={birthDate ? convertDate(birthDate) : "dd-mm-yyyy"}
                              readOnly
                            />
                          </Form.Group>
                        </Form.Group>
                      </Col>
                    </Row>
                  </CommonAccordian>
                </div>
                <div>
                  <CommonAccordian
                    data={{
                      title: "Treatment Information",
                      subtitle:
                        "Claim Information and Time Period at which the Treatment was Taken",
                      eventKey: "2",
                    }}
                  >
                    <Row className="new-claim-row">
                      <Col xs={12} md lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* Dispensary */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "1"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Select
                            aria-label="Floating label select example"
                            isClearable={true}
                            name="dispensery"
                            // value={values.dispensery}
                            // onChange={handleSearchChange}
                            className="select-dropdowns"
                            onChange={(selectedOption) => {
                              // This inline function can now completely be reaplce by handleChange("year")
                              // handleChange("dispensery")(selectedOption);

                              setFieldValue("dispensery", selectedOption);
                            }}
                            options={dispenseryList.map((opt) => ({
                              label: opt.dispensaryName,
                              value: opt.dispenseryId,
                            }))}
                          />
                          <TextError>
                            {errors.dispensery
                              ? "Please Select a Dispensary"
                              : ""}
                          </TextError>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={4}>
                        <Form.Group className="mb-3 claim-col">
                          {/* Claim Amount */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "2"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="claimAmount"
                            value={values.claimAmount}
                            onChange={handleChange}
                            maxLength={7}
                            isValid={touched.claimAmount && !errors.claimAmount}
                            isInvalid={!!errors.claimAmount}
                            onKeyUp={(e) => setClaimAmount(e.target.value)}
                            onKeyDown={(e) => checkDecimalPlaces(e)}
                            onKeyPress={valid_numbers}

                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.claimAmount}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={4}>
                        {!claimAmount ? (
                          ""
                        ) : (
                          <Stack className="claim-stack">
                            <div
                              style={{ color: "#172d66", fontWeight: "bold" }}
                            >
                              Claim Amount In Words
                            </div>
                            <div>{getNumtoWords()}</div>
                          </Stack>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md lg={4}>
                        {/* Treatment From */}
                        <Form.Group
                          className="mb-3 treatment-col"
                          controlId="formBasicEmail"
                        >
                          <Form.Label >
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "3"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Group >
                            {/* <EsiDatePicker
                              maxDate={new Date()}
                              name="treatMentFromDate"
                            /> */}
                            <Field
                              type="date"
                              className="form-control"
                              name="treatMentFromDate"
                              max={convertDate(new Date())}
                              min={convertDate(new Date(today - (365 * 86400000)))}
                            />
                            <ErrorMessage component={TextError} name="treatMentFromDate" />
                            <TextError>
                              {errors.treatMentFromDate
                                ? "Please Select a Treatment From Date"
                                : ""}
                            </TextError>
                          </Form.Group>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* Treatment To */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "4"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Group >
                            <Field
                              type="date"
                              className="form-control"
                              name="treatMentToDate"
                              max={convertDate(new Date())}
                              min={convertDate(new Date(values.treatMentFromDate))}
                            />
                            <ErrorMessage component={TextError} name="treatMentToDate" />
                            <TextError>
                              {errors.treatMentToDate
                                ? "Please Select a Treatment To Date"
                                : ""}
                            </TextError>
                            {/* <EsiDatePicker
                              maxDate={new Date()}
                              minDate={new Date(values.treatMentFromDate)}
                              name="treatMentToDate"
                            /> */}
                          </Form.Group>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md lg={4}>
                        <Form.Group
                          className="mb-3 div"
                          controlId="formBasicEmail"
                        >
                          {/* Is the Treament Referred by ESI hospital */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "5"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <div>
                            <label class="switch" style={{ float: "left" }}>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setIsReffered(e.target.checked)
                                }
                                id="togBtn"
                              />
                              <div class="slider round">
                                <span class="on">YES</span>
                                <span class="off">NO</span>
                              </div>
                            </label>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </CommonAccordian>
                </div>

                <div>
                  <CommonAccordian
                    data={{
                      title: "Declaration",
                      subtitle: "Details of the Attachment to be Submitted",
                      eventKey: "3",
                    }}
                  >
                    <Table responsive>
                      <thead className="table">
                        <tr>
                          <th>Document Details</th>
                          <th style={{ textAlign: "center" }}>N/A</th>
                          <th style={{ textAlign: "center" }}>
                            Original Document
                          </th>
                          <th style={{ textAlign: "center" }}>
                            Copy of Document
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {declarationArray.map((eachDeclaration) =>
                          eachDeclaration.fieldType === "CHECK_BOX" ? (
                            <tr>
                              <td>
                                {isKannada
                                  ? eachDeclaration.declartionTextKn
                                  : eachDeclaration.declartionTextEn}
                              </td>
                              <td
                                style={{ textAlign: "center", width: "9rem" }}
                              >
                                <Field
                                  name={eachDeclaration.declarationCode}
                                  type="radio"
                                  className="form-check-input"
                                  id={eachDeclaration.declarationCode}
                                  onBlur={handleBlur}
                                  checked={
                                    values[eachDeclaration.declarationCode] ===
                                    "N/A"
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      eachDeclaration.declarationCode,
                                      "N/A"
                                    )
                                  }
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Field
                                  name={eachDeclaration.declarationCode}
                                  type="radio"
                                  className="form-check-input"
                                  id={eachDeclaration.declarationCode}
                                  onBlur={handleBlur}
                                  checked={
                                    values[eachDeclaration.declarationCode] ===
                                    "true"
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      eachDeclaration.declarationCode,
                                      "true"
                                    )
                                  }
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Field
                                  name={eachDeclaration.declarationCode}
                                  type="radio"
                                  className="form-check-input"
                                  id={eachDeclaration.declarationCode}
                                  onBlur={handleBlur}
                                  checked={
                                    values[eachDeclaration.declarationCode] ===
                                    "false"
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      eachDeclaration.declarationCode,
                                      "false"
                                    )
                                  }
                                />
                              </td>
                              <TextError>
                                <div style={{width:"8rem"}}>{errors[eachDeclaration.declarationCode]}</div>
                              </TextError>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </Table>
                    <HorizontalDivider />
                    <Row>
                      <Col xs={12} md lg={6}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          {/* If Others, Please specify. */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "7"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="DC15"
                            type="text"
                            maxLength={250}
                            value={values.DC15}
                            onChange={handleChange}
                            isValid={touched.DC15 && !errors.DC15}
                            isInvalid={!!errors.DC15}
                            rows={3}
                            onKeyUp={recalculate}
                          />
                          <p>{textAreaCount}/250</p>
                          <Form.Control.Feedback type="invalid">
                            {errors.DC15}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md lg={3}>
                        <Form.Group className="mb-3">
                          {/* Count of Prescription Attached */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "8"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="DC16"
                            value={values.DC16}
                            onChange={handleChange}
                            isValid={touched.DC16 && !errors.DC16}
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                            maxLength={2}
                            onKeyPress={valid_numbers}
                            isInvalid={!!errors.DC16}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.DC16}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md lg={3}>
                        <Form.Group className="mb-3">
                          {/* Count of bills Attached */}
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === "9"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="DC17"
                            value={values.DC17}
                            onChange={handleChange}
                            isValid={touched.DC17 && !errors.DC17}
                            isInvalid={!!errors.DC17}
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                            maxLength={2}
                            onKeyPress={valid_numbers}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.DC17}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <TextError>{errors.DC18}</TextError>

                          <label class="switch">
                            <input
                              name="DC18"
                              value={values.DC18}
                              onChange={handleChange}
                              type="checkbox"
                              id="togBtn"
                            />
                            <div class="slider round">
                              <span class="on">YES</span>
                              <span class="off">NO</span>
                            </div>
                          </label>
                        </div>

                        <div style={{ fontWeight: "400", fontSize: "1rem" }}>
                          <Form.Label>
                            {attachmentListTreatmentInfo
                              .filter(
                                (attachment) =>
                                  attachment.attachment_Id === "10"
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                        </div>
                      </Stack>
                    </Row>
                  </CommonAccordian>
                </div>
                {/* Form submit Buttons*/}
                <div>
                  {errorMessage && <TextError>{errorMessage}</TextError>}
                  <Row>
                    <Col xs={12} md lg={{ span: 2, offset: 8 }}>
                      <div className=" mg-auto">
                        <Button
                          className="esi-white-cancel-button-lg"
                          onClick={redirectToUserHome}
                        >
                          CANCEL

                        </Button>

                      </div>
                    </Col>
                    <Col xs={12} md lg={{ span: 2, offset: 0 }}>
                      <div className="">
                        <Button
                          className="esi-navy-blue-button-lg"
                          disabled={!isValid || isSubmitting || age == 0}
                          type="submit"
                        // onSubmit={handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>

      <DependentModal
        show={modalShow}
        onHide={modalClosed}
        onCancel={Close}
        data={{ title: "Update Dependant", rowData: dependantRow }}
      />

      <CancelAlert
        show={cancelModel}
        onHide={deleteModalClosed}
      // data={{ rowData: dependantRow }}
      />
    </>
  );
};

export default AddNewClaim;

function getDeclarationList(e, masterDeclarationArray) {
  let declarationList = [];
  masterDeclarationArray.map((oneDeclaration) => {
    let decId = oneDeclaration.declarationCode;
    switch (decId) {
      case "DC01":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC01));
        return true;
      case "DC02":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC02));
        return true;
      case "DC03":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC03));
        return true;
      case "DC04":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC04));
        return true;
      case "DC05":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC05));
        return true;
      case "DC06":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC06));
        return true;
      case "DC07":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC07));
        return true;
      case "DC08":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC08));
        return true;
      case "DC09":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC09));
        return true;
      case "DC10":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC10));
        return true;
      case "DC11":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC11));
        return true;
      case "DC12":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC12));
        return true;
      case "DC13":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC13));
        return true;
      case "DC14":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC14));
        return true;
      case "DC15":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC15));
        return true;
      case "DC16":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC16));
        return true;
      case "DC17":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC17));
        return true;
      case "DC18":
        declarationList.push(getDeclarationObject(oneDeclaration.id, e.DC18));
        return true;
      default:
        return true;
    }
  });
  console.log(declarationList);
  return declarationList;
}

function getDeclarationObject(key, value) {
  return {
    declarationMasterId: key,
    declarationVal: value,
  };
}

function convertDate(date) {
  var forDate = new Date(date);
  var dd = forDate.getDate();
  var mm = forDate.getMonth() + 1;
  var yyyy = forDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var datestring = yyyy + "-" + mm + "-" + dd;
  return datestring;
}
