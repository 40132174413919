import { config } from "../constants";
import { http } from "./http.service";
import { authenticationService } from "../_services";
import { Role } from "../_helpers";

export const IpClaimService = {
  getIpClaimList,
  getIpClaimByNumber,
  getClaimComments,
  getDispensaries,
  approveClaim,
  getIpClaimListPaginated,
  getSearchRequestData,
  createComments,
  createClaim,
  updateClaim,
  getClaimStatusList,
  rejectClaim,
  getDashBoardCounts,
  getIpClaimListPaginatedCount,
  getAttachmentMasterData,
  getIpClaimDeclarationByNumber,
  claimSwitchStatus,
  getCUDispensaries,
  getClaimStageService,
  getSOBEditableValue,
};

async function createClaim(requestBody) {
  const url = `${config.claimsApiUrl}/ip/claim`;
  return http.postData(url, requestBody).then((res) => {
    return res;
  });
}
async function updateClaim(requestBody, claimId) {
  const url = `${config.claimsApiUrl}/ip/claim/${claimId}`;
  return http.putData(url, requestBody).then((res) => {
    return res;
  });
}
async function getIpClaimList() {
  const url = `${config.claimsApiUrl}/ip/all-claims`;
  return http.getData(url).then((res) => {
    return res;
  });
  // return claimTabledata;
}

async function getIpClaimListPaginated(params) {
  const url = `${config.claimsApiUrl}/cw/all-claims?page=${params.page}&results=${params.results}&orderBy=${params.orderBy}&sort=${params.sort}`;
  const requestBody = {
    queryDataList: params.searchQueryArray
  };
  return http.postData(url, requestBody).then((res) => {
    return res;
  });
  // return claimTabledata;
}

async function getIpClaimListPaginatedCount(params) {
  const url = `${config.claimsApiUrl}/cw/claims-count`;
  const requestBody = {
    queryDataList: params.searchQueryArray,
  };
  return http.postData(url, requestBody).then((res) => {
    return res;
  });
}

async function getIpClaimByNumber(claimNumber) {
  const url = `${config.claimsApiUrl}/ip/claim?claimId=${claimNumber}`;
  return http.getData(url).then((res) => {
    return res;     
  });
  // return OneIpClaimD?ata;
}




async function getIpClaimDeclarationByNumber(claimNumber) {
  const url = `${config.claimsApiUrl}/ip/claim-declaration?claimId=${claimNumber}`;
  return http.getData(url).then((res) => {
    return res;
  });
}

async function getClaimComments(claimNumber) {
  const url = `${config.claimsApiUrl}/comment/get-comments?claimId=${claimNumber}`;
  return http.getData(url).then((res) => {
    return res;
  });
  // return CommentData;
}

async function getClaimStatusList(claimNumber) {
  const url = `${config.claimsApiUrl}/ip/claim-status?claimId=${claimNumber}`;
  return http.getData(url).then((res) => {
    return res;
  });
  // return claimStatusList;
}

async function createComments(params) {
  const url = `${config.claimsApiUrl}/comment/add`;
  const requestBody = {
    comment: params.comment,
    claimId: params.claimId,
    ipUser: checkIpUser(),
    external: params.external,
  };

  return http.postData(url, requestBody).then((res) => {
    return res;
  });
  // return CommentData;
}

async function getDispensaries() {
  const url = `${config.claimsApiUrl}/data/get-dispensaries`;
  return http.getData(url).then((res) => {
    return res;
  });
}

async function approveClaim(caseNumber) {
  const url = `${config.claimsApiUrl}/cw/approve?claimId=${caseNumber}&approved=true`;
  return http.postData(url, []).then((res) => {
    return res;
  });
}
async function claimSwitchStatus(claimUuid, status) {
  const url = `${config.claimsApiUrl}/cw/change-status?claimId=${claimUuid}&status=${status}`;
  return http.postData(url, []).then((res) => {
    return res;
  });
}

async function rejectClaim(caseNumber) {
  const url = `${config.claimsApiUrl}/cw/approve?claimId=${caseNumber}&approved=false`;
  return http.postData(url, []).then((res) => {
    return res;
  });
}

async function getDashBoardCounts() {
  const url = `${config.claimsApiUrl}/ip/claim-dashboard`;
  return http.getData(url).then((res) => {
    return res;
  });
}

async function getClaimStageService(claimId) {
  const url = `${config.workFlowUrl}/workflow/claim-stages/${claimId}`;
  return http.getData(url).then((res) => {
    return res;
  });
}

async function getSOBEditableValue(claimId) {  
  const url = `${config.workFlowUrl}/workflow/get-SOBEditableValue/${claimId}`;
  return http.getData(url).then((res) => {    
    return res;
  });
}


function getAttachmentMasterData() {
  const url = `${config.claimsApiUrl}/data/get-declarations`;
  return http.getData(url).then((res) => {
    return res;
  });
}

function getSearchRequestData(
  searchByClaimNumber,
  searchBySakaalaNumber,
  searchByIpNumber,
  searchByPatientName,
  searchByDispensaryNumber
) {
  let searchRequestArray = [];
  if (searchByClaimNumber) {
    searchRequestArray.length === 0
      ? searchRequestArray.push(
        getFirstSearcObject("claimRefNum", searchByClaimNumber, "LIKE")
      )
      : searchRequestArray.push(
        getSearcObject("claimRefNum", searchByClaimNumber, "LIKE")
      );
  }
  if (searchBySakaalaNumber) {
    searchRequestArray.length === 0
      ? searchRequestArray.push(
        getFirstSearcObject("sakalaNumber", searchBySakaalaNumber, "LIKE")
      )
      : searchRequestArray.push(
        getSearcObject("sakalaNumber", searchBySakaalaNumber, "LIKE")
      );
  }
  if (searchByIpNumber) {
    searchRequestArray.length === 0
      ? searchRequestArray.push(
        getFirstSearcObject("ipNumber", searchByIpNumber, "LIKE")
      )
      : searchRequestArray.push(getSearcObject("ipNumber", searchByIpNumber, "LIKE"));
  }
  if (searchByPatientName) {
    searchRequestArray.length === 0
      ? searchRequestArray.push(
        getFirstSearcObject("patientName", searchByPatientName, "LIKE")
      )
      : searchRequestArray.push(
        getSearcObject("patientName", searchByPatientName, "LIKE")
      );
  }
  if (searchByDispensaryNumber) {
    const values = [];
    searchByDispensaryNumber.forEach(ele => {
      values.push(ele.value);
    })
    searchRequestArray.length === 0
      ? searchRequestArray.push(
        getFirstSearcObject("dispensaries", values, "IN")
      )
      : searchRequestArray.push(
        getSearcObject("dispensaries", values, "IN")
      );
  }

  return searchRequestArray;
}

function getFirstSearcObject(columnName, columnValue, operator) {
  const colValue = Array.isArray(columnValue) ? [...columnValue] : [columnValue];
  return {
    key: columnName,
    value: colValue,
    operator: operator,
    previousQueryClause: null,
  };
}

function getSearcObject(columnName, columnValue, operator) {
  const colValue = Array.isArray(columnValue) ? [...columnValue] : [columnValue];
  return {
    key: columnName,
    value: colValue,
    operator: operator,
    previousQueryClause: "AND",
  };
}

function checkIpUser() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser.authorites.indexOf(Role.ipUser) == -1) {
    return false;
  }
  return true;
}

function getCUDispensaries() {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser.dispensaries) {
    return currentUser.dispensaries;
  }
  return [];
}
