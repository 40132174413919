import { React, useEffect, useState } from "react";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../SCSS/Views/Login.scss";
import { Role } from "../../_helpers";
import { authenticationService } from "../../_services";
import { Formik } from "formik";
import * as yup from "yup";
import { EsiLoader } from "../../CommonComponents/EsiLoader";
import TextError from "../../CommonComponents/TextError";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const EsiAdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
 

  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const schema = yup.object().shape({
    username: yup
    .string()
    .max(15, "username maxlength is 15")
    .required("Username is Required"),
    password: yup
    .string().required("Please Enter Password")
    .max(20, "Password length must be upto 20 characters")
    .min(3, "Password length must be atleast 8 characters"),
  });

  useEffect(() => {
  }, []);

  
const handleFormSubmit = async (e) => {
  setLoaderStatus(true);
  if(username == null || username == ""){
    setShowError(true);
    setLoaderStatus(false);
  }else{
    await authenticationService.userManagementLogin({ username: username, password: password}).then(
      (response) => {
        console.log(response);
        if (response.jwt) {
          setLoaderStatus(false);
          history.push("/admin/homepage");
        } else {
          setLoaderStatus(false);
          setShowError(true);
        }
      },
      (error) => {
        console.error(error);
        setLoaderStatus(false);
        setShowError(true);
      }
    );
}
}
 
  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <div className="container-employeelogin-card">
        {showError && (
          <TextError>
            <p key="error" variant="danger">
              Username or Password is invalid
            </p>
          </TextError>
        )}
        
        <Stack className="login-heading">
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Admin Login
          </p>
        </Stack>
        <Formik
        onSubmit={handleFormSubmit}
          validationSchema={schema}
          initialValues={{
            username: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form className="esi-login-form" onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={1}>
                <div>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={values.username}
                      onChange={e=> {handleChange(e); setUsername(e.target.value)}}
                      onBlur={handleBlur}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      placeholder="Enter username"
                      maxLength={15}
                     
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4 password-input"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password/ಪಾಸ್ವರ್ಡ್</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      // minLength={8}
                      maxLength={20}
                      onChange={e=> {handleChange(e); setPassword(e.target.value)}}
                      placeholder="Enter Password"
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    {showPassword ? (
                      <div className="eye-icon">
                        <AiFillEye
                          cursor="pointer"
                          onClick={() => setShowPassword(false)}
                          size={23}
                        />
                      </div>
                    ) : (
                      <div className="eye-icon">
                        <AiFillEyeInvisible
                          cursor="pointer"
                          onClick={() => setShowPassword(true)}
                          size={23}
                        />
                      </div>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ textAlignLast: "center" }}>
                  <Button
                    variant="primary"
                    className="esi-navy-blue-button"
                    type="submit"
                    style={{width:"100%"}}
                    disabled={!isValid || isSubmitting}
                    // onClick={loginApi}
                  >
                    Login
                  </Button>
                </div>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EsiAdminLogin;
