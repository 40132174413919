import "../SCSS/CommonComponentsCSS/EsiLoader.scss";
import { Card, Placeholder } from "react-bootstrap";

export const EsiLoader = () => {
  return (
    <>
      <div className="esi-loader">
        <Card style={{ width: "18rem" }}>
          {/* <Card.Img className="loader-kar-image" variant="top" /> */}
          <Card.Body className="esi-card-body">
            <Card.Title className="esi-card-title">
              ESI <span>CLAIM</span>
            </Card.Title>

            <Card.Text>Fetching Data...</Card.Text>
            <Placeholder
              style={{ width: "15rem", background: "#172d66" }}
              as="p"
              animation="wave"
            >
              <Placeholder style={{ background: "#172d66" }} xs={12} />
            </Placeholder>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
