import React from 'react';
import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Col,
  Row,
  Stack
} from 'react-bootstrap';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory, Link } from 'react-router-dom';
import { CommonAccordian } from '../../CommonComponents/CommonAccordian';
import '../../SCSS/Views/AddNewClaim.scss';
import '../../SCSS/Views/Topbar.scss';
import { DependantService } from '../../_services/DependantService';
import { IpClaimService } from '../../_services/IpClaimService';
import { HorizontalDivider } from '../../CommonComponents/HorizontalDivider';
import { IpProfileInfoStyle } from '../../CommonComponents/IpProfileInfoStyle';

function UserProfileView() {
  const [ipInfo, setIpinfo] = useState({});
  const [dispenseryList, setDispenseryList] = useState([]);
  const [dependantData, setDependantData] = useState([]);
  const [title, setTitle] = useState('');
  const history = useHistory();

  useEffect(() => {
    getDispensaryData();
    getIpInfoData();
    fetchDependants();
  }, []);

  const getDispensaryData = async () => {
    setDispenseryList(await IpClaimService.getDispensaries());
  };
  const getIpInfoData = async () => {
    setIpinfo(await DependantService.getIpInfo());
  };
  const fetchDependants = async () => {
    setDependantData(await DependantService.getIpDependants());
  };

  return (
    <>
      <Row className='row-breadcrumb'>
        <Col>
          <div>
            <MdKeyboardBackspace
              className='BackIcon'
               onClick={() => history.goBack()}
            ></MdKeyboardBackspace>
          </div>
          <div className='breadcrumb-div'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/user' className="homeIcon-svg"><div className="homeIcon-svg"></div></Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='col-sub-header' id='col-edit'>
        {title}
        </Col>
        <HorizontalDivider color='#5277ac' />
      </Row>
      {/* <Form onSubmit={handleSubmit}> */}
      <Stack direction='vertical' gap={5}>
        <div>
          <CommonAccordian
            data={{
              title: 'User Profile',
              subtitle: 'User Information',
              eventKey: '0',
            }}
          >
            <IpProfileInfoStyle onChange={(heading)=>setTitle(heading)} isKannada={false}></IpProfileInfoStyle>
          </CommonAccordian>
        </div>
      </Stack>

      {/* </Form> */}
    </>
  );
}

export default UserProfileView;
