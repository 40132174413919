import React from "react";
import { Container } from "react-bootstrap";
import "../../SCSS/Views/Admin.scss";
import Footbar from "./../Footbar/Footbar";
import Topbar from "./../Topbar/Topbar";

const AdminPage = ({ children }) => {
  return (
    <>
      <div className="page-children">{children}</div>
      <Footbar></Footbar>
    </>
  )
}

export default AdminPage;
