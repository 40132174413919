export const claimTabledata = [
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915401",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915402",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Submitted",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915403",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Madhu",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Rejected",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915404",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Recieved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915405",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Document verified",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915406",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915407",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915408",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915409",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915410",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915411",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915412",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915413",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
  {
    claimUuid: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    partyId: 55,
    ipNumber: 999989999,
    dispenseryId: 2,
    claimRefNum: "C999915474",
    sakalaNumber: "SAKALA0.23595852",
    appliedClaimAmount: 99899,
    patientId: 46,
    esiHospitalReferral: true,
    treatmentFrom: "2021-02-12",
    treatmentTo: "2021-12-15",
    createdBy: "999989999",
    updatedBy: "Abhishek",
    createdTimestamp: "2021-12-15T14:45:03.587",
    updatedTimestamp: "2021-12-15T14:45:38.953",
    claimStatus: "Approved",
    patientName: "999989999",
    relationshipType: "Self",
    dependentDob: null,
    dependentStatus: "1",
    dependentAge: 0,
    approvedAmount: 99899,
  },
];
