import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../../_services';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        const currentUserToken = authenticationService.currentUserToken;
        const findCommonElement = (array1, array2) => {
            for (let i = 0; i < array1.length; i++) {
                for (let j = 0; j < array2.length; j++) {
                    if (array1[i] === array2[j]) {
                        return true;
                    }
                }
            }
            return false;
        }
        if (!currentUser && !currentUserToken) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        // check if route is restricted by role

        

        if (roles && !findCommonElement(roles, currentUser.authorites)) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/'}} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)
