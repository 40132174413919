import { Button, Container, Form, Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { Link } from "react-router-dom";
export const ApprovedModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form className="approved-modal">
          <ImCross className="imcross" onClick={props.onHide} />
          <Modal.Header>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <FaCheck size={70} className="facheck-icon" />
              <br />
              <div className="approved-text">
                <p className="approved-subtext">
                  <span style={{ fontWeight: "bold", textAlign: "center" }}>
                    Received
                  </span>
                </p>
              </div>
            </Container>
          </Modal.Body>
          <Link to="/admin">
            <Button onClick={props.onHide} className="approved-button">
              OK
            </Button>
          </Link>
        </Form>
      </Modal>
    </>
  )
}
