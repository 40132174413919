import React, { useState } from "react";
import * as yup from "yup";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import { AiOutlineWarning } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { DependantService } from "../../../_services/DependantService";
import TextError from "../../../CommonComponents/TextError";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import EsiDatePicker from "../../../CommonComponents/EsiDatePicker";

export const ConfirmToggleStateModel = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Confirm State</Modal.Title>

            <ImCross className="modal-im-cross" onClick={props.onHide} />
          </Modal.Header>
          <Modal.Body className="confirm-modal-body">
          Do you agree to retain selection?
          </Modal.Body>
          <Modal.Footer>
            <Button className="esi-confirm-modal-button" onClick={props.saveChanges}>
              YES
            </Button>
            <Button
              className="esi-confirm-modal-cancel-button"
              onClick={props.onHide}
            >
              NO
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};
