import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Stack, Dropdown, DropdownButton } from "react-bootstrap";
import DataTable from "react-data-table-component-footer";

import { MdKeyboardBackspace } from 'react-icons/md';

import sobLatest from "../../../assests/svg/sob-icon.svg";
import folder from "../../../assests/svg/folder.svg";
import arrow from "../../../assests/svg/group-by-white.svg";
import downloadIcon from "../../../assests/svg/csv.svg";
import editRound from "../../../assests/svg/edit-round.svg";
import expand from "../../../assests/svg/expand.svg";

import { Nav, Navbar, Container } from "react-bootstrap";
import { PcClaimService } from "../../../_services/PcClaimService";
import _ from 'lodash';
import { ViewSOBTable } from "./ViewSOBTable";
import { AddSOB } from "./AddSOB";
import Moment from "react-moment";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import { formatDateAndTime } from "../../../_helpers/date-format";

const formatDate = (input) => {
    const date = new Date(input);

    const formattedDate = date instanceof Date && !isNaN(date) ? date.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
    }):"";

    return formattedDate.replaceAll('/', '-');
}

const numWords = require("num-words");

export const VersionHistorySOB = (props) => {
    const { data, children } = props;
    const [sobList, setSOBList] = useState([]);
    const [activeSOBList, setActiveSOBList] = useState([]);
    const [versionHistory, setVersionHistory] = useState();

    const [keys, setKeys] = useState();
    const [value, setValue] = useState('Name');

    const [showIndex, setShowIndexView] = useState(-1);
    const [editCurrent, setEditCurrent] = useState(false);
    const [viewList, setViewList] = useState([]);
    const [showButton, setShowButton] = useState(false);

    const [loaderStatus, setLoaderStatus] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [i, setIndex] = useState();
    const [dataColumns, setDataColumns] = useState([]);
    const [showViewTable, setShowViewTable] = useState(false);

    const nameColumns = [
        {
            name: "SOB",
            cell: (row,index) => <div> <img src={sobLatest} style={{width: "22px", cursor:"pointer"}} onClick={() => setShowIndexView(index)}/></div>,
            center: true,
            maxWidth: "5%"    
        },
        {
            name: "Eligible Amount",
            cell: (row,index) => '₹'+ row.approvedAmount,
            sortable: true,
            style: {fontWeight: 'bold'},
            right: true
        },
        {
            name: "Generated Date",
            selector: 'createDate',
            sortable: true,
            center: true
        }
    ]

    const dateColumns = [
        {
            name: "SOB",
            cell: (row,index) => <div> <img src={sobLatest} style={{width: "22px", cursor: "pointer"}} onClick={() => setShowIndexView(index)}/></div>,
            center: true,
            maxWidth: "5%"    
        },
        {
            name: "Eligible Amount",
            cell: (row,index) => '₹'+ row.approvedAmount,
            sortable: true,
            style: {fontWeight: 'bold'},
            right: true
        },
        {
            name: "Generated By",
            selector: 'createdBy',
            sortable: true,
            center: true
        }
    ]
    const [leftColumns, setLeftColumns] = useState(nameColumns);
    const getListBasedOnName = (e) => {
        let namesList = _.groupBy(e, 'createdBy')
        let names = Object.keys(namesList);
        setKeys(names);
        setVersionHistory(namesList);
    }

    const getListBasedOnDate = (e) => {
        let dateList = _(e).groupBy(elem => formatDate(elem.createdTimeStamp)).value();
        let names = Object.keys(dateList);
        setKeys(names);
        setVersionHistory(dateList);
    }

    const filterArray = (e) => {
        if (e == 'Name') {
            getListBasedOnName(sobList)
            setLeftColumns(nameColumns)
        }else{
            getListBasedOnDate(sobList)
            setLeftColumns(dateColumns)
        }
    }

    const goBack = () => {
        setShowIndexView(-1);
        setDataColumns([]);
    }

    const handleSelect = (e) => {
        setValue(e)
        filterArray(e)
        goBack()
    }

    async function getClaimVersionHistory() {
        setLoaderStatus(true);
        await PcClaimService.getSOBList(data.claim.claimUuid).then((res) => {
            if (res.length > 0) {
                setSOBList(res);
                res.map((sob) => {
                    if (sob.active) {
                        setActiveSOBList(sob.bills);
                    }
                })
                getListBasedOnName(res);
                setLoaderStatus(false);
            }
        })
    }

    const calculateBill = () => {
        if (selectedOption != undefined && showIndex != -1) {
            let total = _.sum(_.map(selectedOption[showIndex].bills, d => d.billAmount))
            return total;
        }
    }

    const calculateApproved = () => {
        if (selectedOption != undefined && showIndex != -1) {
            let total = _.sum(_.map(selectedOption[showIndex].bills, d => d.approvedAmount))
            return total;
        }
    }

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#d3d3d3",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
                //width: "99.4%"
            }
        },
        headCells: {
            style: {
                fontSize: "14px !important",
                fontWeight: "bold !important",
                backgroundColor: "#d3d3d3",
                //borderRight: "1px solid #172d66",
            }
        },
        cells: {
            style: {
                fontSize: "13px",
                borderBottom: "1px solid #d3d3d3",
                //borderRight: "1px solid #172d66"
            }
        }
    }

    const style = {
        headCells: {
            style: {
                fontSize: "14px !important",
                fontWeight: "bold !important"
            }
        }
    }
    
    const columns = [
        {
            name: "Sl No",
            selector: 'id',
            center: true,
            sortable: true,
            maxWidth: "5%"
        },
        {
            name: "Bill Number",
            selector: 'billNumber',
            center: true,
            maxWidth: "15%"
        },
        {
            name: "Bill Date",
            selector:'billDate',
            cell: (row) =>  formatDate(row.billDate),
            center: true,
            maxWidth: "15%"
        },
        {
            name: "Description",
            selector: 'description',
            center: true,
            maxWidth: "25%"
        },
        {
            name: "Bill Amount",
            selector: "billAmount",
            right: true,
            maxWidth: "15%"
        },
        {
            name: "Eligible Amount",
            selector: "approvedAmount",
            right: true,
            maxWidth: "25% "
        }
    ]

    const footer = {
        billDate: "",
        description: "Total",
        billAmount: '₹' + calculateBill(),
        approvedAmount: '₹' + calculateApproved()
    }

    useEffect(() => {
        getClaimVersionHistory();
    }, []);

    const getNumtoWords = (amount) => {
        try {
            return numWords(parseFloat(amount)) + " Rupees only";
        } catch (e) {
            return "Invalid Data";
        }
    };

    const prepShowDetails = (key) => {
        setIndex(key);
        var arr = versionHistory[key];
        setSelectedOption(arr);
        let leftData = [];
        if(arr){
            
                arr.map((val) => {
                    let bills = val.bills;
                    let amount = _.sum(_.map(val.bills, d => d.approvedAmount))
                    
                    if(value == "Name"){
                        leftData.push({
                            approvedAmount: amount,
                            createDate: formatDateAndTime(val.createdTimeStamp)
                        })
                    }else if(value == "Date"){
                        leftData.push({
                            approvedAmount: amount,
                            createdBy: val.createdBy
                        })
                    }
                })
                setDataColumns(leftData);
        }  
    }
    const setViewTable = (value) => {
        if (value == "CURRENT") {
            setViewList(selectedOption[showIndex].bills)
            setShowButton(true);
            setShowViewTable(true);
        } else if (value == "LATEST") {
            setViewList(activeSOBList);
            setShowViewTable(true);
        }
    }

    const handleCancel = () => {
        setShowViewTable(false);  
        setEditCurrent(false);  
    }

    async function handleSubmit() {
        setLoaderStatus(true);
        setEditCurrent(false);
        await PcClaimService.getSOBList(data.claim.claimUuid).then((res) => {
            if (res.length > 0) {
                res.map((sob) => {
                    if (sob.active) {
                        setViewList(sob.bills);
                        setShowViewTable(true);
                        setLoaderStatus(false);
                    }
                })
            }
        });
    }

    return (
        <>
            {loaderStatus ? <EsiLoader /> : null}
            {editCurrent? <AddSOB data= {{sobList: selectedOption[showIndex].bills, edit: 100, claim: data.claim, setSOBList}} handleCancel={handleCancel} handleSubmit={ handleSubmit }/> :
                 (showViewTable || viewList?.length > 0) ? <ViewSOBTable data={{ sobList: viewList, latest: activeSOBList, showButton:showButton, claim: data.claim, setSOBList}}/> :
            <div>
                <Row className="pc-sob-stack">
                    <Col style={{flex:'2', maxWidth: 'fit-content'}}>
                        <div className="pc-sob-label">Total Eligible Amount: {data.approvedAmount}<span className="currency-color">₹</span> </div>
                        <div className="pc-sob-words">({getNumtoWords(data.approvedAmount)})</div>
                    </Col>
                    <Col style={{flex:'2', maxWidth: 'fit-content'}}>
                        <div className="pc-sob-label">Total Bill Amount: {data.billAmount}<span className="currency-color">₹</span></div>                            
                        <div className="pc-sob-words">({getNumtoWords(data.billAmount)})</div>
                    </Col>
                    <Col style={{flex:'1'}}>
                        <div className="pc-sob-label">Total Bills: {data.bills} </div>
                        <div></div>
                    </Col>  
                    <Col className="ms-auto"></Col>
                    {(showIndex != -1)? <>
                    <Col md={1} className="icon-content-button">
                        <Button className="sob-icon-button float-right"><img src={sobLatest} style={{width:"32px"}} onClick={() => setViewTable("LATEST")}/>
                        <label className="button-text">LATEST SOB</label></Button>
                    </Col>
                    <Col md={1} className="icon-content-button">
                        <Button className="sob-icon-button float-right"><img src={downloadIcon} style={{width:"35px"}}/>
                        <label className="button-text">DOWNLOAD</label></Button>  
                    </Col>
                    <Col md={1} className="icon-content-button">
                        <Button className="sob-icon-button float-right"><img src={editRound} style={{width:"45px"}} onClick={() => setEditCurrent(true)} />
                        <label className="button-text">EDIT BILLS</label></Button>
                    </Col>
                    </> : 
                        <Col className="icon-content-button">
                            <Button className="sob-icon-button float-right" ><img src={sobLatest} style={{width:'30px',cursor:"pointer"}} onClick={() => setViewTable("LATEST")}/> 
                            <label className="button-text">LATEST SOB</label></Button>
                        </Col>
                    }
                </Row>
                <Navbar expand="lg" className="navbar-container">
                    <Container style={{maxWidth: '100%'}}>
                        <Navbar.Brand href="#" className="navbar-title">Version History</Navbar.Brand>
                        <Navbar.Collapse id="navbar-dark-example">
                        <Nav className="ms-auto">
                            <label className="navbar-label">Group By : {value}</label>
                            <DropdownButton className="arrow-button"
                                alignRight
                                title={
                                    <img src={arrow} style={{width: '30px'}}/>
                                }
                                id="dropdown-menu-align-right"
                                onSelect={handleSelect}
                                    >
                                        <Dropdown.Item eventKey="Name">Name</Dropdown.Item>
                                        <Dropdown.Item eventKey="Date">Date</Dropdown.Item>
                            </DropdownButton>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className="container-body">
                    <Row className="">
                    {(dataColumns && dataColumns.length > 0)? 
                        <div  style = {{ width: '35%', padding: "0"}}>
                            <MdKeyboardBackspace
                                className='BackIcon'
                                style= {{fontSize: '30px'}}
                                onClick={() => goBack()}
                            ></MdKeyboardBackspace><span className="sob-back-button">Back</span>
                          <DataTable style={{width: '99%'}}
                              columns = {leftColumns} 
                              data = {dataColumns}
                              customStyles = {style}
                              fixedHeader = {true}
                              highlightOnHover
                              fixedHeaderScrollHeight ="20vh"/>
                      </div>  
                            :
                        <Col md={4}>
                            <Row style={{maxHeight: '35vh', overflowY: 'scroll' }}>
                                {(keys != undefined) ? keys.map((key, index) => {
                                    return(
                                    <Col md={3}>
                                    <Stack direction="vertical">
                                        <div>
                                            <img src={folder} style={{width:'70px', margin:'0.5rem', cursor:"pointer"}} onClick = {() => prepShowDetails(key)}/>
                                        </div>
                                        <div style={{fontSize: '12px', fontWeight: 'bold', textAlign: 'center'}}>{key}</div> 
                                    </Stack> </Col>)

                                            }) : <div></div>}
                                        </Row>
                                    </Col>
                                }
                                {(showIndex != -1) ?
                                    <Col className="table-container" style={{ width: '65%' }}>
                                        <div style={{ float: 'right', paddingBottom: '0.5rem', paddingRight: '0.5rem' }}><img src={expand} style={{ width: '20px',cursor:"pointer" }} onClick={() => setViewTable("CURRENT")} /></div>
                                        <DataTable className="view-sob-table"
                                            columns={columns}
                                            data={selectedOption[showIndex].bills}
                                            customStyles={customStyles}
                                            footer={footer}
                                            fixedHeader={true}
                                            fixedHeaderScrollHeight="22vh" />
                                    </Col>
                                    :
                                    <Col>
                                        <div className="right-section title">Preview Statement of bills</div>
                                        <p className="right-section subtitle">No bills selected</p>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
            }
        </>
    )
};