import React from 'react';
import { useEffect, useState } from 'react';
import {
    Breadcrumb,
    Col,
    Container,
    Row,
    Form,
    Stack
} from 'react-bootstrap';
import * as yup from "yup";

import { MdKeyboardBackspace } from 'react-icons/md';
import { useHistory, Link } from 'react-router-dom';
import '../../SCSS/Views/AddNewClaim.scss';
import '../../SCSS/Views/Topbar.scss';
import { HorizontalDivider } from '../../CommonComponents/HorizontalDivider';
import Button from 'react-bootstrap/Button';
import { esiAdminService } from "../../_services/esiAdminService";
import TextError from "../../CommonComponents/TextError";
import { CommonAccordian } from "../../CommonComponents/CommonAccordian";
import { Formik } from "formik";

import { string } from 'yup';


const AdminHomePage = () => {

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [userId, setUserId] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [radio, setRadio] = useState("single");
    const [confirmPassword, setConfirmpassword] = useState("");


    const history = useHistory();

    useEffect(() => {

    }, []);

    const schema = yup.object().shape({
        password: yup
            .string().required("Please Enter Password")
            .max(20, "Password length must be upto 20 characters")
            .min(8, "Password length must be atleast 8 characters"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required("Please confirm your password")
    });

    useEffect(() => {
        getAllRoles();
        getUsers();
    }, []);

    const getAllRoles = async () => {
        setRoleList(await esiAdminService.getRoles());
    };

    const getUsers = async () => {
        setUsersList(await esiAdminService.getUsers());
    };

    const handleFormSubmit = async (e) => {

    };

    const roleUpdate = async (e) => {
        setLoaderStatus(true);
        const requestData = {
            userId: userId,
            role: role
        };
        await esiAdminService.updateRole(requestData).then(
            (response) => {
                console.log(response);
                if (response == true) {
                    setLoaderStatus(false);
                    alert("Role updated successfully...");
                } else {
                    setLoaderStatus(false);
                    alert("Role update failed!!");
                }
            },
            (error) => {
                console.error(error);
                setShowError(true);
                setLoaderStatus(false);
                alert("Role update failed!!" + error);
            }
        );
    }

    const passwordUpdate = async (e) => {
        setLoaderStatus(true);
        const requestData = {
            userId: userId,
            role: role
        };
        await esiAdminService.updatePassword(requestData).then(
            (response) => {
                console.log(response);
                if (response == true) {
                    setLoaderStatus(false);
                    alert("password updated successfully...");
                } else {
                    setLoaderStatus(false);
                    alert("password update failed!!");
                }
            },
            (error) => {
                console.error(error);
                setShowError(true);
                setLoaderStatus(false);
                alert("password update failed!!" + error);
            }
        );
    }

    const handleRoleUpdate = async () => {
        history.push("/admin/roleupdate");
    };
    const handlePasswordUpdate = async () => {
        history.push("/admin/passwordupdate");
    };

    const handleChange = (e) => {
        let files = e.target.files;
        let reader = new FileReader();
        const missingProps = [];
        reader.readAsText(files[0]);


        reader.onload = (e) => {
            console.warn("obj data : ", e.target.result);
            let fileData = e.target.result;
            localStorage.setItem("fileData", fileData);
            function checkProperties(property, object) {
                property in object ? console.log('Child detected') : missingProps.push(property);
            }

        }
    }

    const single = async (e) => {
        setLoaderStatus(true);
        if (radio === "single") {
            try {
                var myObject = JSON.parse(localStorage.getItem("fileData"))

                if (myObject == null) {
                    e.preventDefault();
                    setShowError(true);
                    setLoaderStatus(false);
                    setErrorMessage("Invalid File...");
                } else {
                    localStorage.removeItem("fileData");
                    await esiAdminService.createSingleUser({ data: myObject }).then(
                        (response) => {
                            if (myObject != null && response == response) {
                                setLoaderStatus(false);
                                setSuccessMessage(true)
                            }

                            if (response.message == null) {
                                setLoaderStatus(false);
                            }
                            else {
                                setLoaderStatus(false);
                                setShowError(true);
                                setErrorMessage("User Creation failed/ user already exist");
                            }
                        },
                        (error) => {
                            console.error(error);
                            setLoaderStatus(false);
                            setShowError(true);
                            setErrorMessage("User Creation failed/ user already exist");
                        }
                    );

                }
            }
            catch {
                alert("Please check for the JSON syntax or upload a valid JSON file");

            }
        }
    }

    const multiple = async (e) => {
        setLoaderStatus(true);
        if (radio === "multiple") {
            try {
                var myObject = JSON.parse(localStorage.getItem("fileData"))
                if (myObject == null) {
                    e.preventDefault();
                    setShowError(true);
                    setLoaderStatus(false);
                    setErrorMessage("Invalid File...");
                } else {
                    localStorage.removeItem("fileData");
                    await esiAdminService.createMultipleUser({ data: myObject }).then(
                        (response) => {
                            if (myObject != null && response == response) {
                                e.preventDefault();
                                setLoaderStatus(false);
                                setSuccessMessage(true)
                            }

                            if (response.message == null) {
                                setLoaderStatus(false);
                            }
                            else {
                                setLoaderStatus(false);
                                setShowError(true);
                                setErrorMessage("User Creation failed/ user already exist");
                            }
                        },
                        (error) => {
                            console.error(error);
                            setLoaderStatus(false);
                            setShowError(true);
                            setErrorMessage("User Creation failed/ user already exist");
                        }
                    );
                }

            }
            catch {
                alert("Please check for the JSON syntax or upload a valid JSON file");

            }
        }
    }

    return (
        <>

            <Stack direction='vertical' gap={5}>

                <Row>
                    <Col className='col-sub-header' id='col-edit'>
                        User Management
                    </Col>
                    <HorizontalDivider color='#5277ac' />
                </Row>
                <Row className="ip-profile-info-row">
                    <CommonAccordian
                        data={{
                            title: "Create New User(s)",
                            eventKey: "0",
                        }}
                    >
                        {showError && (
                            <TextError>
                                <b>
                                    <p key="error" variant="danger">
                                        {errorMessage}
                                    </p>
                                </b>
                            </TextError>
                        )}

                        {successMessage && (
                            <TextError>
                                <b>
                                    <p style={{ color: "green" }}>
                                        File Uploaded Sucessfully!!
                                    </p>
                                </b>
                            </TextError>
                        )}
                        <Row>
                            <Col >
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Select JSON File</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="file"
                                        onChange={(e) => handleChange(e)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div style={{ margin: "0 auto", width: "60%" }}>
                                    <label style={{ margin: "2px 0px 10px" }}>Select type of user to upload the file</label><br />
                                    <input type="radio"
                                        className="form-check-input"
                                        style={{ margin: "5px" }}
                                        name="json"
                                        checked={radio === "single"}
                                        onClick={() => setRadio('single')}
                                    />
                                    Single User
                                    <br />
                                    <input type="radio"
                                        style={{ margin: "5px" }}
                                        name="json"
                                        value="multiple"
                                        className="form-check-input"
                                        checked={radio === "multiple"}
                                        onClick={() => setRadio('multiple')} />
                                    Multiple User
                                </div>
                            </Col>
                            <Col>
                                <div class="text-center">
                                    <Button
                                        variant="primary"
                                        className="esi-navy-blue-button-lg"

                                        onClick={e => { single(); multiple() }}
                                    >CREATE USERS</Button>

                                </div>
                            </Col>
                        </Row>
                        <div>This link can be used to validate your JSON data <a href="https://jsonformatter.org/">JSON Formatter</a></div>
                    </CommonAccordian>
                </Row>

                <Row className="ip-profile-info-row">
                    <CommonAccordian
                        data={{
                            title: "Role Update",
                            eventKey: "0",
                        }}
                    >
                        <Row>
                            <div style={{}}>
                                <div style={{
                                }}>
                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={roleUpdate}
                                        initialValues={{
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            values,
                                            isValid,
                                            isSubmitting,
                                        }) => (
                                            <Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "row", }}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-2" controlId="formBasicName">
                                                            <Form.Label>Employee Name</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="user"
                                                                className="select-dropdowns"
                                                                style={{ width: "480px" }}
                                                                value={values.user}
                                                                onChange={(e) => { setUserId(e.target.value) }}
                                                            >
                                                                <option value="" defaultValue >
                                                                    Select Employee Name
                                                                </option>
                                                                {usersList.map(
                                                                    (users) =>
                                                                        <option value={users.id}>{users.username}</option>
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicName">
                                                            <Form.Label>Employee Roles</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                as="select"
                                                                name="role"
                                                                className="select-dropdowns"
                                                                style={{ width: "480px" }}
                                                                value={values.role}
                                                                onChange={(e) => { setRole(e.target.value) }}
                                                            >
                                                                <option value="" defaultValue >
                                                                    Select Employee Roles
                                                                </option>
                                                                {roleList.map(
                                                                    (roles) =>
                                                                        <option value={roles}>{roles}</option>
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div>&nbsp;</div>
                                                <Button
                                                    style={{ display: "block", margin: "auto" }}
                                                    variant="primary"
                                                    className="esi-navy-blue-button-lg"
                                                    type="submit"
                                                    disabled={!userId || !role}
                                                    onClick={roleUpdate}
                                                >
                                                    UPDATE ROLE
                                                </Button>

                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </Row>
                    </CommonAccordian>
                </Row>

                <Row className="ip-profile-info-row">
                    <CommonAccordian
                        data={{
                            title: "Password Update",
                            eventKey: "0",
                        }}
                    >
                        <Row>

                            <div style={{}}>
                                <div style={{

                                }}>
                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={passwordUpdate}
                                        initialValues={{
                                            password: "",
                                            confirmPassword: ""
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                            isSubmitting,
                                        }) => (
                                            <Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "row", }}>
                                                <Container>

                                                    <Row style={{ marginLeft: "-100px" }}>
                                                        <Col>
                                                            <Form.Group className="mb-2" controlId="formBasicName">
                                                                <Form.Label>Employee Name</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    name="roles"
                                                                    className="select-dropdowns"
                                                                    style={{ width: "332px" }}


                                                                    onChange={(e) => { setUserId(e.target.value) }}
                                                                >
                                                                    <option value="" defaultValue >
                                                                        Select Employee Name
                                                                    </option>
                                                                    {usersList.map(
                                                                        (users) =>
                                                                            <option value={users.id}>{users.username}</option>
                                                                    )}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                <Form.Label>Enter New Password</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="password"

                                                                    style={{ width: "332px" }}
                                                                    value={password}
                                                                    onChange={(e) => { setPassword(e.target.value); handleChange(e) }}
                                                                    onBlur={handleBlur}
                                                                    isValid={touched.password && !errors.password}
                                                                    isInvalid={!!errors.password}
                                                                    placeholder="Enter your Password"
                                                                    onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                                                                    maxLength={20} >
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                                <Form.Label>Confirm New Password</Form.Label>
                                                                <Form.Control
                                                                    type="password"
                                                                    name="confirmPassword"
                                                                    style={{ width: "332px" }}
                                                                    value={confirmPassword}
                                                                    onChange={(e) => { setConfirmpassword(e.target.value); handleChange(e) }}
                                                                    onBlur={handleBlur}
                                                                    isValid={touched.confirmPassword && !errors.confirmPassword}
                                                                    isInvalid={!!errors.confirmPassword}
                                                                    placeholder="Confirm your password"
                                                                    onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                                                                    maxLength={20}
                                                                >

                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <div>&nbsp;</div>
                                                            <Button
                                                                style={{ display: "block", margin: "auto" }}
                                                                variant="primary"
                                                                type="submit"
                                                                className="esi-navy-blue-button-lg"
                                                                disabled={!values.password || !values.confirmPassword || !userId}
                                                            >
                                                                UPDATE PASSWORD
                                                            </Button>
                                                        </Col>
                                                    </Row>



                                                </Container>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </Row>
                    </CommonAccordian>
                </Row>
            </Stack>
        </>
    );
}

export default AdminHomePage;
