import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { Redirect, useHistory } from "react-router-dom";
import * as yup from "yup";
import "../SCSS/CommonComponentsCSS/Modal.scss";
import { DependantService } from "../_services/DependantService";
import EsiDatePicker from "./EsiDatePicker";
import { EsiLoader } from "./EsiLoader";

export const DependentModal = (props) => {
    const [birthDate, setBirthDate] = useState(null);
  const [name, setName] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [relationshipData, setRelationshipData] = useState([]);
  const [patientUhid, setPatientUhid] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getAllRelations();
  }, []);

  const requestData = {};

  const getAllRelations = async () => {
    setRelationshipData(await DependantService.getRelationships());
  };

  const callCreateDependant = async (e) => {
    setLoaderStatus(true);
    setName(e.name);
    setRelationship(e.relationshipType);
    setPatientUhid(e.patientUhid);
    let dependantRes;
    if (!props.data.rowData) {
      dependantRes = await DependantService.createDependant({
        dob: convertDate(e.birthDate),
        dependentName: e.name,
        relationshipType: e.relationshipType,
        patientUhid: e.patientUhid,
      });
    } else {
      dependantRes = await DependantService.createDependant(
        getUpdateRequestData(props.data.rowData, e)
      );
    }
    props.onHide(dependantRes);
    getAllRelations();
    setLoaderStatus(false);
    return <Redirect to={"/user"} />;
  };
  const handleBirthDate = (date) => {
    setBirthDate(date);
  };

  function getUpdateRequestData(rowData, e) {
    return {
      id: rowData.id,
      partyId: rowData.partyId,
      dob: convertDate(e.birthDate),
      dependentName: e.name,
      relationshipType: e.relationshipType,
      patientUhid: e.patientUhid,
    };
  }

  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, "50 Chars Only")
      .matches(/^[ A-Za-z]*$/, "Only alphabets are allowed for this field ")
      .required("Patient's Name is Mandatory"),
    birthDate: yup.date().required("Please Select Birth Date").nullable(),
    relationshipType: yup.string().required("Select a Relationship Type"),
    patientUhid: yup
      .string()
      .trim()
      .min(15, "15 Characters Only")
      .max(15, "15 Characters Only")
      .required("Patient UHID is required")
      .matches(/^[ A-Za-z0-9]*$/, "No special characters are allowed")
      .nullable(),
  });

  return (
    <>
      {" "}
      {loaderStatus ? <EsiLoader /> : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-container"
      >
        <Formik
          validationSchema={schema}
          onSubmit={callCreateDependant}
          initialValues={{
            name: !props.data.rowData ? "" : props.data.rowData.dependentName,
            birthDate: !props.data.rowData
              ? new Date()
              : new Date(props.data.rowData.dob),
            relationshipType: !props.data.rowData
              ? ""
              : props.data.rowData.relationshipType,
            patientUhid: !props.data.rowData
              ? ""
              : props.data.rowData.patientUhid,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header>
                {/* <button type="button" class="btn-close" aria-label="Close"></button> */}
                <Modal.Title id="contained-modal-title-vcenter">
                  {props.data.title}
                </Modal.Title>
                <ImCross className="modal-im-cross" onClick={props.onCancel} />
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md lg={6}>
                      <Form.Group className="mb-2" controlId="formBasicName">
                        <Form.Label>Dependent Name</Form.Label>
                        <Form.Control
                          name="name"
                          className="add-dependant"
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name && !errors.name}
                          isInvalid={!!errors.name}
                          maxLength={50}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicRelation"
                      >
                        <Form.Label>Relationship</Form.Label>
                        {values.relationshipType.toUpperCase() !==
                          "self".toUpperCase() ? (
                          <Form.Control
                            as="select"
                            name="relationshipType"
                            className="select-dropdowns"
                            value={values.relationshipType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // isValid={
                            // touched.relationshipType &&
                            // !errors.relationshipType
                            // }
                            isInvalid={!!errors.relationshipType}
                          >
                            <option value=""defaultValue >
                              Select Relationship
                            </option>
                            {relationshipData.map(
                              (relation) =>
                                !(
                                  relation.toUpperCase() === 
                                  "self".toUpperCase()
                                ) && (
                                  <option value={relation}>{relation}</option>
                                )
                            )}
                            
                            readOnly =
                            {values.relationshipType === "Self" ? true : false}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="relationshipType"
                            className="add-dependant"
                            type="text"
                            value="Self"
                            readOnly
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.relationshipType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicDob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Group>
                        <Field 
                        type="date"
                        className="form-control"
                        value={convertDate(values.birthDate)}
                        max={convertDate(new Date())}
                        name="birthDate"
                        ></Field>
                          {/* <EsiDatePicker
                            maxDate={new Date()}
                            name="birthDate"
                          /> */}
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicpatientUhid"
                      >
                        <Form.Label>Dependant's UHID</Form.Label>
                        <Form.Control
                          type="text"
                          name="patientUhid"
                          onChange={handleChange}
                          value={values.patientUhid}
                          className="add-dependant"
                          isValid={touched.patientUhid && !errors.patientUhid}
                          isInvalid={!!errors.patientUhid}
                          maxLength={15}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.patientUhid}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Button
                type="submit"
                // onClick={callCreateDependant}
                className="esi-modal-blue-button"
                disabled={!isValid || isSubmitting}
              >
                SUBMIT
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

function convertDate(date) {
  var forDate = new Date(date);
  var dd = forDate.getDate();
  var mm = forDate.getMonth() + 1;
  var yyyy = forDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var datestring = yyyy + "-" + mm + "-" + dd;
  return datestring;
}
