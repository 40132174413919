import '../SCSS/CommonComponentsCSS/EmployeeProfileInfoStyle.scss';
import { Formik } from 'formik';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaTicketAlt } from 'react-icons/fa';
import * as yup from 'yup';
import { FaRegUserCircle } from 'react-icons/fa';
import { FaMale } from 'react-icons/fa';
import { FaFemale } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { RiEditBoxLine } from 'react-icons/ri';
import { attachmentListUserInfo } from '../Views/User/Data/Attachment_List';
import { DependantService } from '../_services/DependantService';
import { EsiLoader } from './EsiLoader';
import Badge from 'react-bootstrap/Badge';
import { BsFillCheckCircleFill } from 'react-icons/bs';

export const EmployeeProfileInfoStyle = (props) => {
  const { isKannada, ...rest } = props;
  const [empInfo, setEmpInfo] = useState({});
  const [markReadOnly, setMarkReadOnly] = useState(true);
  const [editUserInfo, setEditUserInfo] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [gend, setGend] = useState('');
  const [listOfDispensaries, setListOfDispensaries] = useState();

  useEffect(() => {
    getUserInfo();
  }, []);

  const [maleCheck, setMaleCheck] = useState(false);
  const [femaleCheck, setFemaleCheck] = useState(false);

  const updateEmpGender = (gen) => {
    console.log(gen);
    if (gen === 'MALE') {
      setMaleCheck(true);
      setFemaleCheck(false);
    } else {
      setMaleCheck(false);
      setFemaleCheck(true);
    }
    setGend(gen);
  };

  const getUserInfo = async () => {
    setLoaderStatus(true);
    props.onChange('User Profile');
    const partId = JSON.parse(localStorage.currentUser).partyId;
    const result = await Promise.all([
      DependantService.getUserInfo(partId),
      DependantService.getDispensaries(partId),
    ]);
    const userInfo = result[0];
    const dispensaries = result[1];
    const dispensaryNames = dispensaries.map((dispensary) => dispensary.name);
    userInfo.dispensaries = dispensaryNames;
    setEmpInfo(userInfo);
    updateEmpGender(userInfo.gender);
    setLoaderStatus(false);
    setListOfDispensaries(dispensaryNames);
  };

  const updateUserInfo = async (e) => {
    setLoaderStatus(true);
    const requestData = {
      id: e.id,
      name: e.name,
      address: e.address,
      contactNum: e.contactNum,
      role: e.role,
      gender: gend,
    };
    console.log(requestData);
    await DependantService.updateUserInfo(requestData).then(
      (res) => {
        getUserInfo();
        setEmpInfo(res);
        setEditUserInfo(false);
        setMarkReadOnly(true);
      },
      (error) => {
        setMarkReadOnly(true);
      }
    );
  };

  const allowEdit = async () => {
    setMarkReadOnly(false);
    setEditUserInfo(true);
    props.onChange('Edit Profile');
  };

  const handleClose = async () => {
    setMarkReadOnly(true);
    setEditUserInfo(false);
    setEmpInfo({});
    getUserInfo();
    props.onChange('User Profile');
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .max(50, '50 Characters Only')
      .matches(/^[ A-Za-z]*$/, 'Only alphabets are allowed for this field ')
      .required(),
    role: yup
      .string()
      .max(50, '50 Characters Only')
      .matches(/^[ A-Za-z_]*$/, 'Only alphabets are allowed for this field ')
      .required(),
    contactNum: yup
      .number()
      .min(1000000000, 'Min 10 Digits')
      .max(9999999999, '10 digits only.')
      .required(),
    address: yup.string().max(250, '250 Characters Only.').nullable().matches(/^[A-Za-z0-9 ,.-]+$/, "No special characters allowed"),
  });

  const valid_numbers = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }
  };
  const valid_alphabet = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 65 && key <= 90) {
      return true;
    }
    else if(key >=97 && key<= 122 ){
      return true
    }
    // to check whether pressed key is alphabet or not
    else {
      //alert("Enter only Alphabet");
      e.preventDefault();
      return false;
    }
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Row className='new-claim-row'>
        <Col xs={3}>
          <Row className='user-icon'>
            <Col align="center">
              <FaRegUserCircle size={110} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div align='center'>Employee ID: {empInfo.id}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                {editUserInfo ? (
                  <>
                    <Col xs={3}></Col>
                    <Col xs={3}>
                      <div
                        className='edit-gender'
                        onClick={(e) => updateEmpGender('MALE')}
                      >
                        <div className="check-circle">
                          {maleCheck && <BsFillCheckCircleFill />}
                        </div>
                        <div>
                          <FaMale size={80} />
                          <div className='edit-male-text'>Male</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div
                        className='edit-gender'
                        onClick={(e) => updateEmpGender('FEMALE')}
                      >
                        <div className="check-circle">
                          {femaleCheck && <BsFillCheckCircleFill />}
                        </div>
                        <div>
                          <FaFemale size={80} />
                          <div className='edit-female-text'>Female</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}></Col>
                  </>
                ) : (
                  <>
                    <Col align='center'>
                      {gend === 'MALE' ? (
                        <>
                          <div className='male'>
                            <FaMale size={80} />
                          </div>
                          <div>Male</div>
                        </>
                      ) : (
                        <>
                          <div className='female'>
                            <FaFemale size={80} />
                          </div>
                          <div>Female</div>
                        </>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={1} className='vl-ad'></Col>
        <Col xs={8}>
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={updateUserInfo}
            initialValues={empInfo}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                {editUserInfo ? (
                  <>
                    <Row>
                      <Col xs={1} lg={{ span: 0, offset: 10 }}>
                        <div>
                          <Button
                            disabled={!isValid || isSubmitting}
                            className='esi-check-button-blue'
                            type='submit'
                          >
                            <FaCheck size={15} />
                          </Button>
                        </div>
                      </Col>
                      <Col xs={1} lg={{ span: 0 }}>
                        <div>
                          <Button
                            onClick={handleClose}
                            className='esi-cross-button'
                          >
                            <ImCross size={15} />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row className='edit-row'>
                    {/* <Col md={2} > edit option</Col> */}

                    <RiEditBoxLine
                      className='blue-edit-icon'
                      onClick={allowEdit}
                      cursor='pointer'
                      title='Edit option'
                    ></RiEditBoxLine>
                  </Row>
                )}
                <Row>
                  <Col xs={4}>
                    <Stack direction='vertical' gap={3}>
                      <div>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                          {/* Name */}
                          <Form.Label>
                            {attachmentListUserInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === '1'
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='name'
                            value={values.name}
                            readOnly={markReadOnly}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                            maxLength={50}
                            onKeyPress={valid_alphabet}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Stack>
                  </Col>
                  <Col xs={4}>
                    <Stack direction='vertical' gap={3}>
                      <div>
                        <Form.Group
                          className='mb-3'
                          controlId='formBasicEmail'
                          marginLeft='-4rem'
                        >
                          {/* Role*/}
                          <Form.Label>
                            {attachmentListUserInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === '3'
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='role'
                            value={values.role === 0 ? null : values.role}
                            readOnly='true'
                            onChange={handleChange}
                            isValid={touched.role && !errors.role}
                            isInvalid={!!errors.role}
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.role}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Stack>
                  </Col>
                  <Col xs={3}>
                    <Stack direction='vertical' gap={3}>
                      <div>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                          {/* User Contact Number */}
                          <Form.Label>
                            {attachmentListUserInfo
                              .filter(
                                (attachment) => attachment.attachment_Id === '4'
                              )
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            type='number'
                            name='contactNum'
                            value={values.contactNum}
                            readOnly={markReadOnly}
                            onChange={handleChange}
                            isValid={touched.contactNum && !errors.contactNum}
                            isInvalid={!!errors.contactNum}
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.contactNum}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Stack direction='vertical' gap={3}>
                      <div>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlTextarea1'
                        >
                          {/* Address */}
                          <Form.Label>
                            {attachmentListUserInfo
                              .filter((attachment) => attachment.attachment_Id === '5')
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            type='text'
                            name='address'
                            value={values.address}
                            rows={5}
                            maxLength={250}
                            readOnly={markReadOnly}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Stack>


                  </Col>
                  <Col xs={7}>
                    <Stack direction='vertical' gap={3}>
                      <div>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlTextarea1'
                        >
                          {/* Address */}
                          <Form.Label>
                            {attachmentListUserInfo
                              .filter((attachment) => attachment.attachment_Id === '6')
                              .map((eachAttachment) => (
                                <>
                                  {isKannada
                                    ? eachAttachment.attachment_Kn
                                    : eachAttachment.attchment_En}
                                </>
                              ))}
                          </Form.Label>
                          <div>
                            <div className='border border-2 rounded mb-3 dispensaryBox'>
                              {listOfDispensaries &&
                                listOfDispensaries.map((value) => {
                                  return (
                                    <Badge pill className='me-2 py-1 px-2 badgesB'>
                                      {value}
                                    </Badge>
                                  );
                                })}
                            </div>
                          </div>
                          <Form.Control.Feedback type='invalid'>
                            {errors.dispensaries}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );

};
