import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { useHistory } from "react-router-dom";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import TextError from "../../../CommonComponents/TextError";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";

export const RejectedToggleModal = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Rejected</Modal.Title>
          <ImCross className="modal-im-cross" onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className="confirm-modal-body">
          <ImCross size={70} style={{ color: "red" }} />
          {props.errorMessage && <TextError>{props.errorMessage}</TextError>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="esi-confirm-modal-button" onClick={props.onHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
