import React, { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import { OfficeNotesAndMemo } from "../../../_services";
import TextError from "../../../CommonComponents/TextError";

export const ConfirmSignSubmit = (props) => {
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const submitSignature = async (e) => {
        e.preventDefault();
        setLoaderStatus(true);
        const obj = {
            sign: props?.data?.split(',')[1]
        };
        await OfficeNotesAndMemo.saveSignatureService(obj).then(
            (res) => {
                setLoaderStatus(false);
                props.onHide("success");
            },
            (error) => {
                console.error(error);
                setShowError(true);
                setLoaderStatus(false);
                setErrorMessage("Something went wrong... contact administrator!!!");
            }
        );
    };

    return (
        <>
            {loaderStatus ? <EsiLoader /> : null}
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form onSubmit={submitSignature}>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Are You Sure</Modal.Title>

                        <ImCross className="modal-im-cross" onClick={props.onHide} />
                    </Modal.Header>
                    <Modal.Body className="confirm-modal-body">
                        {showError ? <TextError>{errorMessage}</TextError> : null}
                        <AiOutlineWarning color="red" size={100}></AiOutlineWarning>
                        <p> Are you sure you want to submit Signature </p>

                        <p> Once Submitted can not be changed </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="esi-confirm-modal-button" type="submit">
                            YES
                        </Button>
                        <Button
                            className="esi-confirm-modal-cancel-button"
                            onClick={props.onHide}
                        >
                            NO
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
