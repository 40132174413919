import { BehaviorSubject } from "rxjs";
import { config } from "../constants";
import {
  currentUserData,
  jwt,
  userRegistrationData,
} from "../Test/Data/UserData";
import { authHeader } from "../_helpers";
import { http } from "./http.service";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const currentTokenSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("userToken"))
);

//new API for Forgot Password
async function forgotIpPassword(params) {
  const url = `${config.apiUrl}/ip/auth/forgot-password`;
  const forgotParams = {
    ipNumber: params.ipNumber
  };

  return http.postData(url, forgotParams).then((data) => {
    localStorage.setItem("userData", JSON.stringify(data));
    return data;
  });

}

async function validateVerificationCode(params) {
  const url = `${config.apiUrl}/ip/auth/validate-verification-code`;
  const verificationParams = {
    ipNumber: params.ipNumber,
    verificationCode: params.verificationCode
  };

  return http.postData(url, verificationParams).then((data) => {
    localStorage.setItem("userToken", JSON.stringify(data));
    return data;
  });
}

async function resetIpPassword(params) {
  const url = `${config.apiUrl}/ip/reset-password`;
  const resetPasswordParams = {
    ipNumber: params.ipNumber,
    password: params.password
  };

  return http.postData(url, resetPasswordParams).then((data) => {
    return data;
  });
}

async function userManagementLogin(params) {
  const url = `${config.apiUrl}/login`;
  const userParams = {
    username: params.username,
    password: params.password
  };

  return http.postData(url, userParams).then((data) => {
    localStorage.setItem("userToken", JSON.stringify(data));
    return data;
  });

}

export const authenticationService = {
  generateOtp,
  validateAndLogin,
  validateVerificationCode,
  resetIpPassword,
  userManagementLogin,
  logout,
  registor,
  setPassword,
  activateAccount,
  sessionService,
  forgotPassword,
  forgotIpPassword,
  userOTPForgotPassword,
  updateUserData,
  updateJwtToken,
  resendOTP,
  currentUser: currentUserSubject.asObservable(),
  userToken: currentTokenSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get currentUserToken() {
    return currentTokenSubject.value;
  },
};

function updateUserData(User) {
  currentUserSubject.next(User);
}

function updateJwtToken(jwt) {
  currentTokenSubject.next(jwt);
}

async function generateOtp(params) {
  const url = `${config.apiUrl}/ip/auth/register-user`;
  const loginParams = {
    ipName: params.ipName,
    ipNumber: params.ipNumber,
    contactNum: params.ipPhone,
    emailId: params.ipEmail,
    password: params.ipPassword
  };
  return http.postData(url, loginParams).then((data) => {
    localStorage.setItem("userData", JSON.stringify(data));
    return data;
  });

  // return testUserRegData().then((data) => {
  //   localStorage.setItem("userData", JSON.stringify(data));
  //   return data;
  // });
}

//Function used to validate otp and login
async function validateAndLogin(params) {
  const url = `${config.apiUrl}/login`;
  const requestData = {
    username: params.userName,
    password: params.password,
  };
  return http.postData(url, requestData).then((jwt) => {
    localStorage.setItem("userToken", JSON.stringify(jwt));
    updateJwtToken(jwt);
    debugger;
    sessionService(); //set the user session post login
    return jwt;
  });

  //Api for new forgot password



  // return testJwtPostData().then((jwt) => {
  //   localStorage.setItem("userToken", JSON.stringify(jwt));
  //   updateJwtToken(jwt); //set the user session post login
  //   // sessionService();
  //   return jwt;
  // });
}

async function registor(params) {
  const url = `${config.apiUrl}/create-account`;
  const data = {
    username: params.name,
    emailId: params.email,
    userType: params.type,
  };
  return http.postData(url, data).then((user) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    updateUserData(user);
    return user;
  });
}

async function testJwtPostData() {
  return jwt;
}

async function testSessionPostData() {
  return currentUserData;
}

async function testUserRegData() {
  return userRegistrationData;
}

async function setPassword(password) {
  const auth = authHeader();
  const data = {
    emailId: this.currentUserValue.emailId,
    password: password,
  };
  const url = `${config.apiUrl}/reset-password`;
  return http.postData(url, data, auth);
}

async function activateAccount(otp) {
  const data = {
    emailId: this.currentUserValue.emailId,
    otp: otp,
  };
  const url = `${config.apiUrl}/activate-account`;
  return http.postData(url, data).then((jwt) => {
    localStorage.setItem("userToken", JSON.stringify(jwt));
    updateJwtToken(jwt);
    return jwt;
  });
}

async function sessionService() {
  debugger
  const url = `${config.apiUrl}/user-session`;
  return http.getData(url).then((user) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    updateUserData(user);
    return user;
  });

  // return testSessionPostData().then((user) => {
  //   localStorage.setItem("currentUser", JSON.stringify(user));
  //   updateUserData(user);
  //   return user;
  // });
}

async function forgotPassword(email) {
  const req = {
    emailId: email,
  };
  const url = `${config.apiUrl}/forgot-password`;
  return http.postData(url, req).then((user) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    updateUserData(user);
    return user;
  });
}

async function userOTPForgotPassword(otp) {
  const req = {
    emailId: this.currentUserValue.emailId,
    otp: otp,
  };
  const url = `${config.apiUrl}/user-otp`;
  return http.postData(url, req).then((jwt) => {
    localStorage.setItem("userToken", JSON.stringify(jwt));
    updateJwtToken(jwt);
    return jwt;
  });
}

async function resendOTP(email) {
  const req = {
    emailId: email,
  };
  const url = `${config.apiUrl}/resend-otp`;
  return http.postData(url, req).then((user) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    updateUserData(user);
    return user;
  });
}

async function logout() {
  // remove user from local storage to log user out

  console.log("Logging Out");
  localStorage.removeItem("currentUser");
  localStorage.removeItem("userToken");
  updateJwtToken(null);
  updateUserData(null);
}
