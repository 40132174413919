export const OneIpClaimData = {
  claimUuid: "66cb5e72-818c-489f-98dc-57a23d00bfca",
  partyId: 1111,
  ipNumber: 1111,
  contactNum: 111111,
  dispenseryId: 2,
  claimRefNum: "C111129834",
  sakalaNumber: null,
  appliedClaimAmount: 222,
  patientId: 146,
  esiHospitalReferral: false,
  treatmentFrom: "2021-12-21",
  treatmentTo: "2021-12-21",
  createdBy: "1111",
  updatedBy: null,
  createdTimestamp: "2021-12-21T07:48:00.12",
  updatedTimestamp: null,
  claimStatus: "Received",
  patientName: "1111",
  relationshipType: "Self",
  dependentDob: null,
  dependentStatus: "1",
  dependentAge: 0,
  declarationList: [
    {
      id: null,
      declarationMasterId: 1,
      declarationVal: "true",
    },
    {
      id: null,
      declarationMasterId: 2,
      declarationVal: "true",
    },
    {
      id: null,
      declarationMasterId: 3,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 4,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 5,
      declarationVal: "true",
    },
    {
      id: null,
      declarationMasterId: 6,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 7,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 8,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 9,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 10,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 11,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 12,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 13,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 14,
      declarationVal: "false",
    },
    {
      id: null,
      declarationMasterId: 15,
      declarationVal: "www",
    },
    {
      id: null,
      declarationMasterId: 16,
      declarationVal: "22",
    },
    {
      id: null,
      declarationMasterId: 17,
      declarationVal: "22",
    },
    {
      id: null,
      declarationMasterId: 18,
      declarationVal: "false",
    },
  ],
};
