export const claimStatusList = [
  {
    id: 64,
    claimId: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    status: "Submitted",
    createdBy: "999989999",
    updatedBy: null,
    createdTimestamp: "2021-12-15T14:45:03.877",
    updatedTimestamp: null,
  },
  {
    id: 65,
    claimId: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    status: "Received",
    createdBy: "Abhishek",
    updatedBy: null,
    createdTimestamp: "2021-12-15T14:45:17.273",
    updatedTimestamp: null,
  },
  {
    id: 66,
    claimId: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    status: "Document Verification",
    createdBy: "Abhishek",
    updatedBy: null,
    createdTimestamp: "2021-12-15T14:45:34.307",
    updatedTimestamp: null,
  },
  {
    id: 67,
    claimId: "06fae6a2-9670-4bb9-8857-043e86ea03fd",
    status: "Approved",
    createdBy: "Abhishek",
    updatedBy: null,
    createdTimestamp: "2021-12-15T14:45:34.307",
    updatedTimestamp: null,
  },
];
