import { config } from "../constants";
import { http } from "./http.service";

export const OfficeNotesAndMemo = {
    getONReportService,
    saveSignatureService,
    getSignatureService,
    saveOfficeNotesDetails,
    getOfficeNotesDetails,
    saveOfficeMemoDetails,
    getOfficeMemoDetails,
    getOMReportService
};

async function getONReportService(claimId) {
    const url = `${config.workFlowUrl}/note/get-on-report/${claimId}`;
    const headers = {
        responseType: 'arraybuffer',
    }
    return http.getData(url, headers).then((res) => {
        return res;
    });
}

async function saveSignatureService(file) {
    const url = `${config.workFlowUrl}/note/save-signature`;
    return http.postData(url, file);
}

async function getSignatureService() {
    const url = `${config.workFlowUrl}/note/get-signature`;
    return http.getData(url).then((res) => {
        return res;
    });
}

async function saveOfficeNotesDetails(params, claimId) {
    const url = `${config.workFlowUrl}/note/save-on-details/${claimId}`;
    const requestBody = {
        notes: params.notes,
        treatmentInfo: params.treatmentInfo,
        grievanceMeetingInfo: params.grievanceMeetingInfo,
        claimType: params.claimType
    };

    return http.postData(url, requestBody).then((res) => {
        return res;
    });
}

async function getOfficeNotesDetails(claimId) {
    const url = `${config.workFlowUrl}/note/get-on-details/${claimId}`;
    return http.getData(url).then((res) => {
        return res;
    });
}
async function getOMReportService(claimId) {
    const url = `${config.workFlowUrl}/note/get-om-report/${claimId}`;
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
        responseType: 'arraybuffer',
    }
    return http.getData(url, headers).then((res) => {
        return res;
    });
}
async function saveOfficeMemoDetails(params, claimId) {
    const url = `${config.workFlowUrl}/note/save-om-details/${claimId}`;
    const requestBody = {
        notes: params.notes
    };

    return http.postData(url, requestBody).then((res) => {
        return res;
    });
}
async function getOfficeMemoDetails(claimId) {
    const url = `${config.workFlowUrl}/note/get-om-details/${claimId}`;
    return http.getData(url).then((res) => {
        return res;
    });
}