import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ImCross } from "react-icons/im";


import "../../SCSS/CommonComponentsCSS/Modal.scss";


export function CancelAlert(props) {

  // const navigate = useNavigate();

  const history = useHistory();
  const Cancelclose = () => {
   
    history.push('/user');
    
  };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
           <Modal.Header >
           <ImCross className="imcross" onClick={props.onHide} />
            {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
           </Modal.Title>  */}
           </Modal.Header>
           
        <Modal.Body>
          <p>
           Are you sure! you want to cancel
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
          onClick={Cancelclose}
          className="esi-btn"
          //onClick={props.onHide}
          >YES</Button>
          <Button onClick={props.onHide} className="esi-btn">
            NO
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
//  export function CancelAlert() {
//     // const [modalShow, setModalShow] = React.useState(false);
  
//     return (
//       <>
//         {/* <Button variant="primary" onClick={() => setModalShow(true)}>
//           Launch vertically centered modal
//         </Button>
  
//         <MyVerticallyCenteredModal
//           show={modalShow}
//           onHide={() => setModalShow(false)}
//         /> */}
//       </>
//     );
//   }
  
  // render(<CancelAlert />);

