import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { ErrorMessage, Field, Formik } from "formik";
import "../../../SCSS/CommonComponentsCSS/Modal.scss";
import { DependantService } from "../../../_services/DependantService";
import TextError from "../../../CommonComponents/TextError";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import EsiDatePicker from "../../../CommonComponents/EsiDatePicker";
import { Role } from "../../../_helpers";
import { useParams } from "react-router-dom";
import { RiEditBoxLine } from "react-icons/ri";
import { ApprovedToggleModal } from "./ApprovedToggleModal";

export const IpCardVerify = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ipCardDetail, setIpCardDetail] = useState();
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [approvedModalShow, setApprovedModalShow] = useState(false);
  const { caseNumber } = useParams();

  useEffect(() => {
    getIpCardDetails();
    checkRole();
  }, [])

  const getIpCardDetails = async () => {
    await DependantService.getIpdCardInfo(props.data.ipNumber).then(res => {
      if (res && res.ipName) {
        const data = {
          id: res.id,
          ipNumber: res.ipNum,
          ipPhone: res.contactNum,
          ipName: res.ipName,
          dateOfRegistration: new Date(res.dateOfReg),
          cardValidUpTo: new Date(res.cardValidUpto)
        }
        setIpCardDetail(data);
        props.ipCardData(data);
      }
    })
  }

  const checkRole = () => {
    const isViewPermission = [
      Role.pharmacist, Role.ddo, Role.d_caseWorker, Role.os, Role.ao, Role.dda, Role.cso, Role.d_pharmacist
    ];
    const userRole = JSON.parse(localStorage.currentUser).authorites[0];
    if (isViewPermission.includes(userRole)) {
      setIsView(true);
    }
    if (userRole === Role.caseWorker && props?.data?.claimStatus === 'Submitted' && JSON.parse(localStorage.currentUser).dispensaries.includes(props?.data?.dispenseryId)) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
      setIsView(true);
    }
  }

  const handleProceedClick = async (e) => {
    setLoaderStatus(true);
    const requestData = {
      ipNum: e.ipNumber,
      contactNum: e.ipPhone,
      ipName: e.ipName,
      dateOfReg: convertDate(e.dateOfRegistration),
      cardValidUpto: convertDate(e.cardValidUpTo),
      claimId: caseNumber,
    };

    if (props.data.isIpCardverified) {
      requestData.id = ipCardDetail.id;
    }

    await DependantService.sendCardInfo(requestData).then(
      (res) => {
        setIsEdit(false);
        if (res.errorCode) {
          setErrorMessage(res.message);
        } else {
          setApprovedModalShow(true);
          setTimeout(() => {
            setApprovedModalShow(false);
          }, 5000);
        }
        setLoaderStatus(false);
      },
      (error) => {
        console.error(error);
        setShowError(true);
        setLoaderStatus(false);
        setErrorMessage("Something went wrong..., contact administrator!!!");
      }
    );
  };

  const schema = yup.object().shape({
    ipNumber: yup
      .number()
      .min(1000000000, "10 Digits Only")
      .max(9999999999, "10 Digits Only")
      .required("IP Number Is Required Field"),
    cardValidUpTo: yup
      .date()
      .required("Please Select Date of validity")
      .nullable(),
    dateOfRegistration: yup
      .date()
      .required("Please Select Date of Registration")
      .nullable(),
    ipPhone: yup
      .number()
      .min(1000000000, "10 Digits Only")
      .max(9999999999, "10 Digits Only")
      .required("IP Phone Number Is Required Field"),
    ipName: yup
      .string()
      .max(50, "50 Chars Only")
      .matches(/^[ A-Za-z]*$/, "Only alphabets are allowed for this field ")
      .required("IP Name Is Required Field"),
  });
  const valid_numbers = (e) => {

    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not 
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }
  }
  const initialForm = {
    ipNumber: '',
    ipPhone: '',
    ipName: '',
    cardValidUpTo: null,
    dateOfRegistration: null,
  };

  const allowEdit = async () => {
    setIsEdit(true);
  };

  const handleCancel = async (values) => {
    if (props.data.isIpCardverified) {
      values(ipCardDetail);
      setIsEdit(false);
    } else {
      values(initialForm);
    }
  };
  const valid_alphabet = (e) => {
    var key = e.which || e.KeyCode;
    if (key === 32 || (key >= 65 && key <= 90)) {
      return true;
    } else if (key >= 97 && key <= 122) {
      return true
    }
    // to check whether pressed key is alphabet or not
    else {
      //alert("Enter only Alphabet");
      e.preventDefault();
      return false;
    }
  };


  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Formik
        validationSchema={schema}
        onSubmit={handleProceedClick}
        initialValues={ipCardDetail || initialForm}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          resetForm,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="add-sob-container">
            {errorMessage ? <TextError>{errorMessage}</TextError> : null}
            <div className="verify-card-form">
              <div id="box">
                <Row>
                  <Col xs={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>IP Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="ipName"
                        value={values.ipName}
                        onChange={handleChange}
                        isValid={touched.ipName && !errors.ipName}
                        isInvalid={!!errors.ipName}
                        readOnly={!isEdit}
                        maxLength={50}
                        onKeyPress={valid_alphabet}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ipName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>IP Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="ipNumber"
                        value={values.ipNumber}
                        onChange={handleChange}
                        isValid={touched.ipNumber && !errors.ipNumber}
                        isInvalid={!!errors.ipNumber}
                        onKeyDown={(e) => e.key === "e" && e.preventDefault()}
                        maxLength={10}
                        onKeyPress={valid_numbers}
                        readOnly={!isEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ipNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>IP Phone</Form.Label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="ipPhone"
                          value={values.ipPhone}
                          onChange={handleChange}
                          isValid={touched.ipPhone && !errors.ipPhone}
                          isInvalid={!!errors.ipPhone}
                          onKeyDown={(e) =>
                            e.key === "e" && e.preventDefault()}
                          maxLength={10}
                          onKeyPress={valid_numbers}
                          readOnly={!isEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ipPhone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Group>
                  </Col>

                  {!isEdit && !isView && <Col xs={1} className='verify-edit-row'>
                    <RiEditBoxLine
                      className='blue-edit-icon'
                      onClick={allowEdit}
                      cursor='pointer'
                    ></RiEditBoxLine>
                  </Col>}
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date of Registration</Form.Label>
                      <Form.Group>
                        {/* <EsiDatePicker
                          maxDate={new Date()}
                          name="dateOfRegistration"
                          readOnly={!isEdit}
                        /> */}
                        <Field
                          type="date"
                          className="form-control"
                          value={values.dateOfRegistration ? convertDate(values.dateOfRegistration) : "dd-mm-yyyy"}
                          maxDate={new Date()}
                          name="dateOfRegistration"
                          readOnly={!isEdit}
                        />
                        <ErrorMessage component={TextError} name="dateOfRegistration" />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Entitlement Period (Running Letter Date)</Form.Label>
                      <Form.Group>
                        {/* <EsiDatePicker
                          minDate={new Date(values.dateOfRegistration)}
                          name="cardValidUpTo"
                          readOnly={!isEdit}
                        /> */}
                        <Field
                          type="date"
                          className="form-control"
                          value={values.cardValidUpTo ? convertDate(values.cardValidUpTo) : "dd-mm-yyyy"}
                          min={convertDate(new Date(values.dateOfRegistration))}
                          name="cardValidUpTo"
                          readOnly={!isEdit}
                        />
                         <ErrorMessage component={TextError} name="cardValidUpTo"/>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              </div>
            </div>
            {isEdit && <div>
              <Row>
                <Col xs={12} md lg={{ span: 2, offset: 8 }}>
                  <div className=" mg-auto">
                    <Button
                      className="esi-white-cancel-button-lg"
                      onClick={() => handleCancel(resetForm)}
                    >
                      CANCEL

                    </Button>

                  </div>
                </Col>
                <Col xs={12} md lg={{ span: 2, offset: 0 }}>
                  <div className="">
                    <Button
                      className="esi-navy-blue-button-lg"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                    >
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>}
          </Form>
        )}
      </Formik>
      <ApprovedToggleModal
        title={"Success"}
        description={"Ip Card Verification Successful"}
        show={approvedModalShow}
        onHide={() => setApprovedModalShow(false)}
      />
    </>
  )
}

function convertDate(date) {
  var forDate = new Date(date);
  var dd = forDate.getDate();
  var mm = forDate.getMonth() + 1;
  var yyyy = forDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var datestring = yyyy + "-" + mm + "-" + dd;
  return datestring;
}
