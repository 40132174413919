import { convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

import activityRibbon from "../../../assests/svg/activity-log-ribbon.svg";
import "../../../SCSS/Views/ClaimTabView.scss";

import { Breadcrumb, Button, Col, Table, Form, Row, Stack, Tabs, Tab } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { MdKeyboardBackspace } from "react-icons/md";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminCommentCard } from "../../../CommonComponents/CommentComponent/AdminCommentCard";
import { UserCommentCard } from "../../../CommonComponents/CommentComponent/UserCommentCard";
import "../../../SCSS/Views/IpClaimViewAdmin.scss";
import { IpClaimService } from "../../../_services/IpClaimService";
import { RejectedModal } from "../../../Views/Admin/Modals/RejectedModal";
import { HorizontalDivider } from "../../../CommonComponents/HorizontalDivider";
import { EsiLoader } from "../../../CommonComponents/EsiLoader";
import { attachmentListTreatmentInfo } from "../../../Views/User/Data/Attachment_List";
import TextError from "../../../CommonComponents/TextError";
import { CommentAccordian } from "../../../CommonComponents/CommentAccordian";
import { IpCardVerify } from "../../../Views/Admin/Modals/IpCardVerify";
import { ApprovedModal } from "./../Modals/ApprovedModal";
import { CommonAccordian } from "../../../CommonComponents/CommonAccordian";
import { IconDescription } from "../../../CommonComponents/IconDescription";
import { ViewSOBTable } from "../SOBComponent/ViewSOBTable";
import { AddSOB } from "../SOBComponent/AddSOB";
import { PcClaimService } from "../../../_services/PcClaimService";
import { ToggleClaimState } from "../ToggleClaimState";
import { Role } from "../../../_helpers";
import { ClaimStageComponent } from "./ClaimStage"
import { ActivityModal } from "../Modals/ActivityModal";
import { OfficeNotesComponent } from "./OfficeNotesComponent";
import Moment from "react-moment";
import _ from "lodash";

export const ClaimTabView = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [comments, setComments] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const history = useHistory();
  const { caseNumber } = useParams();
  const [ipClaim, setIpClaim] = useState({});
  const [states, setStates] = useState({});
  const [tabKey, setTabKey] = useState('');
  const [SOB, SetSOB] = useState('');
  const [isExternal, setIsExternal] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [declarationArray, setDeclarationArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [approvedModalShow, setApprovedModalShow] = useState(false);
  const [sakalaNumber, setSakalaNumber] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [sobList, setSOBList] = useState([]);
  const [sobUUID, setSOBUUID] = useState([]);
  const [isSOBShow, setIsSOBShow] = useState(false);
  const [ipCardInfo, setIpCardInfo] = useState(null);
  const [activityModalShow, setActivityModalShow] = useState(false);
  const [isDCaseWorkerAbove, setIsDCaseWorkerAbove] = useState(false);
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);

  useEffect(() => {
    getCaseDetails();
    getSOBEditableValue();
  }, []);

  const claimInfoList = [
    {
      fieldName: "CASE NUMBER",
      fieldValue: `${ipClaim.claimRefNum ? ipClaim.claimRefNum : "-"}`,
      fieldIcon: "numberIcon-svg"
    },
    {
      fieldName: "IP NAME",
      fieldValue: `${ipClaim.ipName ? ipClaim.ipName : "-"}`,
      fieldIcon: "userIcon-svg"
    },
    {
      fieldName: "RELATIONSHIP",
      fieldValue: `${ipClaim.relationshipType ? ipClaim.relationshipType : "-"}`,
      fieldIcon: "groupIcon-svg"
    },
    {
      fieldName: "PATIENT NAME",
      fieldValue: `${ipClaim.patientName ? ipClaim.patientName : "-"}`,
      fieldIcon: "userIcon-svg"
    },
    {
      fieldName: "CLAIM DATE",
      fieldValue: ipClaim.createdTimestamp ? <Moment format={"DD-MM-YYYY hh:mm A"}>
        {ipClaim.createdTimestamp}
      </Moment> : '-',
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "CLAIM AMOUNT",
      fieldValue: `${ipClaim.appliedClaimAmount ? Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
      }).format(ipClaim.appliedClaimAmount) : "-"
        }`,
      fieldIcon: "rupee-svg"
    },
    {
      fieldName: "ELIGIBLE AMOUNT",
      fieldValue: `${ipClaim.approvedClaimAmount ? Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
      }).format(ipClaim.approvedClaimAmount) : "-"}`,
      fieldIcon: "rupee-svg"
    },
    {
      fieldName: "STATUS",
      fieldValue: `${ipClaim.claimStatus ? ipClaim.claimStatus : "-"}`,
      fieldIcon: "infoIcon-svg"
    },
    {
      fieldName: "DISPENSARY",
      fieldValue: `${ipClaim.dispenseryName ? ipClaim.dispenseryName : "-"}`,
      fieldIcon: "hospital-svg"
    },
    {
      fieldName: "SAKALA NUMBER",
      fieldValue: `${ipClaim.sakalaNumber ? ipClaim.sakalaNumber : "-"}`,
      fieldIcon: "sakala-svg"
    },
    {
      fieldName: "SAKALA CREATED DATE",
      fieldValue: `${ipClaim.sakalaNumber ? ipClaim.sakalaNumber : "-"}`,
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "SAKALA DUE DATE",
      fieldValue: `${ipClaim.sakalaNumber ? ipClaim.sakalaNumber : "-"}`,
      fieldIcon: "calendar-svg"
    },
    {
      fieldName: "SAKALA UPDATED DATE",
      fieldValue: `${ipClaim.sakalaNumber ? ipClaim.sakalaNumber : "-"}`,
      fieldIcon: "calendar-svg"
    },
  ];

  const [convertedContent, setConvertedContent] = useState(null);

  const showActivity = (e) => {   
    setActivityModalShow(true)
  };

  const getIpClaimDeclaration = async () => {
    setDeclarationArray(
      await IpClaimService.getIpClaimDeclarationByNumber(caseNumber)
    );
  };

  const getComments = async () => {
    setLoaderStatus(true);
    const commentsData = await IpClaimService.getClaimComments(caseNumber);   
    setComments(commentsData);
    setLoaderStatus(false);
  };

  const addComments = async () => {
    console.log(convertedContent, isExternal);
    if (!convertedContent || convertedContent === "<p><br></p>") {
      setShowError(true);
      setErrorMessage("Add text to submit comments!!");
    } else {
      setShowError(false);
      setLoaderStatus(true);
      await IpClaimService.createComments({
        comment: convertedContent,
        claimId: ipClaim.claimUuid,
        external: isExternal,
      });
      setLoaderStatus(false);
      setErrorMessage("");
      ChangeTextAreaCount(0); 
      getComments();
      setEditorState(EditorState.createEmpty());
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    console.log("Editor has content");
    let currentContentAsHTML = stateToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const recalculate = e => {
    ChangeTextAreaCount(e.blocks[0].text.length);
  };

  async function getCaseDetails() {
    setLoaderStatus(true)
    await IpClaimService.getIpClaimByNumber(caseNumber).then((res) => {
      setLoaderStatus(false);
      if (res) {
        setIpClaim(res);
        if (JSON.parse(localStorage.currentUser).authorites[0] !== Role.caseWorker) {
          getSOBData();
        }
        getComments();
        getIpClaimDeclaration();
        getStates(res);
      }
    });
  }


  async function getSOBEditableValue() {
    setLoaderStatus(true)
    await IpClaimService.getSOBEditableValue(caseNumber).then((res) => {
      setLoaderStatus(false);
      if (res) {
        SetSOB(res);       
      }
    });
  }




  const handleSelect = (key) => {   
    setTabKey(key);
  };

  const getSOBData = async () => {
    await PcClaimService.getSOBList(caseNumber).then((res) => {
      if (res.length > 0) {
        res.map((sob) => {
          if (sob.active) {
            setSOBList(sob.bills);
            setSOBUUID(sob.sobUUID);
          }
        })
      }
    })
  }

  const getStates = async (ipClaimDetails) => {
    await PcClaimService.getStates().then((res) => {
      if (res) {
        setStates(res);
        loadValidTabs(res, ipClaimDetails)
      }
    })
  }

  const loadValidTabs = (states, ipClaimDetails) => {
    let currentStateId;
    const key = JSON.parse(localStorage.currentUser).authorites[0];
    const currentStatus = ipClaim.claimStatus ? ipClaim.claimStatus : ipClaimDetails.claimStatus;
    const statesGrouped = _.keyBy(states, "wrkFlowCode");
    states.forEach((ele) => {
      if (ele.displayName === currentStatus) {
        currentStateId = ele.id
      }
    });   
    verifySOBCanEdit(key, statesGrouped, currentStateId);
    verifyONDisplay(key, statesGrouped, currentStateId);
  }

  const verifyONDisplay = (key, statesGrouped, currentStateId) => {
    switch (key) {
      case Role.d_caseWorker:
        if (currentStateId >= statesGrouped["SOB_VERIFIED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
      case Role.os:
        if (currentStateId >= statesGrouped["ON_PREPARED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
      case Role.ao:
        if (currentStateId >= statesGrouped["OS_VERIFIED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
      case Role.dda:
        if (currentStateId >= statesGrouped["AO_VERIFIED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
      case Role.cso:
        if (currentStateId >= statesGrouped["AO_APPROVED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
      case Role.d_pharmacist:
        if (currentStateId >= statesGrouped["SS_VERIFIED"].id) {
          setIsDCaseWorkerAbove(true)
        }
        break;
        case Role.ss:
          if (currentStateId >= statesGrouped["OS_VERIFIED"].id) {
            setIsDCaseWorkerAbove(true)
          }
          break;
      default:
        setIsDCaseWorkerAbove(false);
        break;
    }
  }
  const verifySOBCanEdit = (key, statesGrouped, currentStateId) => {    
    switch (key) {
      case Role.pharmacist:
        if (currentStateId >= statesGrouped["RECEIVED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.ddo:
        if (currentStateId >= statesGrouped["SOB_PREPARED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.d_caseWorker:
        if (currentStateId >= statesGrouped["SOB_VERIFIED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.os:
        if (currentStateId >= statesGrouped["ON_PREPARED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.ao:
        if (currentStateId >= statesGrouped["OS_VERIFIED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.dda:
        if (currentStateId >= statesGrouped["AO_VERIFIED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.cso:
        if (currentStateId >= statesGrouped["AO_APPROVED"].id) {
          setIsSOBShow(true)
        }
        break;
      case Role.d_pharmacist:
        if (currentStateId >= statesGrouped["SS_VERIFIED"].id) {
          setIsSOBShow(true)
        }
        break;
      default:
        setIsSOBShow(false);
        break;
    }
  }

  return (
    <>
      <div className="ribbon-container">
        <img src={activityRibbon} className="activity-ribbon" onClick={() => showActivity()} />
      </div>

      {activityModalShow && <ActivityModal
        show={activityModalShow}
        onHide={() => setActivityModalShow(false)}
        caseDetails={caseNumber}
      />}

      {loaderStatus ? <EsiLoader /> : null}
      <Row className="row-breadcrumb">
        <Stack direction="horizontal" gap={2}>
          <div>
            <MdKeyboardBackspace
              className="BackIcon"
              onClick={() => history.goBack()}
            ></MdKeyboardBackspace>
          </div>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/admin"><div className="homeIcon-svg"></div></Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>View Claims</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Stack>
      </Row>
      <Tabs
        defaultActiveKey="Claim-Status"
        id="esi-tabs"
        className="tabsCls"
        onSelect={(e) => handleSelect(e)}
      >

        <Tab eventKey="Claim-Status" title="Claim Status">

          <Stack className="slider-stack" direction="vertical" gap={4}>


            {JSON.parse(localStorage.currentUser).dispensaries.includes(ipClaim?.dispenseryId) && ipClaim?.claimStatus && <ToggleClaimState caseDetails={caseNumber} data={ipClaim} ipCardData={ipCardInfo} sobData={sobList} handleRefresh={getCaseDetails} />}

            <div>
              <CommonAccordian
                data={{
                  title: "Claim Status: " + ipClaim.claimRefNum,
                  eventKey: "0",
                }}
              >
                <Row>
                  {claimInfoList.map((oneClaimField) => (
                    <Col className="columnspace">
                      <IconDescription icon={oneClaimField.fieldIcon} name={oneClaimField.fieldName} description={oneClaimField.fieldValue} />
                    </Col>
                  ))}
                </Row>
              </CommonAccordian>
            </div>
            {isSOBShow && <div>
              <CommonAccordian style={{ height: "80vh", overflow: 'scroll' }}
                data={{
                  title: "Statement of Bills",
                  eventKey: "3",
                }}
              >
                {(sobList && sobList.length) ? <ViewSOBTable data={{ states: states,sobDisplayStatus: SOB,  sobUUID: sobUUID, sobList: sobList, claim: ipClaim, setSOBList, setSOBUUID }} handleRefresh={getCaseDetails} /> : <AddSOB data={{ states: states, claim: ipClaim, setSOBList }} handleRefresh={getCaseDetails} />}


              </CommonAccordian>
            </div>}
            <div>
              <CommonAccordian
                data={{
                  title: "Verify IP Card",
                  eventKey: "2",
                }}
              >
                {ipClaim?.claimStatus && <IpCardVerify data={ipClaim} ipCardData={setIpCardInfo} />}
              </CommonAccordian>
            </div>
            <div>
              <CommonAccordian
                data={{
                  title: "Declaration",
                  subtitle: "Details of the attachment to be Submitted",
                  eventKey: "3",
                }}
              >
                <Table responsive>
                  <thead className="table">
                    <tr>
                      <th>Document Details</th>
                      <th style={{ textAlign: "center" }}>N/A</th>
                      <th style={{ textAlign: "center" }}>Original Document</th>
                      <th style={{ textAlign: "center" }}>Copy of Document</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {declarationArray?.map((eachDeclaration) =>
                      eachDeclaration.fieldType === "CHECK_BOX" ? (
                        <tr>
                          <td>{eachDeclaration.declartionTextEn}</td>
                          {getRadioElements(eachDeclaration.declarationVal)}
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </Table>
                <HorizontalDivider />
                <Row>
                  <Stack direction="horizontal" gap={5}>
                    <div>
                      <Form.Group className="mb-3 attachment-area">
                        {/* If Others, Please specify. */}
                        {declarationArray
                          .filter(
                            (declaration) => declaration.declarationCode === "DC15"
                          )
                          .map((eachDeclaration) => (
                            <>
                              <Form.Label>
                                {eachDeclaration.declartionTextEn}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="DC15"
                                value={eachDeclaration.declarationVal}
                                readOnly
                              />
                            </>
                          ))}
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group className="mb-3">
                        {/* Count of prescription Attached */}

                        {declarationArray
                          .filter(
                            (declaration) => declaration.declarationCode === "DC16"
                          )
                          .map((eachDeclaration) => (
                            <>
                              <Form.Label>
                                {eachDeclaration.declartionTextEn}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="DC16"
                                value={eachDeclaration.declarationVal}
                                readOnly
                              />
                            </>
                          ))}
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group className="mb-3">
                        {/* Count of bills Attached */}
                        {declarationArray
                          .filter(
                            (declaration) => declaration.declarationCode === "DC17"
                          )
                          .map((eachDeclaration) => (
                            <>
                              <Form.Label>
                                {eachDeclaration.declartionTextEn}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="DC17"
                                value={eachDeclaration.declarationVal}
                                readOnly
                              />
                            </>
                          ))}
                      </Form.Group>
                    </div>
                  </Stack>
                </Row>
                <Row>
                  <Stack direction="horizontal" gap={3}>
                    {declarationArray
                      .filter(
                        (declaration) => declaration.declarationCode === "DC18"
                      )
                      .map((eachDeclaration) => (
                        <>
                          <div>
                            <label class="switch">
                              <input
                                name="DC18"
                                type="checkbox"
                                id="togBtn"
                                checked={
                                  eachDeclaration.declarationVal === "true"
                                    ? true
                                    : false
                                }
                              />
                              <div class="slider round">
                                <span class="on">YES</span>
                                <span class="off">NO</span>
                              </div>
                            </label>
                          </div>
                        </>
                      ))}

                    <div style={{ fontWeight: "400", fontSize: "1rem" }}>
                      <Form.Label>
                        {attachmentListTreatmentInfo
                          .filter((attachment) => attachment.attachment_Id === "10")
                          .map((eachAttachment) => (
                            <>{eachAttachment.attchment_En}</>
                          ))}
                      </Form.Label>
                    </div>
                  </Stack>
                </Row>
              </CommonAccordian>
            </div>
            <div>
              <CommentAccordian data={{ title: "Comments", eventKey: "1" }}>
                <Row>
                  {comments
                    ? comments.map((comment) =>
                      comment.ipUser === false ? (
                        <AdminCommentCard data={comment}></AdminCommentCard>
                      ) : (
                        <UserCommentCard data={comment}></UserCommentCard>
                      )
                    )
                    : null}
                </Row>
                {JSON.parse(localStorage.currentUser).dispensaries.includes(ipClaim?.dispenseryId) && <Row className="editor-row">
                  <Col className="editor-col" xs={12} md lg={6}>
                    {showError ? <TextError>{errorMessage}</TextError> : null}
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleEditorChange}
                      onChange={recalculate}
                      handleBeforeInput={val => {
                        const textLength = editorState.getCurrentContent().getPlainText().length;
                        if (val && textLength >= 250) {
                          return 'handled';
                        }
                        return 'not-handled';
                      }}
                      handlePastedText={val => {
                        const textLength = editorState.getCurrentContent().getPlainText().length;
                        return ((val.length + textLength) >= 250);
                      }}
                    />
                    <span placeholder="Comment Here">{textAreaCount}/250</span>
                  </Col>
                  <Col className="comment-col" xs={12} md lg={6}>
                    <Stack direction="horizontal" gap={2}>
                      <div>
                        <Button
                          className="esi-navy-blue-button"
                          variant="primary"
                          size="lg"
                          active
                          onClick={addComments}
                        >
                          Comment
                        </Button>
                      </div>
                      <div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => setIsExternal(e.target.checked)}
                            id="togBtn"
                          />
                          <div class="slider round">
                            <span class="on" >External</span>
                            <span class="off" >Internal</span>
                            {/* <span class="external">External</span>
                            <span class="internal">Internal</span> */}
                          </div>
                        </label>
                      </div>
                    </Stack>
                  </Col>
                </Row>}
              </CommentAccordian>
            </div>
          </Stack>
        </Tab>
        <Tab eventKey="stage" title="Claim Stage">
          {tabKey === 'stage' && <ClaimStageComponent claimId={caseNumber}></ClaimStageComponent>}
        </Tab>
        {isDCaseWorkerAbove && (ipClaim?.appliedClaimAmount > 5000) && <Tab eventKey="logs" title="Office Notes & Memo">
          {tabKey === 'logs' && <OfficeNotesComponent data={{ states: states, claimDetails: ipClaim }}></OfficeNotesComponent>}
        </Tab>}
      </Tabs>
      <RejectedModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(false)}
        data={{ caseNumber: caseNumber }}
      />
      <ApprovedModal
        show={approvedModalShow}
        onHide={() => setApprovedModalShow(false)}
        data={{
          sakalaNumber: sakalaNumber,
          contactNumber: contactNumber,
        }}
      />
    </>
  )
}

function getRadioElements(value) {
  switch (value) {
    case "N/A":
      return (
        <>
          <td style={{ textAlign: "center" }}>
            <FaCheck />
          </td>
          <td></td>
          <td></td>
        </>
      );
    case "true":
      return (
        <>
          <td></td>
          <td style={{ textAlign: "center" }}>
            <FaCheck />
          </td>
          <td></td>
        </>
      );
    case "false":
      return (
        <>
          <td></td>
          <td></td>
          <td style={{ textAlign: "center" }}>
            <FaCheck />
          </td>
        </>
      );
  }
}
