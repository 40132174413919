import '../SCSS/CommonComponentsCSS/IpProfileInfo.scss';
import { Formik } from 'formik';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { RiEditBoxLine } from 'react-icons/ri';
import * as yup from 'yup';
import { FaRegUserCircle } from 'react-icons/fa';
import { FaMale } from 'react-icons/fa';
import { FaFemale } from 'react-icons/fa';
import { attachmentListIpInfo } from '../Views/User/Data/Attachment_List';
import { DependantService } from '../_services/DependantService';
import { CommonAccordian } from './CommonAccordian';
import { EsiLoader } from './EsiLoader';
import { BsFillCheckCircleFill } from 'react-icons/bs';

export const IpProfileInfo = (props) => {
  const { isKannada, ...rest } = props;
  const [ipInfo, setIpinfo] = useState({});
  const [editIpInfo, setEditIpInfo] = useState(false);
  const [markReadOnly, setMarkReadOnly] = useState(true);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [gend, setGend] = useState('');
  const [maleCheck, setMaleCheck] = useState(false);
  const [femaleCheck, setFemaleCheck] = useState(false);
  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);

  useEffect(() => {
    getIpInfoData();
  }, []);

  const getIpInfoData = async () => {
    setLoaderStatus(true);
    await DependantService.getIpInfo().then((res) => {
      console.log("get ip info ",res);
      setIpinfo(res);
      ipInfo.gender = res.gender;
      updateEmpGender(ipInfo.gender);
      setLoaderStatus(false);
    });
  };

  const updateIpInfo = async (e) => {
    setLoaderStatus(true);
    const requestData = {
      ipNumber: e.ipNumber,
      address: e.address,
      ipName: e.ipName,
      contactNum: e.contactNum,
      aadhar: e.aadhar,
      gender: gend
    };
    await DependantService.updateIpInfo(requestData).then(
      (res) => {
        getIpInfoData();
        setIpinfo(res);
        setEditIpInfo(false);
        setMarkReadOnly(true);
      },
      (error) => {
        setMarkReadOnly(true);
      }
    );
  };

  const allowEdit = async () => {
    setMarkReadOnly(false);
    setEditIpInfo(true);
  };

  const handleClose = async () => {
    setMarkReadOnly(true);
    setEditIpInfo(false);
    setIpinfo({});
    getIpInfoData();
  };

  const updateEmpGender = (gen) => {
    console.log(gen);
    if (gen === 'MALE') {
      setMaleCheck(true);
      setFemaleCheck(false);
    } else {
      setMaleCheck(false);
      setFemaleCheck(true);
    }
    setGend(gen);
  };

  const schema = yup.object().shape({
    ipName: yup
      .string()
      .max(50, '50 Characters Only')
      .matches(/^[ A-Za-z]*$/, 'Only Alphabets Are Allowed For This Field ')
      .required(),
    aadhar: yup
      .number()
      .max(999999999999, 'Invalid Aadhar Number')
      .nullable()
      .notRequired(),
    contactNum: yup
      .number()
      .min(1000000000, 'Min 10 Digits')
      .max(9999999999, '10 digits only.')
      .required(),
    address: yup
      .string()
      .max(250, '250 Characters Only.')
      .nullable()
      .matches(/^[A-Za-z0-9 ,.-]+$/, "No special characters allowed"),
      
  });

  const valid_numbers = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    }
    // to check whether pressed key is number or not
    else {
      //alert("Enter only Numbers");
      e.preventDefault();
      return false;
    }
  };
  const valid_alphabet = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 65 && key <= 90) {
      return true;
    }
    else if(key >=97 && key<= 122 ){
      return true
    }
    // to check whether pressed key is alphabet or not
    else {
      //alert("Enter only Alphabet");
      e.preventDefault();
      return false;
    }
  };
  const recalculate = e => {
    ChangeTextAreaCount(e.target.value.length);
  };

  return (
    <>
      {loaderStatus ? <EsiLoader /> : null}
      <Row className='new-claim-row'>
      <Col xs={3}>
            <Row className='user-icon'>
              <Col align="center">
                <FaRegUserCircle size={110} />
              </Col>
            </Row>
            <Row>
            <Col>
            <div align='center'>IP Number: {ipInfo.ipNumber}</div>
            </Col>
            </Row>
            <Row>
            <Col>
              <Row>
                {editIpInfo ? (
                  <>
                    <Col xs={3}></Col>
                    <Col xs={3}>
                      <div
                        className='edit-gender'
                        onClick={(e) => updateEmpGender('MALE')}
                      >
                        <div className="check-circle">
                          {maleCheck && <BsFillCheckCircleFill />}
                        </div>
                        <div>
                          <FaMale size={80} />
                          <div className='edit-male-text'>Male</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div
                        className='edit-gender'
                        onClick={(e) => updateEmpGender('FEMALE')}
                      >
                        <div className="check-circle">
                          {femaleCheck && <BsFillCheckCircleFill />}
                        </div>
                        <div>
                          <FaFemale size={80} />
                          <div className='edit-female-text'>Female</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={3}></Col>
                  </>
                ) : (
                  <>
                    <Col align='center'>
                      {gend === 'MALE' ? (
                        <>
                          <div className='male'>
                            <FaMale size={80} />
                          </div>
                          <div>Male</div>
                        </>
                      ) : (
                        <>
                          <div className='female'>
                            <FaFemale size={80} />
                          </div>
                          <div>Female</div>
                        </>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            </Row>
         </Col>
         <Col className='vl-ad'></Col>
         <Col xs={8}>
      <Formik
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={updateIpInfo}
        initialValues={ipInfo}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          isValid,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {editIpInfo ? (
              <>
                <Row>
                  <Col xs={1} lg={{ span: 0, offset: 10 }}>
                    <div>
                      <Button
                        disabled={!isValid || isSubmitting}
                        className='esi-check-button-blue'
                        type='submit'
                      >
                        <FaCheck size={15} />
                      </Button>
                    </div>
                  </Col>
                  <Col xs={1} lg={{ span: 0, offset: 0 }}>
                    <div>
                      <Button
                        onClick={handleClose}
                        className='esi-cross-button'
                      >
                        <ImCross size={15} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className='edit-row'>
                <RiEditBoxLine
                  className='blue-edit-icon'
                  onClick={allowEdit}
                  cursor='pointer'
                  title='Edit option'
                ></RiEditBoxLine>
              </Row>
            )}
            <Row>
              <Col xs={4} >
                <Stack direction='vertical' gap={3}>
                  <div>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      {/* Insured Person Name */}
                      <Form.Label>
                        {attachmentListIpInfo
                          .filter(
                            (attachment) => attachment.attachment_Id === '1'
                          )
                          .map((eachAttachment) => (
                            <>
                              {isKannada
                                ? eachAttachment.attachment_Kn
                                : eachAttachment.attchment_En}
                            </>
                          ))}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='ipName'
                        value={values.ipName}
                        readOnly={markReadOnly}
                        onChange={handleChange}
                        //isValid={touched.ipName && !errors.ipName}
                        onKeyPress={valid_alphabet}
                        isInvalid={!!errors.ipName}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.ipName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Stack>
              </Col>
              <Col xs={3} >
                <div>
                  <Form.Group
                    className='mb-3'
                    controlId='formBasicEmail'
                    marginLeft='-4rem'
                  >
                    {/* Aadhaar Number */}
                    <Form.Label>
                      {attachmentListIpInfo
                        .filter(
                          (attachment) => attachment.attachment_Id === '3'
                        )
                        .map((eachAttachment) => (
                          <>
                            {isKannada
                              ? eachAttachment.attachment_Kn
                              : eachAttachment.attchment_En}
                          </>
                        ))}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='aadhar'
                      value={values.aadhar === 0 ? null : values.aadhar}
                      readOnly={markReadOnly}
                      onChange={handleChange}
                      //isValid={touched.aadhar && !errors.aadhar}
                      isInvalid={!!errors.aadhar}
                      onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                      maxLength={12}
                      onKeyPress={valid_numbers}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.aadhar}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Col>
              <Col  xs={4}>
                <Stack direction='vertical' gap={3}>
                  <div>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      {/* Insured Person Phone Number */}
                      <Form.Label>
                        {attachmentListIpInfo
                          .filter(
                            (attachment) => attachment.attachment_Id === '4'
                          )
                          .map((eachAttachment) => (
                            <>
                              {isKannada
                                ? eachAttachment.attachment_Kn
                                : eachAttachment.attchment_En}
                            </>
                          ))}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='contactNum'
                        value={values.contactNum}
                        readOnly={markReadOnly}
                        onChange={handleChange}
                        //isValid={touched.contactNum && !errors.contactNum}
                        isInvalid={!!errors.contactNum}
                        maxLength={10}
                        onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                        onKeyPress={valid_numbers}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.contactNum}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Stack>
              </Col>
              </Row>
              <Row>
              <Col xs={11}>
                  <Stack direction='vertical' gap={3}>
                    <div>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlTextarea1'
                >
                  {/* Address */}
                  <Form.Label>
                    {attachmentListIpInfo
                      .filter((attachment) => attachment.attachment_Id === '5')
                      .map((eachAttachment) => (
                        <>
                          {isKannada
                            ? eachAttachment.attachment_Kn
                            : eachAttachment.attchment_En}
                        </>
                      ))}
                  </Form.Label>
                  <Form.Control
                  as='textarea'
                    type='text'
                    name='address'
                    value={values.address}
                    rows={5}
                    maxLength={250}
                    readOnly={markReadOnly}
                    onChange={handleChange}
                    //onChange={(e)=>{ recalculate(); handleChange();}}
                    //isValid={touched.address && !errors.address}
                    isInvalid={!!errors.address}
                  />
                  <Form.Control.Feedback type='invalid' >
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
                </div>
                </Stack>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      </Col>
      </Row>
    </>
  );
};
